import React, { forwardRef, useState } from 'react'

import Icon from '../../Icon/Icon'
import TextInput from '../TextInput/TextInput'

import styles from './PasswordInput.module.css'
import allContent from '../../../../content/content'

const cognitoContent = allContent.cognito
export type TextInputProps = React.ComponentPropsWithRef<'input'> & {
    id?: string
    onChangeCallback?(value: string): void
    isErrored?: boolean
    regExp?: RegExp
    disabled?: boolean
}

const PasswordInput = forwardRef<HTMLInputElement, TextInputProps>(({ ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    const handleShowHidePasswordOnClick = (): void => {
        setShowPassword(!showPassword)
    }

    return (
        <div className={styles.container}>
            <TextInput ref={ref} type={showPassword ? 'text' : 'password'} {...rest} />
            <button
                aria-label={
                    showPassword
                        ? cognitoContent.hidePasswordButtonText
                        : cognitoContent.showPasswordButtonText
                }
                type='button'
                className={styles['show-password-button']}
                onClick={handleShowHidePasswordOnClick}
            >
                <Icon iconName={showPassword ? 'EyeSlash' : 'Eye'} iconSize='S' />
            </button>
        </div>
    )
})

PasswordInput.displayName = 'PasswordInput'

export default PasswordInput
