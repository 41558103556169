import React, { SetStateAction } from 'react'

import LoginForm from '../../sections/LoginForm/LoginForm'
import InfoBanner from '../../blocks/InfoBanner/InfoBanner'
import Spacing from '../../basics/Spacing/Spacing'

import allContent from 'content/content'

const content = allContent.app

type LoginLayoutProps = {
    setIsAuthorised: React.Dispatch<SetStateAction<boolean>>
    setUserCognitoData: React.Dispatch<SetStateAction<Record<string, any> | undefined>>
    showExpiredBanner: boolean
    setShowExpiredBanner: React.Dispatch<SetStateAction<boolean>>
}
const LoginLayout: React.FC<LoginLayoutProps> = ({
    setIsAuthorised,
    setUserCognitoData,
    showExpiredBanner,
    setShowExpiredBanner,
}) => {
    return (
        <div className='general-container'>
            {showExpiredBanner && (
                <>
                    <InfoBanner
                        bannerType='info'
                        message={content.expiredBanner}
                        id='expiry-banner'
                    />
                    <Spacing />
                </>
            )}
            <LoginForm
                setShowExpiredBanner={setShowExpiredBanner}
                setIsAuthorised={setIsAuthorised}
                setUserCognitoData={setUserCognitoData}
            />
        </div>
    )
}
export default LoginLayout
