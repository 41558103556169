import React, { useRef, useEffect, useState } from 'react'
import classnames from 'classnames'

import Button from '../../basics/Button/Button'
import Heading from '../../basics/Heading/Heading'

import * as self from './Modal'

import styles from './Modal.module.css'
import allContent from '../../../content/content'

const content = allContent.components.modal

export type ModalProps = {
    /** boolean for setting modal open */
    isOpen: boolean
    /** callback for when its closed - triggered by escape key, close button or mouse click outside the dialog  */
    setClosed(): void
    /** element id that should take focus when closing modal, normally the button which opened it */
    returnFocusId: string
    /** classname for applying custom styles */
    className?: string
    /** header content */
    headerText: React.ReactNode
    /** main content */
    children?: React.ReactNode
    /** optional loading flag to trigger useEffect if other properties doesn't change */
    loading?: boolean
}

/** isEventOutsideBoundary: a function that takes a mouse click event and DOMRect, then returns true if the click x and y is deemed outside the dom rectangle points - all relative to view port.
 @param { MouseEvent }  event - a mouse click event
 @param { DOMRect } boundaryRect - DOMRect obtained from calling .getBoundingClientRect() on a element in the dom
 */
export const isEventOutsideBoundary = (event: React.MouseEvent, boundaryRect: DOMRect): boolean => {
    return (
        boundaryRect?.left > event.clientX ||
        boundaryRect?.right < event.clientX ||
        boundaryRect?.top > event.clientY ||
        boundaryRect?.bottom < event.clientY
    )
}

/** Modal: Renders a dialog when isOpen = true, displaying a close button and the header and children as content.
 * On opening it takes focus and hides any content outside the viewport to prevent scrolling
 * When escape key or close button are pressed, or mouse is clicked outside the box it calls setClosed and un-hides overflow content to enable scrolling
 * */
const Modal: React.FC<ModalProps> = ({
    children,
    className,
    headerText,
    isOpen,
    setClosed,
    returnFocusId,
    loading,
    ...rest
}: ModalProps) => {
    const modalClassNames = classnames(styles.modal, className, {
        [styles.isOpen]: isOpen,
    })
    const [modalBoundaries, setModalBoundaries] = useState<DOMRect>()
    const ESC_KEY = 'Escape'
    const TAB_KEY = 'Tab'
    const closeButton = useRef<HTMLButtonElement>(null) // to set focus when tabbing off the page
    const modalDiv = useRef<HTMLDivElement>(null)
    const recalc = (): void => setModalBoundaries(modalDiv?.current?.getBoundingClientRect()) // Fetch modal x/y boundaries

    useEffect(() => {
        if (isOpen && modalDiv.current) {
            document.body.style.overflow = 'hidden' // Prevent scrolling the page
            modalDiv.current.focus() // On opening set focus on modal
            recalc()
            modalDiv.current.addEventListener(
                'transitionend', // we've added a tiny css transition (change back colour to 99/9% white) to trigger when focus leaves div
                () => modalDiv.current?.focus() // Listen for css transition caused by focus leaving dialog and send focus back to modal
            )
            window.addEventListener('resize', recalc) // Recalculate modal x/y boundaries on window size change
        }
        // component will unmount (for when the close button isn't clicked but component is removed anyway - page navigation due to form submission)
        return function cleanup(): void {
            document.body.style.overflow = 'visible' // Enable scrolling on the page again
            window.removeEventListener('resize', recalc)
        }
    }, [isOpen])

    useEffect(() => {
        recalc()
    }, [children, loading]) // if children or inner loading statement change - recalc modalBoundaries to avoid onclick autoclosing (because the modal has increase but not been measured)

    const handleClose = (): void => {
        setClosed() // CallBack for when close triggered; parent controls "isOpen" state.
        const returnFocusTo = document.getElementById(returnFocusId) // Get element who's Id was passed to return focus
        returnFocusTo?.focus() // Set focus back to element (button) that lost focus to modal
    }

    const handleModalKeyDown = (e: React.KeyboardEvent): void => {
        // Close when escape key pressed
        if (e.key === ESC_KEY) {
            handleClose()
        }
    }

    const handleCloseButtonKeyDown = (e: React.KeyboardEvent): void => {
        // Set focus on modal if tabbing while focused on close button
        if (e.key === TAB_KEY) {
            e.preventDefault()
            modalDiv.current?.focus()
        }
    }

    const handleMouseDown = (e: React.MouseEvent): void => {
        if (modalBoundaries && self.isEventOutsideBoundary(e, modalBoundaries)) handleClose()
    }

    // Do not render anything if closed
    if (!isOpen) return null
    else {
        return (
            <div
                className={modalClassNames}
                role='dialog'
                aria-labelledby='modal-description'
                aria-modal='true'
                onMouseDown={(e: React.MouseEvent): void => handleMouseDown(e)}
                onKeyDown={handleModalKeyDown}
                tabIndex={0}
                ref={modalDiv}
                {...rest}
            >
                <div className={styles.content}>
                    <Heading heading='1' size='2' className={styles.header}>
                        {headerText}
                    </Heading>
                    {children}
                    <Button
                        type='button'
                        className={styles.closeButton}
                        text={content.close}
                        flavour='icon'
                        iconName='Cross'
                        onClick={handleClose}
                        onKeyDown={handleCloseButtonKeyDown}
                        ref={closeButton}
                    />
                    <div className='visually-hidden' id='modal-description'>
                        This dialog window is overlaying the page content. The last element is the
                        close button which will take you back where you were before.
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal
