const content = {
    app: {
        expiredBanner: 'Your session has expired, please log on again.',
        goToLogin: 'Go to log in page',
        checkingAuth: 'Checking authorisation..... one moment',
        configurationLoadingSpinnerText: 'Loading your setup...',
        tabTitle: 'ConnectManager - TravelTek',
    },
    header: {
        logo: 'https://www.traveltek.com/wp-content/uploads/2023/09/Traveltek-ConnectManager-Logo-Reversed.svg',
        userMenu: {
            label: 'UserMenu',
            logoutButton: 'Log Out',
        },
    },
    footer: {
        support: 'Support',
        serviceDeskText: 'Service Desk',
        serviceDeskUrl: 'https://traveltek.atlassian.net/servicedesk/customer/portal/29',
        privacyPolicyText: 'Privacy Policy',
        privacyPolicyUrl: 'https://www.traveltek.com/uk/privacy-policy/',
        termsOfUseText: 'Terms of Use',
        termsOfUseUrl: 'https://www.traveltek.com/uk/terms-of-service/',
    },
    cognito: {
        showPasswordButtonText: 'Show Password',
        hidePasswordButtonText: 'Hide Password',
        errors: {
            tooManyFailedAttempts: 'Too many failed attempts, please try again later.',
            notAuthorized: 'Incorrect username or password.',
            userNameNotFound: 'Email not found.',
            codeMismatch: 'Invalid confirmation code entered, please try again.',
            expiredCode: 'Confirmation code has expired, please request a new one.',
            unknown: 'Sorry, something went wrong, please try again later or contact us.',
        },
    },
    supplierNames: {
        CUN: 'Cunard',
        CCL: 'Carnival Cruise Line',
        PCL: 'Princess Cruise Line',
        HAL: 'Holland America Line',
        SBN: 'Seabourn',
        MSC: 'MSC Cruises',
        NCL: 'Norwegian Cruise Line',
        RCL: 'Royal Caribbean Line',
        CEL: 'Celebrity Cruises',
        VVC: 'Virgin Voyages',
        VKG: 'Viking Cruises',
        DCL: 'Disney Cruise Line',
        AZA: 'Azamara Cruises',
        EXP: 'Explora Journeys',
    },
    signUpPage: {
        form: {
            successMessage:
                'SUCCESS! Please check your email and then login using the code and username provided.',
            loggedOnMessage:
                'Note, you are currently logged on, is this form supposed to show for logged on users?',
            title: 'Sign up to Connect Products',
            subHeading: 'Get access to manage all your Connect products in one place.',
            firstNameInput: 'First Name',
            lastNameInput: 'Last Name',
            emailInput: 'Email',
            phoneInput: {
                contactNumberInput: 'Contact Number',
                groupLabel: 'Contact Number',
                countryCallingCode: 'Country calling code',
                countryCallingCodePlaceholder: 'Pick',
                phoneNumberNoCountryCode: 'Phone number',
            },
            companyNameInput: 'Company Name',
            companyLocationInput: 'Company Location',
            companyLocationInputPlaceHolder: 'Please select...',
            companyTypeInputPlaceholder: 'Please select...',
            companyType: 'Company Type',
            passInput: 'Password',
            submitButton: 'Sign up',
            submitButtonProcessing: 'Processing...',
            loginLinkText: 'Log In',
            loginText: 'Already a have an account?',
            goToLoginPageButton: 'Go to Login',
            errors: {
                firstNameInput: 'Please enter between 1 - 30 letters.',
                lastNameInput: 'Please enter between 1 - 30 letters.',
                emailInput: '(Is this a valid email? contact us if we are wrong)',
                contactNumberInput:
                    'Please enter number with international dialing code. eg UK: +44131...',
                companyNameInput: 'Please enter between 1 and 30 characters',
                companyLocationInput: 'Please make a selection',
                companyTypeError: 'Please make a selection',
                passInput:
                    'Please enter between 6 and 30 characters, at least one lowercase letter, one uppercase letter, one number and a special character',
                signupError:
                    'Registration unsuccessful. Please check your information and try again or contact our support team for further assistance',
                companyError: 'This company name is already in use. Please select another one.',
                emailError: 'An Account with this email already exists.',
            },
        },
    },
    loginPage: {
        form: {
            title: 'Login to ConnectManager',
            subHeading: 'And start managing all your Connect Suite products in one place.',
            showPasswordToggle: 'Show Password',
            submitButton: 'Login',
            userNameInput: 'User Name (email)',
            passwordInput: 'Password',
            signupLinkText: 'Sign Up',
            signupText: "Don't have an account yet?",
            forgotPasswordLinkText: 'Forgot password?',
            errors: {
                userNameInput: 'Please check your email is typed correctly.',
                passwordInput:
                    'Please enter 6 - 30 characters, include one lowercase letter, one uppercase letter, one number and a special character \\]{}()?"!@#%&/,><\':;|_~`=+-',
                generic: 'We had a problem logging you in, please try again later.',
            },
        },
    },
    newPasswordPage: {
        form: {
            title: 'Choose your password',
            showPasswordToggle: 'Show Password',
            submitButton: 'Submit New Password',
            submitting: 'Processing...',
            passInput:
                'Please enter between 6 and 30 characters, at least one lowercase letter, one uppercase letter, one number and a special character',
            errors: {
                passInput:
                    'Please enter between 6 and 30 characters, at least one lowercase letter, one uppercase letter, one number and a special character',
                apiError: 'Error setting new password, please try again later.',
            },
        },
    },
    navigationPanel: {
        dashboard: 'Dashboard',
        productHeading: 'Products',
        apiDocumentation: 'API Documentation',
        apiDocumentationURL:
            // 'https://www.traveltek.com/uk/product/connect-products/cruiseconnect/api-documentation/', THIS IS CORRECT BUT CURRENTLY ALL "ipsum lorem.." crap
            'https://schema.cruiseconnect.traveltek.net/',
        products: 'Products',
        cruiseConnect: 'CruiseConnect - API',
        supplierConfiguration: 'Supplier Configuration',
        companyConfiguration: 'Company Configuration',
        reporting: 'MI/Reporting',
        reportingBooking: 'Booking Data',
        agentConnect: 'AgentConnect - Application',
        userManagement: 'User Management',
        groupManagement: 'Group Management',
        salesChannel: 'Sales Channel',
        salesChannelManagement: 'Sales Channel Management',
    },
    homePage: {
        products: {
            title: 'Welcome to ConnectManager',
            freeInfoBoxHeading: 'Important information for free demo products.',
            paidProductsHeading: 'Paid products',
            freeProductsHeading: 'Free/Demo products',
            freeProductsSubHeading1:
                '• The demo site uses a mock confirmation for payment and booking. Payment will not be taken.',
            freeProductsSubHeading2:
                '• Demo versions of CruiseConnect - API and AgentConnect - Application use TravelTek credentials for prices.',
            freeProductsSubHeading3:
                '• When purchasing a product TravelTek credentials are removed.',
            freeProductsSubHeading4a: '• For product prices, please email our',
            freeProductsSubHeading4bLinkText: 'sales team.',
            freeProductsSubHeading4bLinkUrl: 'mailto:enquiries@traveltek.net',
            cruiseConnect: {
                title: 'CruiseConnect - API',
                subTitle: 'Advanced Cruise Packaging',
                productDescription:
                    'Is a cruise supplier consolidation product that provides the ability to search, book, cancel and amend across the entire cruise market.',
                productDescription2a: 'Demo version uses TravelTek supplier credentials, and can',
                productDescription2bBold: 'NOT make real bookings!',
                apiCredentialsSection: {
                    setupTitle: 'Setup your Authentication',
                    setupDescriptionNoUsers:
                        'Activate your API login credentials by clicking the button below. You will receive an email with your token and instructions. These same credentials are used to access API after purchase.',
                    setupDescriptionWithUsers:
                        'If you need to regenerate your API credentials, just click "Regenerate API user" button. You will receive an email with your token and instructions. These same credentials are used to access API after purchase.',
                    setupDescription2a: 'Read our',
                    setupDescription2b: 'for more information.',
                    loading: 'Fetching user credentials...',
                    generateApiCredsButton: 'Generate API Login',
                    regenerateApiCredsButton: 'Regenerate',
                    buttonSuccess: 'API Login Created',
                    buttonCopyPassword: 'Copy password',
                    username: 'Username',
                    password: 'Password',
                    passwordCopyWarning:
                        'Your password will only be shown once!\nMake sure you copy it!',
                    errors: {
                        userExists: 'API User already exists!',
                        apiGeneric: 'Sorry, there has been an error. Please try again later.',
                        apiPassword:
                            'Your password will only be shown once! Make sure you copy it!',
                        apiCredentials: 'Cannot get API credentials',
                    },
                },
                upgradeSection: {
                    upgradeQuestion: 'Request CruiseConnect API purchase?',
                    upgradeInfo:
                        'Use your own supplier credentials to make bookings and get your own rates and prices.',
                    upgradeButton: 'Request',
                    upgradeButtonSubmitting: 'Processing...',
                    upgradeModal: {
                        heading: 'Confirm purchase request',
                        text1: 'Paying for CruiseConnect - API will allow you to view rates, prices and make bookings using your own supplier credentials.',
                        text2: 'This request will let our team know you want to buy the full version and they will contact you regarding payment soon.',
                        text3: 'Please note: TravelTek credentials will not be available once you start paying for the API.',
                        cancelButton: 'Cancel',
                        confirmButton: 'Yes, request',
                    },
                    upgradeInProgressMessage:
                        'Purchase for CruiseConnect API currently in progress. A member of our team will be in touch shortly to discuss payment.',
                },
            },
            agentConnectCruise: {
                freeProductDescription: {
                    productName: 'AgentConnect - Application',
                    subtitle: 'Search & Book Products',
                    productDescription:
                        'Allows you to book your travel products, from across the industry, quickly and easily.',
                    productDescription2a: 'Currently only',
                    productDescription2bBold: 'Cruise',
                    productDescription2c: 'is open for use.',
                    productDescription2dBold: 'Flight, Hotels',
                    productDescription2e: 'and more coming soon.',
                },
                paidProductDescription: {
                    productName: 'AgentConnect - Cruise',
                    subtitle: 'Search & Book',
                    text1: 'Allows you to book your travel products, from across the industry, quickly and easily.',
                    credentialsManagement: {
                        addSupplierCredentials: '1. Add your supplier credentials:',
                        addSalesChannels: '1. Manage your Sales Channels:',
                        supplierConfigurationLink: 'Supplier configuration',
                        salesChannelManagementLink: 'Sales Channel Management',
                    },
                    groupsManagement: {
                        manageGroups: '2. Manage your groups:',
                        groupsManagementLink: 'Group Management',
                    },
                    usersManagement: {
                        addUsers: '. Add Users:',
                        userManagementLink: 'User Management',
                        loginInfo:
                            '(You can not use your ConnectManager login to access AgentConnect)',
                    },
                    getStarted: '. Get started using',
                    productLinkText: 'AgentConnect App',
                    productLinkUrl: 'https://agentconnect.traveltek.net',
                },
                demoSection: {
                    title: 'AgentConnect',
                    setupTitle: 'Try Demo application for free',
                    setupDescription1:
                        'Demo version does NOT make real bookings. More features are being added constantly. Please keep visiting or',
                    setupDescription2: 'for more details.',
                    contactUsLink: 'https://www.traveltek.com/contact/',
                    contactUsLinkText: 'contact us',
                    button: 'Open AgentConnect',
                },
                gettingStartedSection: {
                    paidAgentConnect: {
                        title: 'Getting started with AgentConnect',
                        description1a: 'Simply setup Users in the ',
                        description1aLinkText: 'User Management section',
                        description1b:
                            ', and then the Group and associated Sales Channel that they belong to in the ',
                        description1bLinkText: 'Group Management',
                        description1c: ' and ',
                        description1cLinkText: 'Sales Channel Management',
                        description1d: ' sections.',
                    },
                    free: {
                        title: 'Getting started',
                        description1: 'Are you looking for an API or Application?',
                        description2: 'Browse our free/demo products below.',
                    },
                },
                updateGroupModal: {
                    title: 'Edit Group',
                    text1: 'Edit group information in the form below.',
                    groupTitleInput: 'Group Name',
                    salesChannelInput: 'Add Group to Sales Channel',
                    salesChannelPlaceholderText: '-- Select Sales Channel --',
                    cancelButton: 'Cancel',
                    confirmButton: 'Update',
                    submittingButton: 'Submitting...',
                    errors: {
                        groupTitle:
                            "You may use 1-30 characters including letters, numbers, space and ',.!#£$&@%'*+=?^,-",
                        salesChannelId: 'A group must be associated with a sales channel',
                        apiError: 'Sorry, failed to update group. Please try again later.',
                    },
                    instructionToCreateSalesChannel:
                        'To assign a group to a Channel Please go to create your Sales Channel first',
                },
                updateUserModal: {
                    text1: 'Edit user information in the form below.',
                    title: 'Edit User',
                    email: 'Email',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    groupsLabelText: 'Group(s)',
                    groupsPlaceHolderText: '-- Select Group --',
                    fetchingGroupsData: 'Loading groups...',
                    cancelButton: 'Cancel',
                    confirmButton: 'Update',
                    submittingButton: 'Submitting...',
                    errors: {
                        emailInput: 'Please enter a valid email address.',
                        firstNameInput: 'Please enter a valid name from 1 - 30 letters.',
                        lastNameInput: 'Please enter a valid name from 1 - 30 letters.',
                        defaultApiError: 'Sorry, failed to add user. Please try again later.',
                        groupsId: 'Please choose a group.',
                    },
                },
                addUserButton: {
                    text1: 'Create new users by providing their name and email address.',
                    text2: 'An email will be sent to them with their initial access code and link to AgentConnect.',
                    text3: 'Their initial code will expire in 7 days, you will need to reset it for them if this passes.',
                    addUserButton: 'Add User',
                    modal: {
                        loadingGroupsData: 'Loading groups...',
                        title: 'Add AgentConnect user',
                        email: 'E-mail',
                        firstName: 'First Name',
                        lastName: 'Last Name',
                        groupsLabelText: 'Group(s)',
                        groupsPlaceHolderText: '-- Select Group --',
                        fetchingGroupsData: 'Fetching groups data...',
                        cancelButton: 'Cancel',
                        confirmButton: 'Confirm',
                        submittingButton: 'Submitting...',
                    },
                    errors: {
                        emailInput: 'Please enter a valid email address.',
                        firstNameInput: 'Please errors from 1 - 30 letters.',
                        lastNameInput: 'Please errors from 1 - 30 letters.',
                        defaultApiError: 'Sorry, failed to add user. Please try again later.',
                        groupId: 'Please choose a group.',
                    },
                },
                addGroupButton: {
                    addGroupButtonText: 'Add Group',
                    modal: {
                        modalTitle: 'Add AgentConnect group',
                        titleField: 'Group Name',
                        descriptionField: 'Group Description',
                        salesChannel: 'Sales Channel',
                        cancelButton: 'Cancel',
                        confirmButton: 'Confirm',
                        submittingButton: 'Submitting...',
                    },
                    errors: {
                        defaultApiError: 'Sorry, failed to add group. Please try again later.',
                        groupNameInput:
                            "You may use 1-30 characters including letters, numbers, space and ',.!#£$&@%'*+=?^,-",
                    },
                },
                upgradeSection: {
                    upgradeQuestion: 'Request AgentConnect - Cruise purchase?',
                    upgradeInfo:
                        'Use your own supplier credentials to make bookings and get your own rates and prices.',
                    upgradeButton: 'Request',
                    upgradeButtonSubmitting: 'Processing...',
                    upgradeModal: {
                        heading: 'Confirm purchase request',
                        text1: 'Purchasing AgentConnect Cruise will allow you to view rates, prices and make bookings using your own supplier credentials.',
                        text2: 'You will be able to invite users (via email) to create access to the AgentConnect Cruise app.',
                        text3: 'This request will let our team know you want to buy the full version and they will contact you regarding payment soon.',
                        cancelButton: 'Cancel',
                        confirmButton: 'Yes, request',
                    },
                    upgradeInProgressMessage:
                        'Purchase for AgentConnect - Cruise currently in progress. A member of our team will be in touch shortly to discuss payment.',
                },
            },
        },
    },
    resetPasswordPage: {
        form: {
            title: 'Reset the password',
            emailInput: 'Email',
            passInput: 'New Password',
            showPasswordToggle: 'Show Password',
            hidePasswordToggle: 'Hide Password',
            codeInput: 'Confirmation Code',
            submitButton: 'Reset Password',
            errors: {
                codeInput: 'Confirmation code is required.',
                emailInput: '(Is this a valid email? contact us if we are wrong)',
                passInput:
                    'Please enter between 6 and 30 characters, at least one lowercase letter, one uppercase letter, one number and a special character',
                resetPasswordError: 'Sorry, something went wrong, please try again later.',
            },
        },
    },
    forgotPasswordPage: {
        form: {
            title: 'Enter your e-mail address to reset the password',
            emailInput: 'Email',
            submitButton: 'Send confirmation code',
            errors: {
                emailInput: '(Is this a valid email? contact us if we are wrong)',
            },
        },
    },
    supplierConfigurationPage: {
        title: 'Supplier Configuration',
        loading: 'Loading...',
        form: {
            supplier: 'Supplier',
            enable: 'Enable',
            disable: 'Disable',
            add: 'Add',
            edit: 'Edit',
            save: 'Save',
            supplierCredentialsSaved: 'Supplier credentials saved.',
            supplierCredentialsUpdated: 'Supplier credentials updated.',
            supplierToggleOn: 'Supplier toggled on.',
            supplierToggleOff: 'Supplier toggled off.',
        },
        errors: {
            loadingSupplierList: 'Cannot load suppliers list, please try again later.',
            loadingFieldsItems: 'Cannot load supplier configuration, please try again later.',
            savingSupplierCredentials:
                'Sorry, there has been an error saving your supplier credentials.',
            supplierCredentialsDoNotExist:
                'Please ensure that you have added your supplier credentials, as they do not currently exist.',
        },
    },
    companyConfigurationPage: {
        title: 'Company Configuration',
        cardHeader: 'Company Profile',
        loading: 'Loading...',
        logoField: {
            label: 'Company Logo',
            description:
                'Upload your company logo. This will be featured on all your quotation documentation.',
        },
        nameField: {
            label: 'Company Name',
        },
    },
    reportingPage: {
        title: 'Reporting',
        cardHeader: 'Booking report',
        loading: 'Loading...',
        export: 'Export',
        dateFrom: 'From',
        dateTo: 'To',
        errorFetchingReport: 'Sorry, something went wrong.',
    },
    userManagementPage: {
        title: 'User Management',
        userAddSuccessMessage1: 'User ',
        userAddSuccessMessage2: ' has been invited to use AgentConnect',
        fetchingUsers: 'Fetching users...',
        fetchingGroups: 'Fetching groups data',
        errors: {
            addingUser: 'Sorry, there has been an error adding the user.',
            fetchingUsers: 'Sorry, failed to fetch users. Please try again later.',
        },
        filter: {
            title: 'Filter Users by First Name',
            button: 'Clear filter',
        },
        list: {
            heading: 'User Accounts',
            refresh: 'Refresh list',
            userColumn: 'User',
            resetPassword: 'Reset Password',
            actionMenu: 'Actions',
            actionMenuProcessing: 'Processing...',
            edit: 'Edit',
            fetchingGroupsData: 'Fetching group data...',
            disable: 'Disable',
            enable: 'Enable',
            passwordResetSpinner: 'Resetting Password...',
            resetPasswordSuccess: 'was sent NEW pass code (valid for 7 days):',
            updateUserDetailsSuccessMessage: 'The user details have been changed successfully',
            updateUserGroupSuccessMessage: 'The user has been assigned a new group',
            assignUserError: 'There was an error assigning a group to the user',
            confirmationStatusColumn: 'Status',
            linkedGroupsColumn: 'Linked Group',
            noUsers: 'No users found. Please use "Add User" button above to send email invites.',
            userStatus: {
                CONFIRMED: 'Confirmed',
                FORCE_CHANGE_PASSWORD: 'Pending Confirmation',
                DISABLED: 'Disabled',
            },
            errors: {
                resetPassword: 'Sorry, there has been an error resetting the password.',
                disableUser: 'Sorry, there has been an error disabling the user.',
                enableUser: 'Sorry, there has been an error enabling the user.',
                updateUser: 'Sorry, there has been an error on updating user.',
            },
            resetPasswordModal: {
                close: 'Close',
                confirm: 'Confirm',
                title: 'Confirm Password Reset',
                text1: 'Are you sure you want to reset the password for user:',
                text2: 'They will be sent a new confirmation code (valid for 7 days).',
            },
        },
    },
    groupManagementPage: {
        title: 'Group Management',
        description: 'Groups are used to manage users and their access to supplier credentials.',
        filter: {
            title: 'Filter by Group Name',
            button: 'Clear Filter',
        },
        list: {
            fetchingSalesChannels: 'Fetching Sales Channels...',
            heading: 'Group Accounts',
            refresh: 'Refresh list',
            noGroupsCreated: 'No groups created yet, use Add Group button above to create a group.',
            groupColumn: 'Group Name',
            salesChannelColumn: 'Linked Sales Channel',
            actionMenu: 'Actions',
            actionMenuProcessing: 'Processing...',
            edit: 'Edit',
        },
        errors: {
            fetchingSalesChannels:
                'Sorry, failed to fetch Sales Channels. Please try again later. ',
            fetchingGroups: 'Sorry, failed to fetch groups. Please try again later.',
        },
        groupAddSuccessMessage: 'Group has been added to use AgentConnect',
        fetchingGroups: 'Fetching groups data...',
    },
    salesChannelManagementPage: {
        title: 'Sales Channel Management',
        description: 'Sales Channels are used to create and manage supplier credentials.',
        addSalesChannelButton: 'Add Sales Channel',
        modal: {
            header: 'Add Sales Channel',
            titleLabel: 'New Sales Channel Name:',
            cancelButton: 'Cancel',
            confirmButton: 'Confirm',
        },
        filter: {
            title: 'Filter by Sales Channel Name',
            button: 'Clear Filters',
        },
        list: {
            heading: 'Sales Channels',
            salesChannelColumn: 'Sales Channel Name',
            groupColumn: 'Linked Group',
            noSalesChannels: 'No sales channels found.',
            noLinkedGroups: 'No linked groups found.',
            actions: {
                actionMenu: 'Actions',
                edit: 'Edit',
            },
        },
        fetchingSalesChannels: 'Fetching sales channels...',
        salesChannelCreated: 'sales channel has been created.',
        errors: {
            fetchingSalesChannels: 'Sorry, failed to fetch sales channels. Please try again later.',
            createSalesChannel: 'Sorry, there has been an error adding sales channel.',
            title: 'Please provide a valid name.',
        },
        editSalesChannel: {
            title: 'Edit Sales Channel',
            loading: 'Loading...',
            backToSalesChannelsManagement: 'Back to Sales Channel Management',
            fetchingSuppliersData: 'Fetching sales channels...',
            noSalesChannelId: 'No sales channelId found.',
            form: {
                supplier: 'Supplier',
                enable: 'Enable',
                disable: 'Disable',
                add: 'Add',
                edit: 'Edit',
                save: 'Save',
                cancel: 'Cancel',
                supplierCredentialsSaved: 'Supplier credentials saved.',
                supplierCredentialsUpdated: 'Supplier credentials updated.',
                supplierToggleOn: 'Supplier toggled on.',
                supplierToggleOff: 'Supplier toggled off.',
            },
            errors: {
                loadingSupplierList: 'Cannot load suppliers list, please try again later.',
                loadingFieldsItems: 'Cannot load supplier configuration, please try again later.',
                savingSupplierCredentials:
                    'Sorry, there has been an error saving your supplier credentials.',
                supplierCredentialsDoNotExist:
                    'Please ensure that you have added your supplier credentials, as they do not currently exist.',
            },
        },
    },
    components: {
        closeButton: 'close',
        monthStrings: {
            jan: 'January',
            feb: 'February',
            mar: 'March',
            apr: 'April',
            may: 'May',
            jun: 'June',
            jul: 'July',
            aug: 'August',
            sep: 'September',
            oct: 'October',
            nov: 'November',
            dec: 'December',
        },
        errorBoundary: {
            homeButton: 'Back to home',
            heading: 'Something went wrong',
            message: 'This error has been logged, we will be working on it soon',
        },
        modal: {
            close: 'Close',
        },
        datePicker: {
            years: {
                select: 'Select year',
            },
            months: {
                select: 'Select month',
                nextButton: 'Next month',
                previousButton: 'Previous month',
            },
        },
        infoBanner: {
            closeButton: 'close',
        },
        phoneInput: {
            label: 'Phone Number',
            errors: {
                phoneNumber: 'Please check phone number (digits only).',
            },
        },
        pagination: {
            previousPageButtonText: 'Previous',
            nextPageButtonText: 'Next',
        },
        paginationButtons: {
            previousPageButtonText: 'Previous',
            nextPageButtonText: 'Next',
        },
    },
}

export default content
