import React from 'react'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'

import SignUpForm from '../../sections/SignUpForm/SignUpForm'

type SignUpLayoutProps = {
    isAuthorised: boolean
}

const MANAGER_API_CLIENT = new ApolloClient({
    /** HttpLink is used by default, but manually setting allows us to specify a fetch API */
    link: new HttpLink({ uri: process.env.REACT_APP_BACKEND_SERVICE_URL, fetch }),
    /** caches user queries in-memory to reduce redundant fetches */
    cache: new InMemoryCache(),
})

const SignUpLayout: React.FC<SignUpLayoutProps> = ({ isAuthorised }) => (
    <div className='general-container'>
        {isAuthorised ? (
            <p>You are currently logged on, please log out first to sign up.</p>
        ) : (
            <SignUpForm isAuthorised={isAuthorised} signUpClient={MANAGER_API_CLIENT} />
        )}
    </div>
)

export default SignUpLayout
