import React, { useContext } from 'react'

import Button from '../../../../basics/Button/Button'
import Heading from '../../../../basics/Heading/Heading'
import Link from '../../../../basics/Link/Link'
import Text from '../../../../basics/Text/Text'
import { ROUTES, FeatureToggleContext } from '../../../../../App'

import styles from './AgentConnectPaidDescription.module.scss'
import allContent from '../../../../../content/content'

const content = allContent.homePage.products.agentConnectCruise.paidProductDescription

const AgentConnectPaidDescription = (): JSX.Element => {
    const featureToggle = useContext(FeatureToggleContext)

    const isSalesChannelsTurnedOn = featureToggle.TURN_ON_SALES_CHANNEL_MANAGEMENT
    const isGroupsManagementTurnedOn = featureToggle.TURN_ON_GROUP_MANAGEMENT

    const credentialsManagement = (
        <>
            <Text>
                {isSalesChannelsTurnedOn
                    ? content.credentialsManagement.addSalesChannels
                    : content.credentialsManagement.addSupplierCredentials}
            </Text>
            <div className={styles.indent}>
                <Link
                    noUnderLine={true}
                    to={
                        isSalesChannelsTurnedOn
                            ? ROUTES.SALES_CHANNEL_MANAGEMENT
                            : ROUTES.SUPPLIER_CONFIGURATION
                    }
                >
                    <Button
                        type='button'
                        text={
                            isSalesChannelsTurnedOn
                                ? content.credentialsManagement.salesChannelManagementLink
                                : content.credentialsManagement.supplierConfigurationLink
                        }
                    />
                </Link>
            </div>
        </>
    )

    const groupManagement = isGroupsManagementTurnedOn ? (
        <>
            <Text>{content.groupsManagement.manageGroups}</Text>
            <div className={styles.indent}>
                <Link noUnderLine={true} to={ROUTES.GROUP_MANAGEMENT}>
                    <Button type='button' text={content.groupsManagement.groupsManagementLink} />
                </Link>
            </div>
        </>
    ) : null

    const userManagement = (
        <>
            <Text>
                {(isGroupsManagementTurnedOn ? '3' : '2') + content.usersManagement.addUsers}
            </Text>
            <div className={styles.indent}>
                <Link noUnderLine={true} to={ROUTES.USER_MANAGEMENT}>
                    <Button type='button' text={content.usersManagement.userManagementLink} />
                </Link>
            </div>
            <Text>{content.usersManagement.loginInfo}</Text>
        </>
    )

    return (
        <div className={styles.container}>
            <div className={styles['heading-wrapper']}>
                <div className={styles['heading-text']}>
                    <Heading heading='3'>{content.productName}</Heading>
                    <Text weight='bold'>{content.subtitle}</Text>
                </div>
            </div>
            <Text>{content.text1}</Text>
            {credentialsManagement}
            {groupManagement}
            {userManagement}
            <span>
                <Text>{(isGroupsManagementTurnedOn ? '4' : '3') + content.getStarted}</Text>{' '}
                <Link href={content.productLinkUrl}>{content.productLinkText}</Link>
            </span>
        </div>
    )
}

export default AgentConnectPaidDescription
