import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Heading from 'components/basics/Heading/Heading'
import Text from 'components/basics/Text/Text'
import CredentialsListItem, { CredentialListItem } from './CredentialsListItem/CredentialsListItem'
import Card from '../Card/Card'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import { SalesChannelAPIResponse } from 'components/sections/EditSalesChannel/EditSalesChannel'

import styles from './CredentialsList.module.scss'
import allContent from '../../../content/content'
import InfoBanner from '../InfoBanner/InfoBanner'

const content = allContent.salesChannelManagementPage.editSalesChannel

export type CredentialFieldsItem = {
    /** Supplier name */
    supplierName: string
    /** Is that supplier enabled in AgentConnect */
    isEnabled: boolean
    /** Does this supplier have credentials filled in */
    hasCredentials: boolean
}

type CredentialsListProps = {
    credentialItems: CredentialListItem[]
    apiClient: ApolloClient<NormalizedCacheObject>
    salesChannelInfo: SalesChannelAPIResponse | null | undefined
    fetchingSalesChannelInfo: boolean
    errorMessage: string | null
}

/** Credentials: returns a list of fields that lets user add or update credentials for a given supplier.
    It also lets user disable a supplier for Agent Connect search results.
 */
const CredentialsList: React.FC<CredentialsListProps> = ({
    credentialItems,
    apiClient,
    salesChannelInfo,
    fetchingSalesChannelInfo,
    errorMessage,
}: CredentialsListProps) => {
    const suppliers = salesChannelInfo?.salesChannelId ? (
        credentialItems.map((item) => {
            return (
                <CredentialsListItem
                    key={item.supplierCode}
                    credentialItem={{
                        supplierCode: item.supplierCode,
                        available: item.available,
                        created: item.created,
                    }}
                    apiClient={apiClient}
                    salesChannelId={salesChannelInfo?.salesChannelId}
                />
            )
        })
    ) : (
        <Text>{content.noSalesChannelId}</Text>
    )

    return (
        <>
            {fetchingSalesChannelInfo && <LargeSpinner text={content.fetchingSuppliersData} />}
            {!fetchingSalesChannelInfo && salesChannelInfo?.title && (
                <Card header={salesChannelInfo?.title}>
                    <ul className={styles['sales-channel__list']}>
                        <li className={styles['sales-channel__description']}>
                            <Heading heading='2' size='3'>
                                {content.form.supplier}
                            </Heading>
                            <Heading heading='2' size='3'>
                                {content.form.enable}/{content.form.disable}
                            </Heading>
                        </li>
                        {suppliers}
                    </ul>
                </Card>
            )}
            {errorMessage && !fetchingSalesChannelInfo && (
                <InfoBanner
                    id='supplier-credentials-error'
                    bannerType='error'
                    message={errorMessage}
                />
            )}
        </>
    )
}

export default CredentialsList
