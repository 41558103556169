import { getDataFromLocalStorage } from './use-local-storage'
import { setContext } from '@apollo/client/link/context'
import { LOCAL_STORAGE_USER_DATA_KEY } from './constants'

export function getAccessToken(): string {
    const authData = getDataFromLocalStorage({ key: LOCAL_STORAGE_USER_DATA_KEY })
    // get the authentication token from local storage if it exists
    return authData
        ? `Bearer ${
              authData?.user?.signInUserSession?.accessToken?.jwtToken || // this is for the case when user is logged in and verified
              authData?.signInUserSession?.accessToken?.jwtToken // this is for the case when user is logged in but not verified (new_pass_challenge)
          }`
        : ''
}

const authHeader = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorizationToken: getAccessToken(),
        },
    }
})

export default authHeader
