import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Heading from '../../basics/Heading/Heading'
import allContent from '../../../content/content'
import styles from './CompanyConfiguration.module.css'
import ImagePicker from 'components/blocks/ImagePicker/ImagePicker'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import TextInput from 'components/basics/INPUTS/TextInput/TextInput'
import { Controller, useForm } from 'react-hook-form'
import Button from 'components/basics/Button/Button'
import Card from 'components/blocks/Card/Card'

const content = allContent.companyConfigurationPage

type CompanyConfigurationProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
}

const CompanyConfiguration: React.FC<CompanyConfigurationProps> = ({ apiClient }) => {
    const { control, handleSubmit } = useForm<Record<string, string>>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: {
            companyLogo: '',
            companyName: '',
        },
    })

    const onSubmit = (data: any): void => {
        // eslint-disable-next-line
        console.log(data)
    }

    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.title}</Heading>
            <Card header={content.cardHeader}>
                <div className={styles['card-content']}>
                    <Controller
                        name={'companyLogo'}
                        control={control}
                        render={({ field: { onChange, value } }): React.ReactElement => (
                            <ImagePicker
                                label={content.logoField.label}
                                subText={content.logoField.description}
                                defaultValue={value}
                                onChange={onChange}
                                resizeTo={200}
                            />
                        )}
                    />

                    <Controller
                        name={'companyName'}
                        control={control}
                        render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                            <div className={styles['item__field']}>
                                <LabelledInput
                                    key={'companyName'}
                                    htmlFor={'companyName'}
                                    label={content.nameField.label}
                                >
                                    <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                                </LabelledInput>
                            </div>
                        )}
                    />
                    <div>
                        <Button
                            flavour={'primary'}
                            text={'Save'}
                            onClick={handleSubmit(onSubmit)}
                            type='button'
                        />
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default CompanyConfiguration
