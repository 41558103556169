import { gql } from '@apollo/client'

export const SIGNUP = gql`
    mutation SignUp(
        $companyName: String!
        $companyLocation: String!
        $companyType: CompanyTypeEnum!
        $firstName: String!
        $lastName: String!
        $emailAddress: String!
        $contactNumber: String!
    ) {
        signup(
            tenant: {
                companyName: $companyName
                companyType: $companyType
                companyLocation: $companyLocation
            }
            user: {
                firstName: $firstName
                lastName: $lastName
                emailAddress: $emailAddress
                contactNumber: $contactNumber
                role: OWNER
            }
        ) {
            status
        }
    }
`

export const API_USER_CREATE = gql`
    mutation apiUserCreate {
        apiUserCreate {
            password
            username
        }
    }
`

export const API_LIST_USERS = gql`
    query listApiUsers {
        listApiUsers {
            password
            username
        }
    }
`

export const API_USER_RESET_PASSWORD = gql`
    mutation apiUserResetPassword {
        apiUserResetPassword {
            password
            username
        }
    }
`

export const SUPPLIER_CREDENTIALS = gql`
    query supplierCredentials($salesChannelId: String!) {
        supplierCredentials(salesChannelId: $salesChannelId) {
            available
            created
            supplierCode
        }
    }
`

export const SUPPLIER_CREDENTIALS_AND_SALES_CHANNEL = gql`
    query supplierCredentials($salesChannelId: String!) {
        supplierCredentials(salesChannelId: $salesChannelId) {
            available
            created
            supplierCode
        }
        salesChannels {
            salesChannelId
            title
        }
    }
`

export const CREATE_SALES_CHANNEL = gql`
    mutation createSalesChannel($title: String!) {
        createSalesChannel(title: $title) {
            salesChannelId
            title
            userGroups {
                title
                userGroupId
            }
        }
    }
`

export const SUPPLIER_CREDENTIAL_FIELDS = gql`
    query supplierCredentialFields($supplierCode: SupplierCodeEnum!, $salesChannelId: String!) {
        supplierCredentialFields(supplierCode: $supplierCode, salesChannelId: $salesChannelId) {
            helpText
            name
            order
            value
            displayName
        }
    }
`

export const SUPPLIER_CREDENTIAL_CREATE = gql`
    mutation supplierCredentialCreate(
        $salesChannelId: String!
        $supplierCode: SupplierCodeEnum!
        $fields: [SupplierCredentialFieldInput!]!
    ) {
        supplierCredentialCreate(
            salesChannelId: $salesChannelId
            supplierCredential: { supplierCode: $supplierCode, fields: $fields }
        ) {
            available
            created
            supplierCode
        }
    }
`

export const SUPPLIER_CREDENTIAL_UPDATE = gql`
    mutation supplierCredentialUpdate(
        $salesChannelId: String!
        $supplierCode: SupplierCodeEnum!
        $fields: [SupplierCredentialFieldInput!]!
    ) {
        supplierCredentialUpdate(
            salesChannelId: $salesChannelId
            supplierCredential: { supplierCode: $supplierCode, fields: $fields }
        ) {
            created
        }
    }
`

export const SUPPLIER_CREDENTIAL_AVAILABLE_TOGGLE = gql`
    mutation supplierCredentialAvailableToggle(
        $salesChannelId: String!
        $available: Boolean!
        $supplierCode: SupplierCodeEnum!
    ) {
        supplierCredentialAvailableToggle(
            salesChannelId: $salesChannelId
            available: $available
            supplierCode: $supplierCode
        ) {
            available
        }
    }
`

export const REQUEST_PRODUCT_UPGRADE = gql`
    mutation upgradeTenant($productName: ProductNameEnum!) {
        upgradeTenant(productInput: { name: $productName }) {
            status
        }
    }
`

export const AGENT_CONNECT_GROUP_ADD = gql`
    mutation createUserGroup($title: String!, $description: String) {
        createUserGroup(title: $title, description: $description) {
            userGroupId
            title
            description
            tenantId
        }
    }
`

export const AGENT_CONNECT_USER_UPDATE = gql`
    mutation agentConnectUserUpdate(
        $userId: String!
        $emailAddress: String
        $firstName: String
        $lastName: String
    ) {
        agentConnectUserUpdate(
            user: {
                username: $userId
                emailAddress: $emailAddress
                firstName: $firstName
                lastName: $lastName
            }
        ) {
            status
        }
    }
`

export const ASSIGN_SALES_CHANNEL_TO_GROUP = gql`
    mutation assignSalesChannelToAUserGroup($salesChannelId: String!, $userGroupId: String!) {
        assignSalesChannelToAUserGroup(salesChannelId: $salesChannelId, userGroupId: $userGroupId) {
            title
            salesChannels {
                salesChannelId
                title
            }
            userGroupId
        }
    }
`

export const AGENT_CONNECT_USER_DISABLE = gql`
    mutation agentConnectUserDisable($emailAddress: String!) {
        agentConnectUserDisable(user: { emailAddress: $emailAddress }) {
            status
        }
    }
`

export const AGENT_CONNECT_USER_ENABLE = gql`
    mutation agentConnectUserEnable($emailAddress: String!) {
        agentConnectUserEnable(user: { emailAddress: $emailAddress }) {
            status
        }
    }
`

export const GET_CONNECT_USER_DATA = gql`
    query getConnectUserData {
        getConnectUserData {
            products {
                name
                tier
            }
        }
    }
`

export const AGENT_CONNECT_LIST_USERS = gql`
    query agentConnectListUsers {
        listAgentConnectUsers {
            enabled
            username
            userStatus
            emailAddress
            firstName
            lastName
            userGroups {
                title
                userGroupId
            }
        }
    }
`
export const AGENT_CONNECT_LIST_GROUPS = gql`
    query userGroups {
        userGroups {
            title
            salesChannels {
                title
                salesChannelId
            }
            userGroupId
        }
    }
`

export const AGENT_CONNECT_USER_RESET_PASSWORD = gql`
    mutation agentConnectUserResetPassword($emailAddress: String!) {
        agentConnectUserResetPassword(user: { emailAddress: $emailAddress }) {
            emailAddress
            password
        }
    }
`

export const LIST_SALES_CHANNELS = gql`
    query salesChannels {
        salesChannels {
            salesChannelId
            title
            userGroups {
                title
                userGroupId
            }
        }
    }
`

export const ASSIGN_USER_TO_A_GROUP = gql`
    mutation assignUserToAUserGroup($userId: String!, $userGroupId: String!) {
        assignUserToAUserGroup(userGroupId: $userGroupId, userId: $userId) {
            email
            userGroups {
                title
            }
            userId
        }
    }
`

export const AGENT_CONNECT_USER_ADD = gql`
    mutation agentConnectUserAdd(
        $emailAddress: String!
        $firstName: String!
        $lastName: String!
        $userGroupId: String
    ) {
        agentConnectUserAdd(
            user: { emailAddress: $emailAddress, lastName: $lastName, firstName: $firstName }
            userGroupId: $userGroupId
        ) {
            status
        }
    }
`
