import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Navigation from '../../sections/NavigationPannel/NavigationPanel'

import { ProductTiersType } from '../../../App'
import styles from './CompanyConfigurationLayout.module.css'
import CompanyConfigurationPage from 'components/sections/CompanyConfiguration/CompanyConfiguration'

type CompanyConfigurationProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersType
}

const CompanyConfiguration = ({
    apiClient,
    productTiers,
}: CompanyConfigurationProps): JSX.Element => {
    return (
        <div className={styles.container}>
            <Navigation productTiers={productTiers} />
            <CompanyConfigurationPage apiClient={apiClient} />
        </div>
    )
}

export default CompanyConfiguration
