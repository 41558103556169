import React, { SetStateAction, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { datadogLogs } from '@datadog/browser-logs'

import Button from '../../basics/Button/Button'
import FieldError from '../../basics/FieldError/FieldError'
import Heading from '../../basics/Heading/Heading'
import InfoBanner from '../../blocks/InfoBanner/InfoBanner'
import LabelledInput from '../../blocks/LabelledInput/LabelledInput'
import Link from '../../basics/Link/Link'
import PasswordInput from '../../basics/INPUTS/PasswordInput/PasswordInput'
import Spacing from '../../basics/Spacing/Spacing'
import Text from '../../basics/Text/Text'
import TextInput from '../../basics/INPUTS/TextInput/TextInput'
import { ROUTES } from '../../../App'
import { setDataToLocalStorage } from '../../../utils/use-local-storage'
import { getCognitoErrorMessage } from '../../../utils/get-cognito-error-message'

import styles from './LoginForm.module.css'
import allContent from '../../../content/content'
import {
    LOCAL_STORAGE_USER_DATA_KEY,
    ACCESS_TOKEN_EXPIRY_MINS,
    COGNITO_ACTIONS,
} from '../../../utils/constants'
const content = allContent.loginPage.form

type LoginFormProps = {
    setIsAuthorised: React.Dispatch<SetStateAction<boolean>>
    setUserCognitoData: React.Dispatch<SetStateAction<Record<string, any> | undefined>>
    setShowExpiredBanner: React.Dispatch<SetStateAction<boolean>>
}

type LoginFormDataType = {
    userName: string
    password: string
}

const LoginForm: React.FC<LoginFormProps> = ({
    setIsAuthorised,
    setUserCognitoData,
    setShowExpiredBanner,
}) => {
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [fetchedUserWithPasswordChallengeToggle, setFetchedUserWithPasswordChallengeToggle] =
        useState<boolean>(false)
    const [loginApiError, setLoginError] = useState<string>('')
    useEffect(() => {
        //Render occurred. Navigate to new password if form submission returned valid user and challenge param = NEW_PASSWORD:',
        if (fetchedUserWithPasswordChallengeToggle) {
            navigate(ROUTES.NEW_PASSWORD)
        }
    }, [fetchedUserWithPasswordChallengeToggle, navigate])

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormDataType>({
        mode: 'onBlur',
        reValidateMode: 'onChange', // only comes into affect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            userName: '',
            password: '',
        },
    })

    const loginForm = (
        <form
            onSubmit={handleSubmit(async (formFields: LoginFormDataType) => {
                setIsSubmitting(true)
                setLoginError('')
                await Auth.signIn(formFields.userName, formFields.password)
                    .then((user) => {
                        setShowExpiredBanner(false)
                        if (user.challengeName === COGNITO_ACTIONS.new_password) {
                            setDataToLocalStorage({
                                data: user,
                                key: LOCAL_STORAGE_USER_DATA_KEY,
                                expiryMins: 60,
                            })
                            setFetchedUserWithPasswordChallengeToggle(true)
                        } else {
                            // eslint-disable-next-line no-console
                            console.log(
                                'Logged in, setting userState and isAuthorised to true:',
                                user
                            )
                            setUserCognitoData(user)
                            setIsAuthorised(true)
                            setDataToLocalStorage({
                                data: { user: user },
                                key: LOCAL_STORAGE_USER_DATA_KEY,
                                expiryMins: ACCESS_TOKEN_EXPIRY_MINS,
                            })
                            datadogLogs.setGlobalContext({
                                email: formFields.userName,
                            })
                        }
                    })
                    .catch((error) => {
                        setIsSubmitting(false)
                        setLoginError(getCognitoErrorMessage(error.name || 'unknown'))
                        datadogLogs.logger.error(
                            `Auth.signIn LoginForm - error: ${JSON.stringify({ error })}`,
                            {
                                email: 'formFields.userName',
                            },
                            error
                        )
                    })
            })}
        >
            <fieldset disabled={isSubmitting} className={styles.form}>
                <legend className='visually-hidden'>{`${content.userNameInput} + ${content.passwordInput}`}</legend>
                <Controller
                    control={control}
                    name='userName'
                    rules={{
                        required: true,
                        minLength: 1,
                        maxLength: 300,
                        pattern: /^[\w.!#$%'*+/=?^`{|}~-]+@[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*$/,
                        // Valid characters in email: https://ladedu.com/valid-characters-for-email-addresses-the-complete-list/
                    }}
                    render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                        <div className={styles['input-wrapper']}>
                            <LabelledInput
                                required={true}
                                htmlFor='userName'
                                label={content.userNameInput}
                                aria-describedby='userName-error-message'
                            >
                                <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                            </LabelledInput>
                            <FieldError
                                inputId='userName'
                                showError={!!errors.userName}
                                errorMessage={content.errors.userNameInput}
                            />
                        </div>
                    )}
                />
                <Controller
                    control={control}
                    name='password'
                    rules={{
                        required: true,
                        minLength: 6,
                        maxLength: 30,
                        pattern:
                            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[\^$*.[\]{}()?"!@#%&/,><':;|_~`=+-]).{6,30}$/,
                        // cognito password policy: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
                    }}
                    render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                        <div className={styles['input-wrapper']}>
                            <LabelledInput
                                required={true}
                                htmlFor='password'
                                label={content.passwordInput}
                                aria-describedby='password-error-message'
                            >
                                <PasswordInput
                                    autoComplete='off'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            </LabelledInput>
                            <FieldError
                                inputId='password'
                                showError={!!errors.password}
                                errorMessage={content.errors.passwordInput}
                            />
                            <Spacing />
                        </div>
                    )}
                />
            </fieldset>
            <div className={styles['submit-login-wrapper']}>
                <div className={styles['submit-login-button-wrapper']}>
                    <Link to={ROUTES.FORGOT_PASSWORD}>{content.forgotPasswordLinkText}</Link>
                    <Button
                        type='submit'
                        flavour='primary'
                        text={isSubmitting ? 'Submitting' : content.submitButton}
                        showSpinner={isSubmitting}
                    />
                    <p className={styles['signup-text']}>
                        {content.signupText}
                        <Link to={ROUTES.SIGNUP}>{content.signupLinkText}</Link>
                    </p>
                </div>
                {!!loginApiError && (
                    <InfoBanner
                        id='reset-password-api-error'
                        bannerType='error'
                        message={loginApiError}
                        isFocusable={true}
                    />
                )}
            </div>
        </form>
    )

    return (
        <div className={styles.container}>
            <Heading heading='2'>{content.title}</Heading>
            <Text>{content.subHeading}</Text>
            <Spacing />
            {loginForm}
        </div>
    )
}

export default LoginForm
