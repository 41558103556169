import allContent from '../content/content'
const content = allContent.cognito.errors

/*
 * all cognito available exception names and status codes:
 * https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html#API_InitiateAuth_Errors
 */
export const getCognitoErrorMessage = (exception_name: string): string => {
    let errorMessage = ''
    switch (exception_name) {
        case 'TooManyFailedAttemptsException':
            errorMessage = content.tooManyFailedAttempts
            break
        case 'CodeMismatchException':
            errorMessage = content.codeMismatch
            break
        case 'ExpiredCodeException':
            errorMessage = content.expiredCode
            break
        case 'UserNotFoundException':
            errorMessage = content.userNameNotFound
            break
        case 'InvalidParameterException':
        case 'NotAuthorizedException':
            errorMessage = content.notAuthorized
            break
        default:
            errorMessage = content.unknown
    }
    return errorMessage
}
