import React from 'react'
import styles from './InlineSpinner.module.css'
import classnames from 'classnames'

type SpinnerProps = {
    text?: string
    circleOnRight?: boolean
}

const InlineSpinner: React.FC<SpinnerProps> = ({ text, circleOnRight = false }) => {
    const spinnerClassnames = classnames(styles.spinner, { [styles.right]: circleOnRight })
    return (
        <div className={spinnerClassnames}>
            <svg className={styles.circle} viewBox='0 0 50 50'>
                <title>Spinner</title>
                <circle
                    className={styles.path}
                    cx='25'
                    cy='25'
                    r='20'
                    fill='none'
                    strokeWidth='4'
                />
            </svg>
            <div className={styles.text}>{text && <span>{text}</span>}</div>
        </div>
    )
}

export default InlineSpinner
