import React, { forwardRef } from 'react'
import classnames from 'classnames'

import Icon, { IconColours } from '../Icon/Icon'
import allContent from '../../../content/content'
import styles from './Close.module.scss'

const content = allContent.components

type CloseProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    className?: string
    text?: string
    onClick?(): void
    onBackground?: 'white' | 'lightColoured' | 'darkColoured'
    disabled?: boolean
}

const Close = forwardRef<HTMLButtonElement, CloseProps>((props, ref) => {
    const {
        className,
        text = content.closeButton,
        onClick,
        onBackground,
        disabled,
        ...rest
    } = props

    const iconColor: IconColours =
        onBackground === 'darkColoured' || onBackground === 'lightColoured'
            ? 'white'
            : 'primary-midnight'

    const classNames = classnames(
        styles['close-button'],
        { [styles[`close-button--light-coloured-background`]]: onBackground === 'lightColoured' },
        { [styles[`close-button--dark-coloured-background`]]: onBackground === 'darkColoured' },
        className
    )

    return (
        <button
            ref={ref}
            type='button'
            className={classNames}
            onClick={onClick}
            disabled={disabled}
            {...rest}
        >
            <Icon iconName='Cross' iconSize='S' iconColor={iconColor} />
            <span className={'visually-hidden'}>{text}</span>
        </button>
    )
})

Close.displayName = 'Close'
export default Close
