import React from 'react'
import classnames from 'classnames'

import styles from './Footer.module.css'

interface FooterProps {
    className?: string
    children: React.ReactNode
}

const Footer: React.FC<FooterProps> = ({ className, children }) => {
    const classNames = classnames(`${styles.container}`, className)

    return (
        <footer className={classNames}>
            <div className='tt-decoration-stripe' />
            <div className={styles.content}>{children}</div>
        </footer>
    )
}

export default Footer
