import React, { SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'

import Breadcrumb from '../../basics/Breadcrumb/Breadcrumb'
import ResetPasswordForm from '../../sections/ResetPasswordForm/ResetPasswordForm'

type ResetPasswordLayoutProps = {
    setIsAuthorised: React.Dispatch<SetStateAction<boolean>>
}

const ResetPasswordLayout: React.FC<ResetPasswordLayoutProps> = ({ setIsAuthorised }) => {
    const { state } = useLocation() // TODO, fix this. should have email already but not seeing it (maybe as not in challenge password mode - since I shouldn't be able to access this page if not in CHOOSE_PASSWORD challenge state. but i can get here from url change
    const email = state?.email
    return (
        <>
            <Breadcrumb urlList={[{ url: '/', text: 'Login' }, { text: 'Reset Password' }]} />
            <div className='general-container'>
                <ResetPasswordForm setIsAuthorised={setIsAuthorised} email={email} />
            </div>
        </>
    )
}

export default ResetPasswordLayout
