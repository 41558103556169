import React from 'react'
import ReactAutosuggest, {
    BlurEvent,
    ChangeEvent,
    SuggestionsFetchRequestedParams,
    RenderSuggestionsContainerParams,
    AutosuggestProps,
} from 'react-autosuggest'

export type AutoSuggestProps = {
    /** To allow the user to free type their input not be forced to use a value from suggestion list */
    onBlur?: (currentInputValue: string, highlightedSuggestion: any) => void
    /** for styling the input container (width etc.) */
    className?: string
    /** callback used to get input string from suggestion list values */
    getSuggestionValue(dataItem: any): string
    /** callback used get current value of input */
    inputValue: string
    /** callback used to execute code after selecting a suggestion */
    onSuggestionSelected(value: any): void
    /** name attribute */
    name: string
    /** what react-autosuggest will render when suggestions are available */
    renderSuggestion(dataItem: any): React.ReactElement
    /** callback used to track state of the input value */
    setInputValue(value: string): void
    /** callback used to manage the clearing the suggestions from the autosuggest dropdown */
    onSuggestionsClearRequested?(): void
    /** suggestions data used by renderSuggestion in the autosuggest dropdown */
    suggestionsData: any[]
    /** callback used to fetch suggestions data from an external source */
    suggestionsFetchRequest(value: string): void
    /** callback used to render a custom input */
    renderInputComponent: AutosuggestProps<any, any>['renderInputComponent']
    /** id to be assigned if more than one Autosuggest is used on the same page*/
    id?: string
    /** opens the suggestions (if present) on focus */
    openSuggestionsOnFocus?: boolean
}

const Autosuggest = ({
    className,
    getSuggestionValue,
    name,
    id = name,
    inputValue,
    onBlur,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    openSuggestionsOnFocus = false,
    renderInputComponent,
    renderSuggestion,
    setInputValue,
    suggestionsData,
    suggestionsFetchRequest,
}: AutoSuggestProps): JSX.Element => {
    const inputProps = {
        id: `${name}-auto-suggest-input`,
        value: inputValue ?? '',
        onBlur: (
            _event: React.FormEvent<HTMLElement>,
            params: BlurEvent<any> | undefined
        ): void => {
            if (onBlur) onBlur(inputValue, params?.highlightedSuggestion)
        },
        onChange: (_event: React.FormEvent<HTMLElement>, params: ChangeEvent): void => {
            // THIS IS CALLED when input has change AND when suggestion is selected
            setInputValue(params?.newValue)
        },
    }

    const onSuggestionsFetchRequested = (request: SuggestionsFetchRequestedParams): void => {
        const { value } = request // Also has variable 'reason'<string> to see what action caused this function call.
        suggestionsFetchRequest(value) // Calls provided function which should take a substring value to be used to fetch suggestion data.
    }

    const shouldRenderSuggestions = (value: string, reason: string): boolean => {
        if (reason === 'input-focused') return openSuggestionsOnFocus // Allow use case to say if focus opens the suggestions (pre-loaded data that is just being filtered, yes. data needs string to fetch, no.)
        return !!value || !openSuggestionsOnFocus // Do not render list if no value present (if they have deleted the last char) and we don't want focus to open the list.
    }

    const renderSuggestionsContainer = ({
        containerProps,
        children,
    }: RenderSuggestionsContainerParams): React.ReactElement => {
        const emptyList = suggestionsData.length === 0
        // This is needed to fix lacking aria props (adds aria-hidden when list is empty)
        // And to show a pointless message when api doesn't have any results for input string.
        return (
            <div {...containerProps} role={undefined} aria-hidden={emptyList}>
                {children}
            </div>
        )
    }

    const suggestionSelectedHandler = (
        event: React.FormEvent<HTMLElement>,
        suggestion: any
    ): void => {
        onSuggestionSelected(suggestion.suggestion)
    }

    const theme = {
        suggestionsContainer: {
            position: 'relative',
        },
        suggestionsList: {
            backgroundColor: 'var(--white)',
            borderRadius: 'var(--normal-border-radius)',
            border: '1px solid var(--border-gray)',
            minWidth: '200px',
            width: '100%',
            position: 'absolute',
            zIndex: '1',
            marginTop: '3px',
        },
        suggestion: {
            backgroundColor: 'var(--white)',
            padding: '4px 8px',
            listStyleType: 'none',
            color: 'var(--primary-midnight)',
        },
        suggestionHighlighted: {
            backgroundColor: 'var(--highlight-blue)',
            cursor: 'pointer',
        },
    }

    return (
        <div className={className}>
            <ReactAutosuggest
                id={id}
                shouldRenderSuggestions={shouldRenderSuggestions}
                suggestions={suggestionsData}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                renderSuggestionsContainer={renderSuggestionsContainer}
                highlightFirstSuggestion={true} // can be true as long as the on blur is turned off (allows keyboard focus to start on first item)
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={suggestionSelectedHandler}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                theme={theme}
                renderInputComponent={renderInputComponent}
            />
        </div>
    )
}

export default Autosuggest
