import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'

import Button from '../../../basics/Button/Button'
import ToggleSwitch from '../../../basics/INPUTS/ToggleSwitch/ToggleSwitch'
import LabelledInput from '../../LabelledInput/LabelledInput'
import TextInput from '../../../basics/INPUTS/TextInput/TextInput'
import Text from '../../../basics/Text/Text'
import InfoBanner from '../../InfoBanner/InfoBanner'
import {
    SUPPLIER_CREDENTIAL_FIELDS,
    SUPPLIER_CREDENTIAL_CREATE,
    SUPPLIER_CREDENTIAL_UPDATE,
    SUPPLIER_CREDENTIAL_AVAILABLE_TOGGLE,
} from '../../../../graphql-queries/queries'
import { SupplierCode } from '../../../../utils/constants'

import allContent from '../../../../content/content'
import styles from './CredentialsListItem.module.scss'

const content = allContent.salesChannelManagementPage.editSalesChannel
const supplierNamescontent = allContent.supplierNames

export type CredentialListItem = {
    /** is available */
    available: boolean
    /** has supplier credentials filled in */
    created: boolean
    /** Supplier code */
    supplierCode: SupplierCode
}

type FieldItem = {
    /** Field name*/
    name: string
    /** Field value*/
    value: string | null
    /** Field display order */
    order: number
    /** Field description */
    helpText: string
    /** Field display name */
    displayName: string
}

type CreateFieldItem = {
    /** Field name*/
    name: string
    /** Field value*/
    value: string
}

type CredentialsListItemProps = {
    credentialItem: CredentialListItem
    apiClient: ApolloClient<NormalizedCacheObject>
    salesChannelId: string
}

const CredentialsListItem: React.FC<CredentialsListItemProps> = ({
    credentialItem,
    apiClient,
    salesChannelId,
}: CredentialsListItemProps) => {
    const [supplierCredentialFields, setSupplierCredentialFields] = useState<any>([])
    const [fetchingSupplierFields, setFetchingSupplierFields] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [credentialsCreated, setCredentialsCreated] = useState<boolean>(credentialItem.created)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [successMessage, setSuccessMessage] = useState<string | null>(null)
    const [isEnabled, setIsEnabled] = useState<boolean>(credentialItem.available)
    const [isOpen, setIsOpen] = useState(false)

    const supplierCredentialsFieldsHandler = async (supplierCode: string): Promise<void> => {
        setSupplierCredentialFields([])
        setFetchingSupplierFields(true)
        setErrorMessage(null)
        setSuccessMessage(null)
        await apiClient
            .query({
                query: SUPPLIER_CREDENTIAL_FIELDS,
                variables: { supplierCode: supplierCode, salesChannelId: salesChannelId },
            })
            .then((response) => {
                if (response?.data?.supplierCredentialFields) {
                    setSupplierCredentialFields(
                        [...response.data.supplierCredentialFields].sort(compareOrderNumbers) // we sort fields by field.order value
                    )
                    datadogLogs.logger.info(
                        `Query SUPPLIER_CREDENTIAL_FIELDS - data: ${JSON.stringify(
                            response.data.supplierCredentialFields
                        )}`
                    )
                }
            })
            .catch((error) => {
                setErrorMessage(content.errors.loadingSupplierList)
                datadogLogs.logger.error(
                    `Query SUPPLIER_CREDENTIAL_FIELDS CredentialsItems error: ${JSON.stringify(
                        error
                    )}`
                )
                setIsOpen(false)
            })
        setFetchingSupplierFields(false)
        setIsOpen(true)
    }

    const supplierCredentialsCreateHandler = async (
        supplierCode: string,
        fields: CreateFieldItem[]
    ): Promise<void> => {
        setSubmitting(true)
        await apiClient
            .mutate({
                mutation: SUPPLIER_CREDENTIAL_CREATE,
                variables: {
                    fields: fields,
                    supplierCode: supplierCode,
                    salesChannelId: salesChannelId,
                },
            })
            .then((response) => {
                if (response?.data?.supplierCredentialCreate?.created) {
                    datadogLogs.logger.info(
                        `Query SUPPLIER_CREDENTIAL_CREATE added supplier credentials for: ${supplierCode}`
                    )
                    setCredentialsCreated(true)
                    setIsEnabled(true)
                    setSuccessMessage(content.form.supplierCredentialsSaved)
                }
            })
            .catch((error) => {
                datadogLogs.logger.error(
                    `Mutate SUPPLIER_CREDENTIAL_CREATE CredentialsItem - error: ${JSON.stringify(
                        error
                    )}`
                )
                setErrorMessage(content.errors.savingSupplierCredentials)
            })
        setSubmitting(false)
        setIsOpen(false)
    }

    const supplierCredentialsUpdateHandler = async (
        supplierCode: string,
        fields: CreateFieldItem[]
    ): Promise<void> => {
        setSubmitting(true)
        await apiClient
            .mutate({
                mutation: SUPPLIER_CREDENTIAL_UPDATE,
                variables: {
                    fields: fields,
                    supplierCode: supplierCode,
                    salesChannelId: salesChannelId,
                },
            })
            .then((response) => {
                if (response?.data?.supplierCredentialUpdate?.created) {
                    datadogLogs.logger.info(
                        `Mutate SUPPLIER_CREDENTIAL_UPDATE Successfully updated supplier credentials for: ${supplierCode}`
                    )
                    setCredentialsCreated(true)
                    setSuccessMessage(content.form.supplierCredentialsUpdated)
                }
            })
            .catch((error) => {
                console.log(error) // eslint-disable-line no-console
                datadogLogs.logger.error(
                    `Mutate SUPPLIER_CREDENTIAL_UPDATE CredentialsItem - error: ${JSON.stringify(
                        error
                    )}`
                )
                setErrorMessage(content.errors.savingSupplierCredentials)
            })
        setSubmitting(false)
        setIsOpen(false)
    }

    const supplierCredentialsAvailableToggleHandler = async (
        supplierCode: string,
        available: boolean
    ): Promise<void> => {
        setSubmitting(true)
        setErrorMessage(null)
        setSuccessMessage(null)
        await apiClient
            .mutate({
                mutation: SUPPLIER_CREDENTIAL_AVAILABLE_TOGGLE,
                variables: {
                    available: available,
                    supplierCode: supplierCode,
                    salesChannelId: salesChannelId,
                },
            })
            .then((response) => {
                if (
                    response?.data?.supplierCredentialAvailableToggle &&
                    response?.data?.supplierCredentialAvailableToggle?.available === available
                ) {
                    datadogLogs.logger.info(
                        `Mutate SUPPLIER_CREDENTIAL_AVAILABLE_TOGGLE - Successfully toggled supplier credentials ${
                            response?.data?.supplierCredentialAvailableToggle?.available
                                ? 'on'
                                : 'off'
                        } for: ${supplierCode}`
                    )
                    setIsEnabled(!isEnabled)
                    setSuccessMessage(
                        available ? content.form.supplierToggleOn : content.form.supplierToggleOff
                    )
                }
            })
            .catch((error) => {
                datadogLogs.logger.error(
                    `Mutate SUPPLIER_CREDENTIAL_AVAILABLE_TOGGLE CredentialsItem - available:${available}, supplierCode:${supplierCode}, error: ${JSON.stringify(
                        error
                    )}`
                )
                setErrorMessage(
                    error.message === "Supplier Credential doesn't exist"
                        ? content.errors.supplierCredentialsDoNotExist
                        : content.errors.savingSupplierCredentials
                )
            })
        setSubmitting(false)
    }

    const handleToggleChange = (): void => {
        supplierCredentialsAvailableToggleHandler(credentialItem.supplierCode, !isEnabled)
    }
    const toggleFields = (supplierCode: string): void => {
        if (isOpen) {
            setIsOpen(false)
            setErrorMessage('')
            setSuccessMessage('')
        }
        if (!isOpen) {
            supplierCredentialsFieldsHandler(supplierCode).catch(
                (error) => console.log(error) // eslint-disable-line no-console
            )
        }
    }

    function compareOrderNumbers(a: FieldItem, b: FieldItem): number {
        return a.order - b.order
    }

    const { control, handleSubmit } = useForm<Record<string, string>>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
    })

    const fields = (
        <ul className={styles['item__fields-container']}>
            {supplierCredentialFields.map((field: FieldItem) => {
                return (
                    <Controller
                        key={field.name}
                        name={field.name}
                        control={control}
                        defaultValue={field.value ? field.value : ''}
                        render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                            <li className={styles['item__field']}>
                                <LabelledInput
                                    key={field.name}
                                    htmlFor={field.name}
                                    label={field.displayName || field.name}
                                    aria-describedby={field.helpText}
                                >
                                    <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                                </LabelledInput>
                                <Text size='S'>{field.helpText}</Text>
                            </li>
                        )}
                    />
                )
            })}
        </ul>
    )

    const buttonId = `${credentialItem.supplierCode}-button`
    const fieldsId = `${credentialItem.supplierCode}-fields`
    const supplierName = supplierNamescontent
        ? supplierNamescontent[credentialItem.supplierCode]
        : credentialItem.supplierCode
    const buttonText = credentialsCreated ? content.form.edit : content.form.add
    const buttonIcon = credentialsCreated ? 'Pencil' : 'Plus'
    return (
        <li className={styles['item__container']}>
            <div className={styles['item__controls']}>
                <Text weight='bold'>{supplierName}</Text>
                <ToggleSwitch
                    isChecked={isEnabled}
                    disabled={submitting}
                    onChange={handleToggleChange}
                    label={`${content.form.enable}/${content.form.disable} ${credentialItem.supplierCode}`}
                />
                <Button
                    flavour='text'
                    text={isOpen ? content.form.cancel : buttonText}
                    iconName={isOpen ? undefined : buttonIcon}
                    onClick={(): void => toggleFields(credentialItem.supplierCode)}
                    type='button'
                    showSpinner={fetchingSupplierFields}
                    id={buttonId}
                    aria-expanded={isOpen}
                    aria-controls={fieldsId}
                />
            </div>
            <div id={fieldsId} className={styles['item__fields']} aria-hidden={!isOpen}>
                {!fetchingSupplierFields && supplierCredentialFields.length > 0 && (
                    <form
                        onSubmit={handleSubmit((formFields) => {
                            const supplierCredentialsInput = Object.entries(formFields).map(
                                (form) => {
                                    return { name: form[0], value: form[1] }
                                }
                            )
                            credentialsCreated
                                ? supplierCredentialsUpdateHandler(
                                      credentialItem.supplierCode,
                                      supplierCredentialsInput
                                  )
                                : supplierCredentialsCreateHandler(
                                      credentialItem.supplierCode,
                                      supplierCredentialsInput
                                  )
                        })}
                    >
                        {fields}
                        <div className={styles['item-save-button']}>
                            <Button
                                type='submit'
                                text={content.form.save}
                                flavour='primary'
                                showSpinner={submitting}
                            />
                        </div>
                    </form>
                )}
            </div>
            <div className={styles['item__message']}>
                {!!errorMessage && (
                    <InfoBanner
                        id={`${credentialItem.supplierCode}-error-banner`}
                        bannerType='error'
                        message={errorMessage}
                    />
                )}
                {!!successMessage && (
                    <InfoBanner
                        id={`${credentialItem.supplierCode}-success-banner`}
                        bannerType='success'
                        message={successMessage}
                    />
                )}
            </div>
        </li>
    )
}

export default CredentialsListItem
