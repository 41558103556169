import React, { SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getDataFromLocalStorage } from '../../../utils/use-local-storage'
import { COGNITO_ACTIONS, LOCAL_STORAGE_USER_DATA_KEY } from '../../../utils/constants'
import { ROUTES } from '../../../App'

import NewPasswordForm from '../../sections/NewPasswordForm/NewPasswordForm'

type NewPasswordLayoutProps = {
    setIsAuthorised: React.Dispatch<SetStateAction<boolean>>
}
const NewPasswordLayout: React.FC<NewPasswordLayoutProps> = ({ setIsAuthorised }) => {
    const [userData, setUserData] = useState<Record<string, any>>()
    const navigate = useNavigate()

    useEffect(() => {
        const data = getDataFromLocalStorage({ key: LOCAL_STORAGE_USER_DATA_KEY })
        if (!data) {
            // If no user data, then user needs to login.
            // eslint-disable-next-line no-console
            console.log(' NO USER DATA, so navigating to login:', data)
            navigate(ROUTES.LOGIN)
        }
        if (data?.challengeName === COGNITO_ACTIONS.new_password) {
            // If user data and challenge, the user should be on this form.
            // eslint-disable-next-line no-console
            console.log('Challenge name is NEW_PASSWORD, so staying here')
            setUserData(data)
        } else {
            // If user data and not a challenge, the user should not be on this form.
            navigate(ROUTES.HOME)
            // eslint-disable-next-line no-console
            console.log('User data but not challenge.... so redirect back to home?')
        }
    }, [navigate])

    return (
        <div className='general-container'>
            <NewPasswordForm
                setIsAuthorised={setIsAuthorised}
                setUserData={setUserData}
                userData={userData}
            />
        </div>
    )
}
export default NewPasswordLayout
