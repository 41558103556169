import React, { useEffect, useMemo, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { datadogLogs } from '@datadog/browser-logs'
import { ROUTES } from 'App'
import Heading from '../../basics/Heading/Heading'
import Link from 'components/basics/Link/Link'
import CredentialsList from 'components/blocks/CredentialsList/CredentialsList'
import { CredentialListItem } from '../../blocks/CredentialsList/CredentialsListItem/CredentialsListItem'
import { SUPPLIER_CREDENTIALS_AND_SALES_CHANNEL } from '../../../graphql-queries/queries'
import { getDataFromLocalStorage } from '../../../utils/use-local-storage'
import { LOCAL_STORAGE_USER_DATA_KEY } from '../../../utils/constants'
import { checkAndPerformUserSessionRefreshIfNeeded } from '../../../utils/check-and-perform-user-session-refresh-if-needed'

import { getTenantId } from '../../../utils/cognito-helpers/get-tenant-id'
import allContent from '../../../content/content'
import styles from './EditSalesChannel.module.css'

const content = allContent.salesChannelManagementPage.editSalesChannel

type EditSalesChannelProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    salesChannelId: string
}

export type SalesChannelAPIResponse = {
    salesChannelId: string
    title: string
}

export function getSalesChannel(
    salesChannels: SalesChannelAPIResponse[],
    salesChannelId: string
): SalesChannelAPIResponse | undefined {
    return salesChannels.find((salesChannel) => salesChannel.salesChannelId === salesChannelId)
}

const EditSalesChannel: React.FC<EditSalesChannelProps> = ({ apiClient, salesChannelId }) => {
    const navigate = useNavigate()
    const [supplierCredentials, setSupplierCredentials] = useState<CredentialListItem[]>([])
    const [salesChannelInfo, setSalesChannelInfo] = useState<SalesChannelAPIResponse | undefined>()
    const [fetchingSupplierCredentials, setFetchingSupplierCredentials] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const userData = useMemo(
        () => getDataFromLocalStorage({ key: LOCAL_STORAGE_USER_DATA_KEY }) ?? {},
        []
    )

    useEffect((): void => {
        const tenantId = getTenantId(userData)
        checkAndPerformUserSessionRefreshIfNeeded({ timeNow: Date.now(), navigate: navigate })
        if (tenantId && !supplierCredentials?.length) {
            setFetchingSupplierCredentials(true)
            apiClient
                .query({
                    query: SUPPLIER_CREDENTIALS_AND_SALES_CHANNEL,
                    variables: { salesChannelId: salesChannelId },
                })
                .then((response) => {
                    const apiResponse = response
                    if (apiResponse?.data?.supplierCredentials) {
                        setSupplierCredentials(response?.data?.supplierCredentials)
                    }
                    if (apiResponse?.data?.salesChannels) {
                        setSalesChannelInfo(
                            getSalesChannel(response?.data?.salesChannels, salesChannelId)
                        )
                    }
                    if (apiResponse.errors?.length)
                        setErrorMessage(content.errors.loadingSupplierList)
                    setFetchingSupplierCredentials(false)
                })
                .catch((error) => {
                    datadogLogs.logger.error(
                        `Query SUPPLIER_CREDENTIALS_AND_SALES_CHANNEL - error: ${error.message}`
                    )
                    setErrorMessage(content.errors.loadingSupplierList)
                    setFetchingSupplierCredentials(false)
                })
        }
    }, [errorMessage]) // eslint-disable-line react-hooks/exhaustive-deps
    // empty dependency because we only want this fetched once, and we use the data structure it fetches to determine if its been fetched... which if we didn't check would mean we could have a filled dependency array that would only fetch once?

    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.title}</Heading>
            <Link to={ROUTES.SALES_CHANNEL_MANAGEMENT}>
                {content.backToSalesChannelsManagement}
            </Link>
            <CredentialsList
                credentialItems={supplierCredentials}
                apiClient={apiClient}
                salesChannelInfo={salesChannelInfo}
                fetchingSalesChannelInfo={fetchingSupplierCredentials}
                errorMessage={errorMessage}
            />
        </div>
    )
}

export default EditSalesChannel
