import React from 'react'
import { createRoot } from 'react-dom/client'
import { ApolloClient, createHttpLink, DefaultOptions, InMemoryCache } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import * as Sentry from '@sentry/react'
import { BrowserRouter } from 'react-router-dom'
import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom'
import packageJson from '../package.json'
import { Auth } from '@aws-amplify/auth'

import App from './App'
import authHeader from './utils/auth-header'
import './index.css'

const APP_NAME = packageJson.name
const APP_VERSION = packageJson.version

// Only initiate these in development, stage or production envs - not local dev.
if (process.env.NODE_ENV === 'production') {
    try {
        // eslint-disable-next-line no-console
        console.log('Initializing Datadog, Sentry, Vitally and HotJar...')
        if (process.env.REACT_APP_DD_LOGS_CLIENT_TOKEN) {
            datadogLogs.init({
                clientToken: process.env.REACT_APP_DD_LOGS_CLIENT_TOKEN,
                site: 'datadoghq.eu',
                forwardErrorsToLogs: true,
                sessionSampleRate: 100,
                service: APP_NAME,
                version: APP_VERSION,
                env: process.env.REACT_APP_ENVIRONMENT_NAME, // Development, Stage and Prod are differentiated with this value (both use same token)
            })
        }

        if (
            process.env.REACT_APP_DD_RUM_APPLICATION_ID &&
            process.env.REACT_APP_DD_RUM_CLIENT_TOKEN &&
            process.env.REACT_APP_BACKEND_SERVICE_URL
        ) {
            datadogRum.init({
                applicationId: process.env.REACT_APP_DD_RUM_APPLICATION_ID,
                clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN,
                site: 'datadoghq.eu',
                service: APP_NAME,
                env: process.env.REACT_APP_ENVIRONMENT_NAME, // Sandbox and Prod pass different values.
                version: APP_VERSION,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackUserInteractions: true,
                allowedTracingUrls: [
                    {
                        match: process.env.REACT_APP_BACKEND_SERVICE_URL, // TODO: make sure all api urls are here
                        propagatorTypes: ['datadog', 'tracecontext'],
                    },
                ],
            })

            // Session recording only for production (not development or stage)
            if (process.env.REACT_APP_ENVIRONMENT_NAME === 'production') {
                datadogRum.startSessionReplayRecording()
            }
        }
        if (process.env.REACT_APP_SENTRY_DSN) {
            Sentry.init({
                environment: process.env.REACT_APP_ENVIRONMENT_NAME,
                dsn: process.env.REACT_APP_SENTRY_DSN,
                integrations: [
                    new Sentry.BrowserTracing({
                        // See docs for support of different versions of variation of react router
                        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                            React.useEffect,
                            useLocation,
                            useNavigationType,
                            createRoutesFromChildren,
                            matchRoutes
                        ),
                    }),
                    new Sentry.Replay(),
                ],
                // We recommend adjusting this value in production, or using tracesSampler
                // for finer control
                tracesSampleRate: 1.0,
            })
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error initializing plug-ins', error)
        datadogLogs.logger.error(`source: app, Error initializing plugins', {}, error`)
    }
}

// REACT_APP_ENVIRONMENT_NAME tells the app the environment its in. (Unlike NODE_ENV which is the app build type)
if (
    process.env.REACT_APP_ENVIRONMENT_NAME === 'development' ||
    process.env.REACT_APP_ENVIRONMENT_NAME === 'stage' ||
    process.env.REACT_APP_ENVIRONMENT_NAME === 'local'
) {
    // eslint-disable-next-line no-console
    console.log('None prod environment detected. PROCESS.ENV:', process.env)
}

export const STAGE_USER_POOL_DOMAINS = [
    'http://localhost:3006',
    'https://hal9000.traveltekstage.com',
]

export const DEV_USER_POOL_DOMAINS = ['http://localhost:4007', 'https://hal9000.traveltekdev.com']

export const NEW_USER_POOL_DOMAINS = [
    'http://localhost:4008',
    'https://new-pool.hal9000.traveltekdev.com',
    // 'https://new-pool.hal9000.traveltekstage.com', // these will have different user pools once its deployed fully. we're only testing at the moment
]

const appDomain = window.location.origin
// eslint-disable-next-line no-console
console.log('APP DOMAIN:', appDomain)
if (DEV_USER_POOL_DOMAINS.includes(appDomain)) {
    // eslint-disable-next-line no-console
    console.log('DEV USER POOL DOMAIN DETECTED')
    /** Point to NEW user pool for development of Single user pool work*/
    Auth.configure({
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_f44PbMtyJ',
        userPoolWebClientId: '5hufqn5brpqfduegda583e4rh2',
        mandatorySignIn: true,
    })
} else if (NEW_USER_POOL_DOMAINS.includes(appDomain)) {
    // eslint-disable-next-line no-console
    console.log('NEW USER POOL DOMAIN DETECTED')
    /** Point to NEW user pool for development of Single user pool work*/
    Auth.configure({
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_AcdPfJVGR',
        userPoolWebClientId: '26e9vlnpta2gc849odi62hifim',
        mandatorySignIn: true,
    })
} else if (STAGE_USER_POOL_DOMAINS.includes(appDomain)) {
    // eslint-disable-next-line no-console
    console.log('STAGE USER POOL USER POOL DOMAIN DETECTED')
    /** Point to Stage user pool */
    Auth.configure({
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_khwaccfJc',
        userPoolWebClientId: '49h64mgjkuc29li6rp99ncgpmk',
        mandatorySignIn: true,
    })
} else {
    // eslint-disable-next-line no-console
    console.log('PRODUCTION POOL USER POOL DOMAIN DETECTED')
    /** Point to PRODUCTION user pool */
    Auth.configure({
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_gqN6lJas4',
        userPoolWebClientId: '454qqd66sv0bmmovmebbkvaafj',
        mandatorySignIn: true,
    })
}

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_BACKEND_SERVICE_URL,
    fetch,
})

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}
/**
 * ApolloClient errorPolicy options
 * none: This is the default policy to match how Apollo Client 1.0 worked. Any GraphQL Errors are treated the same as network errors and any data is ignored from the response.
 * ignore: Ignore allows you to read any data that is returned alongside GraphQL Errors, but doesn't save the errors or report them to your UI.
 * all: Using the all policy is the best way to notify your users of potential issues while still showing as much data as possible from your server. It saves both data and errors into the Apollo Cache so your UI can use them.
 */

const API_CLIENT = new ApolloClient({
    /** HttpLink is used by default, but manually setting allows us to specify a fetch API */
    link: authHeader.concat(httpLink),
    /** caches user queries in-memory to reduce redundant fetches */
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
})

const container = document.getElementById('app')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App apiClient={API_CLIENT} />
        </BrowserRouter>
    </React.StrictMode>
)
