import React from 'react'
import classnames from 'classnames'

import styles from './ColoredLine.module.scss'

type ColoredLineProps = React.HTMLAttributes<HTMLHRElement> & {
    className?: string
    padding?: 'single' | 'double' | 'non'
}

const ColoredLine: React.FC<ColoredLineProps> = ({ className, padding = 'non' }) => {
    const coloredLineClassNames = classnames(
        styles['colored-line'],
        { [styles[`padding--${padding}`]]: padding === 'non' },
        { [styles[`padding--${padding}`]]: padding === 'single' },
        { [styles[`padding--${padding}`]]: padding === 'double' },
        className
    )

    return <hr data-testid='hr' className={coloredLineClassNames} />
}

export default ColoredLine
