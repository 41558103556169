export const countryData = [
    {
        name: 'United States',
        iso2Code: 'US',
        phoneMask: '+. (...) ...-....',
        phoneCode: '1',
        flag: '🇺🇸',
    },
    {
        name: 'Canada',
        iso2Code: 'CA',
        phoneMask: '+. (...) ...-....',
        phoneCode: '1',
        flag: '🇨🇦',
    },
    {
        name: 'United Kingdom',
        iso2Code: 'GB',
        phoneMask: '+.. .... ......',
        phoneCode: '44',
        flag: '🇬🇧',
    },
    {
        name: 'Afghanistan',
        iso2Code: 'AF',
        phoneMask: '+..-..-...-....',
        phoneCode: '93',
        flag: '🇦🇫',
    },
    {
        name: 'Åland Islands',
        iso2Code: 'AX',
        phoneMask: '',
        phoneCode: '358',
        flag: '🇦🇽',
    },
    {
        name: 'Albania',
        iso2Code: 'AL',
        phoneMask: '+...(...)...-...',
        phoneCode: '355',
        flag: '🇦🇱',
    },
    {
        name: 'Algeria',
        iso2Code: 'DZ',
        phoneMask: '+...-..-...-....',
        phoneCode: '213',
        flag: '🇩🇿',
    },
    {
        name: 'American Samoa',
        iso2Code: 'AS',
        phoneMask: '+.(...)...-....',
        phoneCode: '1684',
        flag: '🇦🇸',
    },
    {
        name: 'Andorra',
        iso2Code: 'AD',
        phoneMask: '+...-...-...',
        phoneCode: '376',
        flag: '🇦🇩',
    },
    {
        name: 'Angola',
        iso2Code: 'AO',
        phoneMask: '+...(...)...-...',
        phoneCode: '244',
        flag: '🇦🇴',
    },
    {
        name: 'Anguilla',
        iso2Code: 'AI',
        phoneMask: '+.(...)...-....',
        phoneCode: '1264',
        flag: '🇦🇮',
    },
    {
        name: 'Antarctica',
        iso2Code: 'AQ',
        phoneMask: '',
        phoneCode: '672',
        flag: '🇦🇶',
    },
    {
        name: 'Antigua and Barbuda',
        iso2Code: 'AG',
        phoneMask: '+.(...)...-....',
        phoneCode: '1268',
        flag: '🇦🇬',
    },
    {
        name: 'Argentina',
        iso2Code: 'AR',
        phoneMask: '+..(...)...-....',
        phoneCode: '54',
        flag: '🇦🇷',
    },
    {
        name: 'Armenia',
        iso2Code: 'AM',
        phoneMask: '+...-..-...-...',
        phoneCode: '374',
        flag: '🇦🇲',
    },
    {
        name: 'Aruba',
        iso2Code: 'AW',
        phoneMask: '+...-...-....',
        phoneCode: '297',
        flag: '🇦🇼',
    },
    {
        name: 'Australia',
        iso2Code: 'AU',
        phoneMask: '+.. ... ... ...',
        phoneCode: '61',
        flag: '🇦🇺',
    },
    {
        name: 'Austria',
        iso2Code: 'AT',
        phoneMask: '+..(...)...-....',
        phoneCode: '43',
        flag: '🇦🇹',
    },
    {
        name: 'Azerbaijan',
        iso2Code: 'AZ',
        phoneMask: '+...-..-...-..-..',
        phoneCode: '994',
        flag: '🇦🇿',
    },
    {
        name: 'Bahamas, The',
        iso2Code: 'BS',
        phoneMask: '+.(...)...-....',
        phoneCode: '1242',
        flag: '🇧🇸',
    },
    {
        name: 'Bahrain',
        iso2Code: 'BH',
        phoneMask: '+...-....-....',
        phoneCode: '973',
        flag: '🇧🇭',
    },
    {
        name: 'Bangladesh',
        iso2Code: 'BD',
        phoneMask: '+...-..-...-...',
        phoneCode: '880',
        flag: '🇧🇩',
    },
    {
        name: 'Barbados',
        iso2Code: 'BB',
        phoneMask: '+.(...)...-....',
        phoneCode: '1246',
        flag: '🇧🇧',
    },
    {
        name: 'Belarus',
        iso2Code: 'BY',
        phoneMask: '+...(..)...-..-..',
        phoneCode: '375',
        flag: '🇧🇾',
    },
    {
        name: 'Belgium',
        iso2Code: 'BE',
        phoneMask: '+.. ... .. .. ..',
        phoneCode: '32',
        flag: '🇧🇪',
    },
    {
        name: 'Belize',
        iso2Code: 'BZ',
        phoneMask: '+...-...-....',
        phoneCode: '501',
        flag: '🇧🇿',
    },
    {
        name: 'Benin',
        iso2Code: 'BJ',
        phoneMask: '+...-..-..-....',
        phoneCode: '229',
        flag: '🇧🇯',
    },
    {
        name: 'Bermuda',
        iso2Code: 'BM',
        phoneMask: '+.(...)...-....',
        phoneCode: '1441',
        flag: '🇧🇲',
    },
    {
        name: 'Bhutan',
        iso2Code: 'BT',
        phoneMask: '+...-.-...-...',
        phoneCode: '975',
        flag: '🇧🇹',
    },
    {
        name: 'Bolivia (Plurinational State of)',
        iso2Code: 'BO',
        phoneMask: '+...-.-...-....',
        phoneCode: '591',
        flag: '🇧🇴',
    },
    {
        name: 'Bonaire, Sint Eustatius and Saba',
        iso2Code: 'BQ',
        phoneMask: '+...-...-....',
        phoneCode: '599',
        flag: '🇧🇶',
    },
    {
        name: 'Bosnia and Herzegovina',
        iso2Code: 'BA',
        phoneMask: '+...-..-....',
        phoneCode: '387',
        flag: '🇧🇦',
    },
    {
        name: 'Botswana',
        iso2Code: 'BW',
        phoneMask: '+...-..-...-...',
        phoneCode: '267',
        flag: '🇧🇼',
    },
    {
        name: 'Bouvet Island',
        iso2Code: 'BV',
        phoneMask: '',
        phoneCode: '47',
        flag: '🇧🇻',
    },
    {
        name: 'Brazil',
        iso2Code: 'BR',
        phoneMask: '+..-..-....-....',
        phoneCode: '55',
        flag: '🇧🇷',
    },
    {
        name: 'British Indian Ocean Territory',
        iso2Code: 'IO',
        phoneMask: '+...-...-....',
        phoneCode: '246',
        flag: '🇮🇴',
    },
    {
        name: 'Brunei Darussalam',
        iso2Code: 'BN',
        phoneMask: '+...-...-....',
        phoneCode: '673',
        flag: '🇧🇳',
    },
    {
        name: 'Bulgaria',
        iso2Code: 'BG',
        phoneMask: '+...(...)...-...',
        phoneCode: '359',
        flag: '🇧🇬',
    },
    {
        name: 'Burkina Faso',
        iso2Code: 'BF',
        phoneMask: '+...-..-..-....',
        phoneCode: '226',
        flag: '🇧🇫',
    },
    {
        name: 'Burundi',
        iso2Code: 'BI',
        phoneMask: '+...-..-..-....',
        phoneCode: '257',
        flag: '🇧🇮',
    },
    {
        name: 'Cabo Verde',
        iso2Code: 'CV',
        phoneMask: '+...(...)..-..',
        phoneCode: '238',
        flag: '🇨🇻',
    },
    {
        name: 'Cambodia',
        iso2Code: 'KH',
        phoneMask: '+...-..-...-...',
        phoneCode: '855',
        flag: '🇰🇭',
    },
    {
        name: 'Cameroon',
        iso2Code: 'CM',
        phoneMask: '+...-....-....',
        phoneCode: '237',
        flag: '🇨🇲',
    },
    {
        name: 'Cayman Islands',
        iso2Code: 'KY',
        phoneMask: '+.(...)...-....',
        phoneCode: '1345',
        flag: '🇰🇾',
    },
    {
        name: 'Central African Republic',
        iso2Code: 'CF',
        phoneMask: '+...-..-..-....',
        phoneCode: '236',
        flag: '🇨🇫',
    },
    {
        name: 'Chad',
        iso2Code: 'TD',
        phoneMask: '+...-..-..-..-..',
        phoneCode: '235',
        flag: '🇹🇩',
    },
    {
        name: 'Chile',
        iso2Code: 'CL',
        phoneMask: '+..-.-....-....',
        phoneCode: '56',
        flag: '🇨🇱',
    },
    {
        name: 'China',
        iso2Code: 'CN',
        phoneMask: '+.. ..-........',
        phoneCode: '86',
        flag: '🇨🇳',
    },
    {
        name: 'Christmas Island',
        iso2Code: 'CX',
        phoneMask: '',
        phoneCode: '61',
        flag: '🇨🇽',
    },
    {
        name: 'Cocos (Keeling) Islands',
        iso2Code: 'CC',
        phoneMask: '',
        phoneCode: '61',
        flag: '🇨🇨',
    },
    {
        name: 'Colombia',
        iso2Code: 'CO',
        phoneMask: '+..(...)...-....',
        phoneCode: '57',
        flag: '🇨🇴',
    },
    {
        name: 'Comoros',
        iso2Code: 'KM',
        phoneMask: '+...-..-.....',
        phoneCode: '269',
        flag: '🇰🇲',
    },
    {
        name: 'Congo',
        iso2Code: 'CG',
        phoneMask: '+...-..-...-....',
        phoneCode: '242',
        flag: '🇨🇬',
    },
    {
        name: 'Congo (Democratic Republic of the)',
        iso2Code: 'CD',
        phoneMask: '+...(...)...-...',
        phoneCode: '243',
        flag: '🇨🇩',
    },
    {
        name: 'Cook Islands',
        iso2Code: 'CK',
        phoneMask: '+...-..-...',
        phoneCode: '682',
        flag: '🇨🇰',
    },
    {
        name: 'Costa Rica',
        iso2Code: 'CR',
        phoneMask: '+... ....-....',
        phoneCode: '506',
        flag: '🇨🇷',
    },
    {
        name: 'Côte d"Ivoire',
        iso2Code: 'CI',
        phoneMask: '+...-..-...-...',
        phoneCode: '225',
        flag: '🇨🇮',
    },
    {
        name: 'Croatia',
        iso2Code: 'HR',
        phoneMask: '+...-..-...-...',
        phoneCode: '385',
        flag: '🇭🇷',
    },
    {
        name: 'Cuba',
        iso2Code: 'CU',
        phoneMask: '+..-.-...-....',
        phoneCode: '53',
        flag: '🇨🇺',
    },
    {
        name: 'Curaçao',
        iso2Code: 'CW',
        phoneMask: '+...-...-....',
        phoneCode: '599',
        flag: '🇨🇼',
    },
    {
        name: 'Cyprus',
        iso2Code: 'CY',
        phoneMask: '+...-..-...-...',
        phoneCode: '357',
        flag: '🇨🇾',
    },
    {
        name: 'Czechia',
        iso2Code: 'CZ',
        phoneMask: '+...(...)...-...',
        phoneCode: '420',
        flag: '🇨🇿',
    },
    {
        name: 'Denmark',
        iso2Code: 'DK',
        phoneMask: '+.. .. .. .. ..',
        phoneCode: '45',
        flag: '🇩🇰',
    },
    {
        name: 'Djibouti',
        iso2Code: 'DJ',
        phoneMask: '+...-..-..-..-..',
        phoneCode: '253',
        flag: '🇩🇯',
    },
    {
        name: 'Dominica',
        iso2Code: 'DM',
        phoneMask: '+.(...)...-....',
        phoneCode: '1767',
        flag: '🇩🇲',
    },
    {
        name: 'Dominican Republic',
        iso2Code: 'DO',
        phoneMask: '+.(...)...-....',
        phoneCode: '1',
        flag: '🇩🇴',
    },
    {
        name: 'Ecuador',
        iso2Code: 'EC',
        phoneMask: '+...-.-...-....',
        phoneCode: '593',
        flag: '🇪🇨',
    },
    {
        name: 'Egypt',
        iso2Code: 'EG',
        phoneMask: '+..(...)...-....',
        phoneCode: '20',
        flag: '🇪🇬',
    },
    {
        name: 'El Salvador',
        iso2Code: 'SV',
        phoneMask: '+... ....-....',
        phoneCode: '503',
        flag: '🇸🇻',
    },
    {
        name: 'Equatorial Guinea',
        iso2Code: 'GQ',
        phoneMask: '+...-..-...-....',
        phoneCode: '240',
        flag: '🇬🇶',
    },
    {
        name: 'Eritrea',
        iso2Code: 'ER',
        phoneMask: '+...-.-...-...',
        phoneCode: '291',
        flag: '🇪🇷',
    },
    {
        name: 'Estonia',
        iso2Code: 'EE',
        phoneMask: '+...-...-....',
        phoneCode: '372',
        flag: '🇪🇪',
    },
    {
        name: 'Eswatini',
        iso2Code: 'SZ',
        phoneMask: '+...-..-..-....',
        phoneCode: '268',
        flag: '🇸🇿',
    },
    {
        name: 'Ethiopia',
        iso2Code: 'ET',
        phoneMask: '+...-..-...-....',
        phoneCode: '251',
        flag: '🇪🇹',
    },
    {
        name: 'Falkland Islands (Malvinas)',
        iso2Code: 'FK',
        phoneMask: '+...-.....',
        phoneCode: '500',
        flag: '🇫🇰',
    },
    {
        name: 'Faroe Islands',
        iso2Code: 'FO',
        phoneMask: '+...-...-...',
        phoneCode: '298',
        flag: '🇫🇴',
    },
    {
        name: 'Fiji',
        iso2Code: 'FJ',
        phoneMask: '+...-..-.....',
        phoneCode: '679',
        flag: '🇫🇯',
    },
    {
        name: 'Finland',
        iso2Code: 'FI',
        phoneMask: '+... .. .... ....',
        phoneCode: '358',
        flag: '🇫🇮',
    },
    {
        name: 'France',
        iso2Code: 'FR',
        phoneMask: '+.. . .. .. .. ..',
        phoneCode: '33',
        flag: '🇫🇷',
    },
    {
        name: 'French Guiana',
        iso2Code: 'GF',
        phoneMask: '+...-.....-....',
        phoneCode: '594',
        flag: '🇬🇫',
    },
    {
        name: 'French Polynesia',
        iso2Code: 'PF',
        phoneMask: '+...-..-..-..',
        phoneCode: '689',
        flag: '🇵🇫',
    },
    {
        name: 'French Southern Territories',
        iso2Code: 'TF',
        phoneMask: '',
        phoneCode: '262',
        flag: '🇹🇫',
    },
    {
        name: 'Gabon',
        iso2Code: 'GA',
        phoneMask: '+...-.-..-..-..',
        phoneCode: '241',
        flag: '🇬🇦',
    },
    {
        name: 'Gambia, The',
        iso2Code: 'GM',
        phoneMask: '+...(...)..-..',
        phoneCode: '220',
        flag: '🇬🇲',
    },
    {
        name: 'Georgia',
        iso2Code: 'GE',
        phoneMask: '+...(...)...-...',
        phoneCode: '995',
        flag: '🇬🇪',
    },
    {
        name: 'Germany',
        iso2Code: 'DE',
        phoneMask: '+.. ... .......',
        phoneCode: '49',
        flag: '🇩🇪',
    },
    {
        name: 'Ghana',
        iso2Code: 'GH',
        phoneMask: '+...(...)...-...',
        phoneCode: '233',
        flag: '🇬🇭',
    },
    {
        name: 'Gibraltar',
        iso2Code: 'GI',
        phoneMask: '+...-...-.....',
        phoneCode: '350',
        flag: '🇬🇮',
    },
    {
        name: 'Greece',
        iso2Code: 'GR',
        phoneMask: '+..(...)...-....',
        phoneCode: '30',
        flag: '🇬🇷',
    },
    {
        name: 'Greenland',
        iso2Code: 'GL',
        phoneMask: '+...-..-..-..',
        phoneCode: '299',
        flag: '🇬🇱',
    },
    {
        name: 'Grenada',
        iso2Code: 'GD',
        phoneMask: '+.(...)...-....',
        phoneCode: '1473',
        flag: '🇬🇩',
    },
    {
        name: 'Guadeloupe',
        iso2Code: 'GP',
        phoneMask: '',
        phoneCode: '590',
        flag: '🇬🇵',
    },
    {
        name: 'Guam',
        iso2Code: 'GU',
        phoneMask: '+.(...)...-....',
        phoneCode: '1671',
        flag: '🇬🇺',
    },
    {
        name: 'Guatemala',
        iso2Code: 'GT',
        phoneMask: '+... ....-....',
        phoneCode: '502',
        flag: '🇬🇹',
    },
    {
        name: 'Guernsey',
        iso2Code: 'GG',
        phoneMask: '',
        phoneCode: '44',
        flag: '🇬🇬',
    },
    {
        name: 'Guinea',
        iso2Code: 'GN',
        phoneMask: '+...-..-...-...',
        phoneCode: '224',
        flag: '🇬🇳',
    },
    {
        name: 'Guinea-Bissau',
        iso2Code: 'GW',
        phoneMask: '+...-.-......',
        phoneCode: '245',
        flag: '🇬🇼',
    },
    {
        name: 'Guyana',
        iso2Code: 'GY',
        phoneMask: '+...-...-....',
        phoneCode: '592',
        flag: '🇬🇾',
    },
    {
        name: 'Haiti',
        iso2Code: 'HT',
        phoneMask: '+... ....-....',
        phoneCode: '509',
        flag: '🇭🇹',
    },
    {
        name: 'Heard Island and Mcdonald Islands',
        iso2Code: 'HM',
        phoneMask: '',
        phoneCode: '672',
        flag: '🇭🇲',
    },
    {
        name: 'Holy See (Vatican City State)',
        iso2Code: 'VA',
        phoneMask: '+.. .. .... ....',
        phoneCode: '39',
        flag: '🇻🇦',
    },
    {
        name: 'Honduras',
        iso2Code: 'HN',
        phoneMask: '+...-....-....',
        phoneCode: '504',
        flag: '🇭🇳',
    },
    {
        name: 'Hong Kong',
        iso2Code: 'HK',
        phoneMask: '+... .... ....',
        phoneCode: '852',
        flag: '🇭🇰',
    },
    {
        name: 'Hungary',
        iso2Code: 'HU',
        phoneMask: '+..(...)...-...',
        phoneCode: '36',
        flag: '🇭🇺',
    },
    {
        name: 'Iceland',
        iso2Code: 'IS',
        phoneMask: '+... ... ....',
        phoneCode: '354',
        flag: '🇮🇸',
    },
    {
        name: 'India',
        iso2Code: 'IN',
        phoneMask: '+.. .....-.....',
        phoneCode: '91',
        flag: '🇮🇳',
    },
    {
        name: 'Indonesia',
        iso2Code: 'ID',
        phoneMask: '+..-..-...-..',
        phoneCode: '62',
        flag: '🇮🇩',
    },
    {
        name: 'Iran, Islamic Republic Of',
        iso2Code: 'IR',
        phoneMask: '+..(...)...-....',
        phoneCode: '98',
        flag: '🇮🇷',
    },
    {
        name: 'Iraq',
        iso2Code: 'IQ',
        phoneMask: '+...(...)...-....',
        phoneCode: '964',
        flag: '🇮🇶',
    },
    {
        name: 'Ireland',
        iso2Code: 'IE',
        phoneMask: '+... .. .......',
        phoneCode: '353',
        flag: '🇮🇪',
    },
    {
        name: 'Isle of Man',
        iso2Code: 'IM',
        phoneMask: '',
        phoneCode: '44',
        flag: '🇮🇲',
    },
    {
        name: 'Israel',
        iso2Code: 'IL',
        phoneMask: '+...-.-...-....',
        phoneCode: '972',
        flag: '🇮🇱',
    },
    {
        name: 'Italy',
        iso2Code: 'IT',
        phoneMask: '+.. ... ......',
        phoneCode: '39',
        flag: '🇮🇹',
    },
    {
        name: 'Jamaica',
        iso2Code: 'JM',
        phoneMask: '+.(...)...-....',
        phoneCode: '1876',
        flag: '🇯🇲',
    },
    {
        name: 'Japan',
        iso2Code: 'JP',
        phoneMask: '+.. ... .. ....',
        phoneCode: '81',
        flag: '🇯🇵',
    },
    {
        name: 'Jersey',
        iso2Code: 'JE',
        phoneMask: '',
        phoneCode: '44',
        flag: '🇯🇪',
    },
    {
        name: 'Jordan',
        iso2Code: 'JO',
        phoneMask: '+...-.-....-....',
        phoneCode: '962',
        flag: '🇯🇴',
    },
    {
        name: 'Kazakhstan',
        iso2Code: 'KZ',
        phoneMask: '+. ... ...-..-..',
        phoneCode: '7',
        flag: '🇰🇿',
    },
    {
        name: 'Kenya',
        iso2Code: 'KE',
        phoneMask: '+...-...-......',
        phoneCode: '254',
        flag: '🇰🇪',
    },
    {
        name: 'Kiribati',
        iso2Code: 'KI',
        phoneMask: '+...-..-...',
        phoneCode: '686',
        flag: '🇰🇮',
    },
    {
        name: 'Korea, Democratic People"S Republic of',
        iso2Code: 'KP',
        phoneMask: '+...-...-...',
        phoneCode: '850',
        flag: '🇰🇵',
    },
    {
        name: 'Korea, Republic of',
        iso2Code: 'KR',
        phoneMask: '+..-..-...-....',
        phoneCode: '82',
        flag: '🇰🇷',
    },
    {
        name: 'Kuwait',
        iso2Code: 'KW',
        phoneMask: '+...-....-....',
        phoneCode: '965',
        flag: '🇰🇼',
    },
    {
        name: 'Kyrgyzstan',
        iso2Code: 'KG',
        phoneMask: '+...(...)...-...',
        phoneCode: '996',
        flag: '🇰🇬',
    },
    {
        name: 'Lao People"S Democratic Republic',
        iso2Code: 'LA',
        phoneMask: '+...-..-...-...',
        phoneCode: '856',
        flag: '🇱🇦',
    },
    {
        name: 'Latvia',
        iso2Code: 'LV',
        phoneMask: '+...-..-...-...',
        phoneCode: '371',
        flag: '🇱🇻',
    },
    {
        name: 'Lebanon',
        iso2Code: 'LB',
        phoneMask: '+...-.-...-...',
        phoneCode: '961',
        flag: '🇱🇧',
    },
    {
        name: 'Lesotho',
        iso2Code: 'LS',
        phoneMask: '+...-.-...-....',
        phoneCode: '266',
        flag: '🇱🇸',
    },
    {
        name: 'Liberia',
        iso2Code: 'LR',
        phoneMask: '+...-..-...-...',
        phoneCode: '231',
        flag: '🇱🇷',
    },
    {
        name: 'Libyan Arab Jamahiriya',
        iso2Code: 'LY',
        phoneMask: '+...-..-...-...',
        phoneCode: '218',
        flag: '🇱🇾',
    },
    {
        name: 'Liechtenstein',
        iso2Code: 'LI',
        phoneMask: '+...(...)...-....',
        phoneCode: '423',
        flag: '🇱🇮',
    },
    {
        name: 'Lithuania',
        iso2Code: 'LT',
        phoneMask: '+...(...)..-...',
        phoneCode: '370',
        flag: '🇱🇹',
    },
    {
        name: 'Luxembourg',
        iso2Code: 'LU',
        phoneMask: '+...(...)...-...',
        phoneCode: '352',
        flag: '🇱🇺',
    },
    {
        name: 'Macao',
        iso2Code: 'MO',
        phoneMask: '+...-....-....',
        phoneCode: '853',
        flag: '🇲🇴',
    },
    {
        name: 'Republic of North Macedonia',
        iso2Code: 'MK',
        phoneMask: '+...-..-...-...',
        phoneCode: '389',
        flag: '🇲🇰',
    },
    {
        name: 'Madagascar',
        iso2Code: 'MG',
        phoneMask: '+...-..-..-.....',
        phoneCode: '261',
        flag: '🇲🇬',
    },
    {
        name: 'Malawi',
        iso2Code: 'MW',
        phoneMask: '+...-.-....-....',
        phoneCode: '265',
        flag: '🇲🇼',
    },
    {
        name: 'Malaysia',
        iso2Code: 'MY',
        phoneMask: '+.. ..-....-....',
        phoneCode: '60',
        flag: '🇲🇾',
    },
    {
        name: 'Maldives',
        iso2Code: 'MV',
        phoneMask: '+...-...-....',
        phoneCode: '960',
        flag: '🇲🇻',
    },
    {
        name: 'Mali',
        iso2Code: 'ML',
        phoneMask: '+...-..-..-....',
        phoneCode: '223',
        flag: '🇲🇱',
    },
    {
        name: 'Malta',
        iso2Code: 'MT',
        phoneMask: '+...-....-....',
        phoneCode: '356',
        flag: '🇲🇹',
    },
    {
        name: 'Marshall Islands',
        iso2Code: 'MH',
        phoneMask: '+...-...-....',
        phoneCode: '692',
        flag: '🇲🇭',
    },
    {
        name: 'Martinique',
        iso2Code: 'MQ',
        phoneMask: '+...(...)..-..-..',
        phoneCode: '596',
        flag: '🇲🇶',
    },
    {
        name: 'Mauritania',
        iso2Code: 'MR',
        phoneMask: '+...-..-..-....',
        phoneCode: '222',
        flag: '🇲🇷',
    },
    {
        name: 'Mauritius',
        iso2Code: 'MU',
        phoneMask: '+...-...-....',
        phoneCode: '230',
        flag: '🇲🇺',
    },
    {
        name: 'Mayotte',
        iso2Code: 'YT',
        phoneMask: '',
        phoneCode: '262',
        flag: '🇾🇹',
    },
    {
        name: 'Mexico',
        iso2Code: 'MX',
        phoneMask: '+..-..-..-....',
        phoneCode: '52',
        flag: '🇲🇽',
    },
    {
        name: 'Micronesia, Federated States of',
        iso2Code: 'FM',
        phoneMask: '+...-...-....',
        phoneCode: '691',
        flag: '🇫🇲',
    },
    {
        name: 'Moldova, Republic of',
        iso2Code: 'MD',
        phoneMask: '+...-....-....',
        phoneCode: '373',
        flag: '🇲🇩',
    },
    {
        name: 'Monaco',
        iso2Code: 'MC',
        phoneMask: '+...-..-...-...',
        phoneCode: '377',
        flag: '🇲🇨',
    },
    {
        name: 'Mongolia',
        iso2Code: 'MN',
        phoneMask: '+...-..-..-....',
        phoneCode: '976',
        flag: '🇲🇳',
    },
    {
        name: 'Montserrat',
        iso2Code: 'MS',
        phoneMask: '+.(...)...-....',
        phoneCode: '1664',
        flag: '🇲🇸',
    },
    {
        name: 'Morocco',
        iso2Code: 'MA',
        phoneMask: '+...-..-....-...',
        phoneCode: '212',
        flag: '🇲🇦',
    },
    {
        name: 'Mozambique',
        iso2Code: 'MZ',
        phoneMask: '+...-..-...-...',
        phoneCode: '258',
        flag: '🇲🇿',
    },
    {
        name: 'Myanmar',
        iso2Code: 'MM',
        phoneMask: '+..-...-...',
        phoneCode: '95',
        flag: '🇲🇲',
    },
    {
        name: 'Namibia',
        iso2Code: 'NA',
        phoneMask: '+...-..-...-....',
        phoneCode: '264',
        flag: '🇳🇦',
    },
    {
        name: 'Nauru',
        iso2Code: 'NR',
        phoneMask: '+...-...-....',
        phoneCode: '674',
        flag: '🇳🇷',
    },
    {
        name: 'Nepal',
        iso2Code: 'NP',
        phoneMask: '+...-..-...-...',
        phoneCode: '977',
        flag: '🇳🇵',
    },
    {
        name: 'Netherlands',
        iso2Code: 'NL',
        phoneMask: '+.. .. ........',
        phoneCode: '31',
        flag: '🇳🇱',
    },
    {
        name: 'Netherlands Antilles',
        iso2Code: 'AN',
        phoneMask: '',
        phoneCode: '599',
        flag: '',
    },
    {
        name: 'New Caledonia',
        iso2Code: 'NC',
        phoneMask: '+...-..-....',
        phoneCode: '687',
        flag: '🇳🇨',
    },
    {
        name: 'New Zealand',
        iso2Code: 'NZ',
        phoneMask: '+.. ...-...-....',
        phoneCode: '64',
        flag: '🇳🇿',
    },
    {
        name: 'Nicaragua',
        iso2Code: 'NI',
        phoneMask: '+...-....-....',
        phoneCode: '505',
        flag: '🇳🇮',
    },
    {
        name: 'Niger',
        iso2Code: 'NE',
        phoneMask: '+...-..-..-....',
        phoneCode: '227',
        flag: '🇳🇪',
    },
    {
        name: 'Nigeria',
        iso2Code: 'NG',
        phoneMask: '+...-..-...-..',
        phoneCode: '234',
        flag: '🇳🇬',
    },
    {
        name: 'Niue',
        iso2Code: 'NU',
        phoneMask: '+...-....',
        phoneCode: '683',
        flag: '🇳🇺',
    },
    {
        name: 'Norfolk Island',
        iso2Code: 'NF',
        phoneMask: '+...-...-...',
        phoneCode: '672',
        flag: '🇳🇫',
    },
    {
        name: 'Northern Mariana Islands',
        iso2Code: 'MP',
        phoneMask: '+.(...)...-....',
        phoneCode: '1670',
        flag: '🇲🇵',
    },
    {
        name: 'Norway',
        iso2Code: 'NO',
        phoneMask: '+.. ... .. ...',
        phoneCode: '47',
        flag: '🇳🇴',
    },
    {
        name: 'Oman',
        iso2Code: 'OM',
        phoneMask: '+...-..-...-...',
        phoneCode: '968',
        flag: '🇴🇲',
    },
    {
        name: 'Pakistan',
        iso2Code: 'PK',
        phoneMask: '+.. ...-.......',
        phoneCode: '92',
        flag: '🇵🇰',
    },
    {
        name: 'Palau',
        iso2Code: 'PW',
        phoneMask: '+...-...-....',
        phoneCode: '680',
        flag: '🇵🇼',
    },
    {
        name: 'Palestinian Territory, Occupied',
        iso2Code: 'PS',
        phoneMask: '+...-..-...-....',
        phoneCode: '970',
        flag: '🇵🇸',
    },
    {
        name: 'Panama',
        iso2Code: 'PA',
        phoneMask: '+...-...-....',
        phoneCode: '507',
        flag: '🇵🇦',
    },
    {
        name: 'Papua New Guinea',
        iso2Code: 'PG',
        phoneMask: '+...(...)..-...',
        phoneCode: '675',
        flag: '🇵🇬',
    },
    {
        name: 'Paraguay',
        iso2Code: 'PY',
        phoneMask: '+...(...)...-...',
        phoneCode: '595',
        flag: '🇵🇾',
    },
    {
        name: 'Peru',
        iso2Code: 'PE',
        phoneMask: '+..(...)...-...',
        phoneCode: '51',
        flag: '🇵🇪',
    },
    {
        name: 'Philippines',
        iso2Code: 'PH',
        phoneMask: '+.. ... ....',
        phoneCode: '63',
        flag: '🇵🇭',
    },
    {
        name: 'Pitcairn',
        iso2Code: 'PN',
        phoneMask: '',
        phoneCode: '64',
        flag: '🇵🇳',
    },
    {
        name: 'Poland',
        iso2Code: 'PL',
        phoneMask: '+.. ...-...-...',
        phoneCode: '48',
        flag: '🇵🇱',
    },
    {
        name: 'Portugal',
        iso2Code: 'PT',
        phoneMask: '+...-..-...-....',
        phoneCode: '351',
        flag: '🇵🇹',
    },
    {
        name: 'Puerto Rico',
        iso2Code: 'PR',
        phoneMask: '+. (...) ...-....',
        phoneCode: '1',
        flag: '🇵🇷',
    },
    {
        name: 'Qatar',
        iso2Code: 'QA',
        phoneMask: '+...-....-....',
        phoneCode: '974',
        flag: '🇶🇦',
    },
    {
        name: 'Reunion',
        iso2Code: 'RE',
        phoneMask: '+...-.....-....',
        phoneCode: '262',
        flag: '🇷🇪',
    },
    {
        name: 'Romania',
        iso2Code: 'RO',
        phoneMask: '+..-..-...-....',
        phoneCode: '40',
        flag: '🇷🇴',
    },
    {
        name: 'Russian Federation',
        iso2Code: 'RU',
        phoneMask: '+. ... ...-..-..',
        phoneCode: '7',
        flag: '🇷🇺',
    },
    {
        name: 'Rwanda',
        iso2Code: 'RW',
        phoneMask: '+...(...)...-...',
        phoneCode: '250',
        flag: '🇷🇼',
    },
    {
        name: 'Saint Barthelemy',
        iso2Code: 'BL',
        phoneMask: '',
        phoneCode: '590',
        flag: '🇧🇱',
    },
    {
        name: 'Saint Helena',
        iso2Code: 'SH',
        phoneMask: '',
        phoneCode: '290',
        flag: '🇸🇭',
    },
    {
        name: 'Saint Kitts and Nevis',
        iso2Code: 'KN',
        phoneMask: '+.(...)...-....',
        phoneCode: '1869',
        flag: '🇰🇳',
    },
    {
        name: 'Saint Lucia',
        iso2Code: 'LC',
        phoneMask: '+.(...)...-....',
        phoneCode: '1758',
        flag: '🇱🇨',
    },
    {
        name: 'Saint Martin',
        iso2Code: 'MF',
        phoneMask: '',
        phoneCode: '590',
        flag: '🇲🇫',
    },
    {
        name: 'Saint Pierre and Miquelon',
        iso2Code: 'PM',
        phoneMask: '',
        phoneCode: '508',
        flag: '🇵🇲',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        iso2Code: 'VC',
        phoneMask: '+.(...)...-....',
        phoneCode: '1784',
        flag: '🇻🇨',
    },
    {
        name: 'Samoa',
        iso2Code: 'WS',
        phoneMask: '+...-..-....',
        phoneCode: '685',
        flag: '🇼🇸',
    },
    {
        name: 'San Marino',
        iso2Code: 'SM',
        phoneMask: '+...-....-......',
        phoneCode: '378',
        flag: '🇸🇲',
    },
    {
        name: 'Sao Tome and Principe',
        iso2Code: 'ST',
        phoneMask: '+...-..-.....',
        phoneCode: '239',
        flag: '🇸🇹',
    },
    {
        name: 'Saudi Arabia',
        iso2Code: 'SA',
        phoneMask: '+...-..-...-....',
        phoneCode: '966',
        flag: '🇸🇦',
    },
    {
        name: 'Senegal',
        iso2Code: 'SN',
        phoneMask: '+...-..-...-....',
        phoneCode: '221',
        flag: '🇸🇳',
    },
    {
        name: 'Montenegro',
        iso2Code: 'ME',
        phoneMask: '+...-..-...-...',
        phoneCode: '382',
        flag: '🇲🇪',
    },
    {
        name: 'Serbia',
        iso2Code: 'RS',
        phoneMask: '+...-..-...-....',
        phoneCode: '381',
        flag: '🇷🇸',
    },
    {
        name: 'Seychelles',
        iso2Code: 'SC',
        phoneMask: '+...-.-...-...',
        phoneCode: '248',
        flag: '🇸🇨',
    },
    {
        name: 'Sierra Leone',
        iso2Code: 'SL',
        phoneMask: '+...-..-......',
        phoneCode: '232',
        flag: '🇸🇱',
    },
    {
        name: 'Singapore',
        iso2Code: 'SG',
        phoneMask: '+.. ....-....',
        phoneCode: '65',
        flag: '🇸🇬',
    },
    {
        name: 'Sint Maarten',
        iso2Code: 'SX',
        phoneMask: '+.(...)...-....',
        phoneCode: '1721',
        flag: '',
    },
    {
        name: 'Slovakia',
        iso2Code: 'SK',
        phoneMask: '+...(...)...-...',
        phoneCode: '421',
        flag: '🇸🇰',
    },
    {
        name: 'Slovenia',
        iso2Code: 'SI',
        phoneMask: '+...-..-...-...',
        phoneCode: '386',
        flag: '🇸🇮',
    },
    {
        name: 'Solomon Islands',
        iso2Code: 'SB',
        phoneMask: '+...-.....',
        phoneCode: '677',
        flag: '🇸🇧',
    },
    {
        name: 'Somalia',
        iso2Code: 'SO',
        phoneMask: '+...-.-...-...',
        phoneCode: '252',
        flag: '🇸🇴',
    },
    {
        name: 'South Africa',
        iso2Code: 'ZA',
        phoneMask: '+..-..-...-....',
        phoneCode: '27',
        flag: '🇿🇦',
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        iso2Code: 'GS',
        phoneMask: '',
        phoneCode: '500',
        flag: '🇬🇸',
    },
    {
        name: 'South Sudan',
        iso2Code: 'SS',
        phoneMask: '+...-..-...-....',
        phoneCode: '211',
        flag: '🇸🇸',
    },
    {
        name: 'Spain',
        iso2Code: 'ES',
        phoneMask: '+.. ... ... ...',
        phoneCode: '34',
        flag: '🇪🇸',
    },
    {
        name: 'Sri Lanka',
        iso2Code: 'LK',
        phoneMask: '+..-..-...-....',
        phoneCode: '94',
        flag: '🇱🇰',
    },
    {
        name: 'Sudan',
        iso2Code: 'SD',
        phoneMask: '+...-..-...-....',
        phoneCode: '249',
        flag: '🇸🇩',
    },
    {
        name: 'Suriname',
        iso2Code: 'SR',
        phoneMask: '+...-...-...',
        phoneCode: '597',
        flag: '🇸🇷',
    },
    {
        name: 'Svalbard and Jan Mayen',
        iso2Code: 'SJ',
        phoneMask: '',
        phoneCode: '47',
        flag: '🇸🇯',
    },
    {
        name: 'Sweden',
        iso2Code: 'SE',
        phoneMask: '+.. .. ... .. ..',
        phoneCode: '46',
        flag: '🇸🇪',
    },
    {
        name: 'Switzerland',
        iso2Code: 'CH',
        phoneMask: '+.. .. ... .. ..',
        phoneCode: '41',
        flag: '🇨🇭',
    },
    {
        name: 'Syrian Arab Republic',
        iso2Code: 'SY',
        phoneMask: '+...-..-....-...',
        phoneCode: '963',
        flag: '🇸🇾',
    },
    {
        name: 'Taiwan',
        iso2Code: 'TW',
        phoneMask: '+...-....-....',
        phoneCode: '886',
        flag: '🇹🇼',
    },
    {
        name: 'Tajikistan',
        iso2Code: 'TJ',
        phoneMask: '+...-..-...-....',
        phoneCode: '992',
        flag: '🇹🇯',
    },
    {
        name: 'Tanzania, United Republic of',
        iso2Code: 'TZ',
        phoneMask: '+...-..-...-....',
        phoneCode: '255',
        flag: '🇹🇿',
    },
    {
        name: 'Thailand',
        iso2Code: 'TH',
        phoneMask: '+..-..-...-...',
        phoneCode: '66',
        flag: '🇹🇭',
    },
    {
        name: 'Timor-Leste',
        iso2Code: 'TL',
        phoneMask: '+...-...-....',
        phoneCode: '670',
        flag: '🇹🇱',
    },
    {
        name: 'Togo',
        iso2Code: 'TG',
        phoneMask: '+...-..-...-...',
        phoneCode: '228',
        flag: '🇹🇬',
    },
    {
        name: 'Tokelau',
        iso2Code: 'TK',
        phoneMask: '+...-....',
        phoneCode: '690',
        flag: '🇹🇰',
    },
    {
        name: 'Tonga',
        iso2Code: 'TO',
        phoneMask: '+...-.....',
        phoneCode: '676',
        flag: '🇹🇴',
    },
    {
        name: 'Trinidad and Tobago',
        iso2Code: 'TT',
        phoneMask: '+.(...)...-....',
        phoneCode: '1868',
        flag: '🇹🇹',
    },
    {
        name: 'Tunisia',
        iso2Code: 'TN',
        phoneMask: '+...-..-...-...',
        phoneCode: '216',
        flag: '🇹🇳',
    },
    {
        name: 'Turkey',
        iso2Code: 'TR',
        phoneMask: '+.. ... ... .. ..',
        phoneCode: '90',
        flag: '🇹🇷',
    },
    {
        name: 'Turkmenistan',
        iso2Code: 'TM',
        phoneMask: '+...-.-...-....',
        phoneCode: '993',
        flag: '🇹🇲',
    },
    {
        name: 'Turks and Caicos Islands',
        iso2Code: 'TC',
        phoneMask: '+.(...)...-....',
        phoneCode: '1649',
        flag: '🇹🇨',
    },
    {
        name: 'Tuvalu',
        iso2Code: 'TV',
        phoneMask: '+...-.....',
        phoneCode: '688',
        flag: '🇹🇻',
    },
    {
        name: 'Uganda',
        iso2Code: 'UG',
        phoneMask: '+...(...)...-...',
        phoneCode: '256',
        flag: '🇺🇬',
    },
    {
        name: 'Ukraine',
        iso2Code: 'UA',
        phoneMask: '+...(..)...-..-..',
        phoneCode: '380',
        flag: '🇺🇦',
    },
    {
        name: 'United Arab Emirates',
        iso2Code: 'AE',
        phoneMask: '+...-.-...-....',
        phoneCode: '971',
        flag: '🇦🇪',
    },
    {
        name: 'United States Minor Outlying Islands',
        iso2Code: 'UM',
        phoneMask: '',
        phoneCode: '1',
        flag: '🇺🇸',
    },
    {
        name: 'Uruguay',
        iso2Code: 'UY',
        phoneMask: '+...-.-...-..-..',
        phoneCode: '598',
        flag: '🇺🇾',
    },
    {
        name: 'Uzbekistan',
        iso2Code: 'UZ',
        phoneMask: '+...-..-...-....',
        phoneCode: '998',
        flag: '🇺🇿',
    },
    {
        name: 'Vanuatu',
        iso2Code: 'VU',
        phoneMask: '+...-.....',
        phoneCode: '678',
        flag: '🇻🇺',
    },
    {
        name: 'Venezuela',
        iso2Code: 'VE',
        phoneMask: '+..(...)...-....',
        phoneCode: '58',
        flag: '🇻🇪',
    },
    {
        name: 'Vietnam',
        iso2Code: 'VN',
        phoneMask: '+..-..-....-...',
        phoneCode: '84',
        flag: '🇻🇳',
    },
    {
        name: 'Virgin Islands, British',
        iso2Code: 'VG',
        phoneMask: '+.(...)...-....',
        phoneCode: '1284',
        flag: '🇻🇬',
    },
    {
        name: 'Virgin Islands, U.S.',
        iso2Code: 'VI',
        phoneMask: '+.(...)...-....',
        phoneCode: '1340',
        flag: '🇻🇮',
    },
    {
        name: 'Wallis and Futuna',
        iso2Code: 'WF',
        phoneMask: '+...-..-....',
        phoneCode: '681',
        flag: '🇼🇫',
    },
    {
        name: 'Western Sahara',
        iso2Code: 'EH',
        phoneMask: '+...-..-....',
        phoneCode: '212',
        flag: '🇪🇭',
    },
    {
        name: 'Yemen',
        iso2Code: 'YE',
        phoneMask: '+...-.-...-...',
        phoneCode: '967',
        flag: '🇾🇪',
    },
    {
        name: 'Zambia',
        iso2Code: 'ZM',
        phoneMask: '+...-..-...-....',
        phoneCode: '260',
        flag: '🇿🇲',
    },
    {
        name: 'Zimbabwe',
        iso2Code: 'ZW',
        phoneMask: '+...-.-......',
        phoneCode: '263',
        flag: '🇿🇼',
    },
]
