import React from 'react'
import { Tooltip as ReactTooltip, PlacesType } from 'react-tooltip'
import classnames from 'classnames'
import styles from './Tooltip.module.scss'

type TooltipProps = React.HTMLAttributes<HTMLSpanElement> &
    React.HTMLAttributes<HTMLParagraphElement> & {
        /**ID for the tooltip */
        id: string
        /** optional: class-names */
        className?: string
        /** children always of type ReactNode */
        children: React.ReactNode
        /** content to show in tooltip */
        content: string
        /** tooltip position */
        position?: PlacesType
        /** indicate if it hides on hovering tooltip */
        clickable?: boolean
    }

/** Tooltip: renders a Tooltip tag of a type based on the tagName prop */
const Tooltip: React.FC<TooltipProps> = ({
    id,
    className,
    children,
    content,
    position = 'top',
    clickable = true,
    ...rest
}: TooltipProps) => {
    const tooltipClassNames = classnames(styles['custom-tooltip'], className)
    const targetClassNames = classnames(styles['custom-tooltip-target'])
    return (
        <span {...rest}>
            <ReactTooltip
                id={id}
                className={tooltipClassNames}
                clickable={clickable}
                variant='light'
            />
            <span
                id={`target-${id}`}
                data-tooltip-id={id}
                data-tooltip-content={content}
                data-tooltip-place={position}
                className={targetClassNames}
            >
                {children}
            </span>
        </span>
    )
}

export default Tooltip
