import React from 'react'
import classnames from 'classnames'

import imageStyles from './Image.module.css'

type ImageProps = React.AllHTMLAttributes<HTMLImageElement>

const Image: React.FC<ImageProps> = ({ alt, src, className, ...rest }: ImageProps) => {
    const classNames = classnames(imageStyles.image, className)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <img alt={alt} src={src} className={classNames} {...rest} />
}

export default Image
