import React from 'react'
import classnames from 'classnames'
import iconStyles from './Icon.module.css'
import * as Icons from './svgs/index'

export type IconColours =
    | 'gray'
    | 'dark-gray'
    | 'tertiary-blue'
    | 'tertiary-blue-25'
    | 'green'
    | 'white'
    | 'red'
    | 'pink'
    | 'orange'
    | 'primary-midnight'
    | 'primary-midnight-40'
export type IconSizes = 'XS' | 'S' | 'M' | 'L' | 'XL'
export type IconNames =
    | 'ArrowsRotate'
    | 'Basket'
    | 'Calendar'
    | 'CalendarDays'
    | 'Check'
    | 'ChevronDown'
    | 'ChevronLeft'
    | 'CircleChevronLeft'
    | 'ChevronRight'
    | 'CircleChevronRight'
    | 'ChevronUp'
    | 'CircleMinus'
    | 'CirclePlus'
    | 'CircleExclamationSolid'
    | 'CircleCheckSolid'
    | 'Clock'
    | 'Copy'
    | 'Desktop'
    | 'Cross'
    | 'Error'
    | 'Eye'
    | 'EyeSlash'
    | 'Info'
    | 'Key'
    | 'Location'
    | 'Moon'
    | 'Pencil'
    | 'Plane'
    | 'Plus'
    | 'Search'
    | 'Ship'
    | 'TriangleExclamationSolid'
    | 'Ttek'
    | 'User'
    | 'UserCircle'
    | 'Wrench'
    | ''

export type IconProps = React.AllHTMLAttributes<HTMLSpanElement> & {
    /** string name given for exported SVGs from /svg/index.ts - update index.ts and IconNames type when adding new */
    iconName: IconNames
    iconSize?: IconSizes
    iconColor?: IconColours
}

/** Icon: renders a span with a class-name that applies a specific icon from the fa stylesheet */
const Icon: React.FC<IconProps> = ({
    iconName,
    iconSize = 'S',
    iconColor = 'dark-gray',
    className,
}: IconProps) => {
    const sizeClass = iconStyles[`icon-size-${iconSize}`]
    const colorClass = iconStyles[`icon-color-${iconColor}`]
    const iconClassNames = classnames(sizeClass, colorClass)

    const containerClassNames = classnames(iconStyles.container, className)
    // Need a type declaration for /svg/index.ts/* Icons that matches the type for iconNames
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const IconSVG = Icons[iconName] as keyof JSX.IntrinsicElements
    return (
        <span aria-hidden='true' className={containerClassNames}>
            <IconSVG className={iconClassNames} />
        </span>
    )
}

export default Icon
