import React, { useEffect, useMemo, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'

import Heading from '../../basics/Heading/Heading'
import Credentials from '../../blocks/Credentials/Credentials'
import { CredentialItem } from '../../blocks/Credentials/CredentialsItem/CredentialsItem'
import InlineSpinner from '../../basics/Spinners/InlineSpinner'
import FieldError from '../../basics/FieldError/FieldError'
import { SUPPLIER_CREDENTIALS } from '../../../graphql-queries/queries'
import { getDataFromLocalStorage } from '../../../utils/use-local-storage'
import { LOCAL_STORAGE_USER_DATA_KEY } from '../../../utils/constants'
import allContent from '../../../content/content'
import styles from './SupplierConfiguration.module.css'
import { getTenantId } from '../../../utils/cognito-helpers/get-tenant-id'

const content = allContent.supplierConfigurationPage

type SupplierConfigurationProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
}

const SupplierConfiguration: React.FC<SupplierConfigurationProps> = ({ apiClient }) => {
    const [supplierCredentials, setSupplierCredentials] = useState<CredentialItem[] | []>([])
    const [fetchingSupplierCredentials, setFetchingSupplierCredentials] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const userData = useMemo(
        () => getDataFromLocalStorage({ key: LOCAL_STORAGE_USER_DATA_KEY }) ?? {},
        []
    )

    useEffect((): void => {
        const tenantId = getTenantId(userData)
        if (tenantId && !supplierCredentials?.length) {
            apiClient
                // TODO: add salesChannelId variable as it's mandatory now
                .query({ query: SUPPLIER_CREDENTIALS })
                .then((response) => {
                    setFetchingSupplierCredentials(true)
                    if (response?.data?.supplierCredentials) {
                        setSupplierCredentials(response?.data?.supplierCredentials)
                    }
                })
                .catch((error) => {
                    setFetchingSupplierCredentials(true)
                    console.log(error) // eslint-disable-line no-console
                    datadogLogs.logger.error(`Query SUPPLIER_CREDENTIALS - error: ${error.message}`)
                    setErrorMessage(content.errors.loadingSupplierList)
                })
            setFetchingSupplierCredentials(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    // empty dependency because we only want this fetched once, and we use the data structure it fetches to determine if its been fetched... which if we didn't check would mean we could have a filled dependency array that would only fetch once?

    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.title}</Heading>
            {!fetchingSupplierCredentials && <InlineSpinner text={content.loading} />}
            {fetchingSupplierCredentials && !!supplierCredentials?.length && (
                <Credentials credentialItems={supplierCredentials} apiClient={apiClient} />
            )}
            <FieldError
                inputId='getSupplierList'
                showError={errorMessage !== null}
                errorMessage={errorMessage ?? ''}
            />
        </div>
    )
}

export default SupplierConfiguration
