import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import SalesChannelManagement from 'components/sections/SalesChannelManagement/SalesChannelManagement'
import Navigation from '../../sections/NavigationPannel/NavigationPanel'

import { ProductTiersType } from '../../../App'
import styles from './SalesChannelManagementLayout.module.css'

type SalesChannelManagementLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersType
}

const SalesChannelManagementLayout: React.FC<SalesChannelManagementLayoutProps> = ({
    apiClient,
    productTiers,
}) => {
    return (
        <div className={styles.container}>
            <Navigation productTiers={productTiers} />
            <SalesChannelManagement apiClient={apiClient} productTiers={productTiers} />
        </div>
    )
}

export default SalesChannelManagementLayout
