import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'

import Button from '../../basics/Button/Button'
import Text from '../../basics/Text/Text'

import styles from './ErrorBoundary.module.css'
import allContent from '../../../content/content'
import { ROUTES } from '../../../App'
const content = allContent.components.errorBoundary

interface ErrorBoundaryProps {
    children: React.ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
}
const handleClickBackToIndex = (): void => {
    Navigate({ to: ROUTES.LOGIN })
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(): any {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: Record<any, any>): void {
        datadogLogs.logger.error(`Error Boundary - ${JSON.stringify({ error, errorInfo })}`)
    }

    render(): any {
        if (this.state.hasError) {
            return (
                <div className={styles.container}>
                    <Text size='XL'>{content.heading}</Text>
                    <Text size='M'>{content.message}</Text>
                    <Button
                        type='button'
                        onClick={handleClickBackToIndex}
                        text={content.homeButton}
                    />
                </div>
            )
        }
        return this.props.children
    }
}
export default ErrorBoundary
