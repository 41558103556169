import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import Navigation from '../../sections/NavigationPannel/NavigationPanel'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import { ProductTiersType, ROUTES } from '../../../App'

import styles from './EditSalesChannelLayout.module.css'
import EditSalesChannel from 'components/sections/EditSalesChannel/EditSalesChannel'

type EditSalesChannelLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersType
}

const EditSalesChannelLayout = ({
    apiClient,
    productTiers,
}: EditSalesChannelLayoutProps): JSX.Element => {
    const navigate = useNavigate()
    const { salesChannelId } = useParams()

    const salesChannelManagementURL = ROUTES.SALES_CHANNEL_MANAGEMENT

    return (
        <div className={styles.container}>
            <Navigation productTiers={productTiers} />
            <div>
                {!salesChannelId && (
                    <InfoBanner
                        id='SalesChannelInfo'
                        bannerType='error'
                        message='Sales channel not found!'
                        onButtonClick={(): void => navigate(salesChannelManagementURL)}
                        buttonText='Return to Sales Channel Management'
                        isCloseable={false}
                    />
                )}
            </div>
            {salesChannelId && (
                <EditSalesChannel apiClient={apiClient} salesChannelId={salesChannelId} />
            )}
        </div>
    )
}

export default EditSalesChannelLayout
