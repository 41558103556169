import React, { useEffect, useState, ReactNode } from 'react'
import classNames from 'classnames'

import Button from '../../basics/Button/Button'
import Close from '../../basics/Close/Close'
import Icon, { IconColours, IconNames } from '../../basics/Icon/Icon'
import Text from '../../basics/Text/Text'
import { handleFocusOnId } from '../../../utils/handle-focus-on-id'

import styles from './InfoBanner.module.scss'
import allContent from '../../../content/content'

const content = allContent.components.infoBanner

type BannerTypes = 'error' | 'warning' | 'success' | 'info'

type InfoBannerProps = {
    /** depending on the banner type, the user will see the color (red, yellow, green, blue, respectively) and the appropriate icon on the banner */
    bannerType: BannerTypes
    /** the informative message string (or component) the banner conveys to the user **/
    message: string | ReactNode
    /** call back function to be called when button is clicked, also drives the displaying of the button **/
    onButtonClick?: () => void
    /** when there is an action button, this prop gives the button the text that explains what the action is **/
    buttonText?: string
    /** can be set to false if we want the user to be unable to close the banner **/
    isCloseable?: boolean
    /** can be set to true if we want the page to scroll to where the banner is rendered **/
    isFocusable?: boolean
    id: string
}

const InfoBanner: React.FC<InfoBannerProps> = ({
    message,
    onButtonClick,
    bannerType,
    buttonText,
    isCloseable = true,
    isFocusable = false,
    id,
}: InfoBannerProps) => {
    const [isClosed, setIsClosed] = useState(false)

    const infoBannerClassNames = classNames(styles['info-banner'], [
        styles[`info-banner__${bannerType}`],
    ])

    let iconName: IconNames = 'Info'
    let iconColor: IconColours = 'white'
    if (bannerType === 'error') {
        iconColor = 'red'
        iconName = 'CircleExclamationSolid'
    }
    if (bannerType === 'warning') {
        iconColor = 'orange'
        iconName = 'TriangleExclamationSolid'
    }
    if (bannerType === 'success') {
        iconColor = 'green'
        iconName = 'CircleCheckSolid'
    }
    if (bannerType === 'info') {
        iconColor = 'tertiary-blue'
        iconName = 'Info'
    }
    useEffect(() => {
        if (isFocusable) handleFocusOnId(id)
    }, [id, isFocusable])

    if (isClosed) return null
    return (
        <div id={id} className={infoBannerClassNames}>
            <div className={styles['text']}>
                <Icon iconColor={iconColor} iconName={iconName} />
                {typeof message === 'string' ? <Text>{message}</Text> : message}
            </div>
            <div className={styles['buttons']}>
                {buttonText && onButtonClick && (
                    <Button
                        type='button'
                        flavour='tertiary'
                        text={buttonText}
                        onClick={onButtonClick}
                    />
                )}
                {isCloseable && (
                    <Close text={content.closeButton} onClick={(): void => setIsClosed(true)} />
                )}
            </div>
        </div>
    )
}
export default InfoBanner
