import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Auth } from '@aws-amplify/auth'
import { datadogLogs } from '@datadog/browser-logs'

import Button from '../../basics/Button/Button'
import FieldError from '../../basics/FieldError/FieldError'
import Heading from '../../basics/Heading/Heading'
import InfoBanner from '../../blocks/InfoBanner/InfoBanner'
import LabelledInput from '../../blocks/LabelledInput/LabelledInput'
import Spacing from '../../basics/Spacing/Spacing'
import TextInput from '../../basics/INPUTS/TextInput/TextInput'

import styles from './ForgotPasswordForm.module.css'
import allContent from '../../../content/content'
import { ROUTES } from '../../../App'
import { REGEX } from '../../../utils/constants'
import { getCognitoErrorMessage } from '../../../utils/get-cognito-error-message'

const content = allContent.forgotPasswordPage.form

type ForgotPasswordDataType = {
    email: string
}

const ForgotPasswordForm: React.FC = () => {
    const [successful, setSuccessful] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [forgotPasswordApiError, setForgotPasswordApiError] = useState<string>('')
    const [formData, setFormData] = useState<ForgotPasswordDataType>()
    const navigate = useNavigate()
    useEffect(() => {
        if (successful) {
            navigate(ROUTES.RESET_PASSWORD, { state: formData }) // pass the email to reset password form, set it as default and disable input
        }
    }, [successful, formData, navigate])
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ForgotPasswordDataType>({
        mode: 'onBlur',
        reValidateMode: 'onChange', // only comes into effect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            email: '',
        },
    })

    const forgotPasswordForm = (
        <form
            className={styles.form}
            onSubmit={handleSubmit(async (formFields: ForgotPasswordDataType) => {
                setFormData(formFields)
                setForgotPasswordApiError('')
                setSubmitting(true)
                await Auth.forgotPassword(formFields.email)
                    .then((response) => {
                        if (response) {
                            // eslint-disable-next-line no-console
                            console.log('Forgot Password response:', response)
                            setSuccessful(true)
                            reset()
                        }
                    })
                    .catch((error) => {
                        setForgotPasswordApiError(getCognitoErrorMessage(error?.name || 'unknown'))
                        datadogLogs.logger.error(
                            `Auth.forgotPassword ForgotPasswordForm - error: ${JSON.stringify({
                                error,
                            })}`
                        )
                        // eslint-disable-next-line no-console
                        console.log('ERROR with Forgot Password:', error) // remove once troubleshooting period is done
                    })
                setSubmitting(false)
            })}
        >
            <Controller
                control={control}
                name='email'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 300,
                    pattern: REGEX.EMAIL,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='email'
                            label={content.emailInput}
                            aria-describedby='email-error-message'
                            isErrored={!!errors.email}
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='email'
                            showError={!!errors.email}
                            errorMessage={content.errors.emailInput}
                        />
                    </div>
                )}
            />
            <div className={styles['submit-button-wrapper']}>
                <Button
                    type='submit'
                    flavour='primary'
                    text={content.submitButton}
                    disabled={submitting}
                />
            </div>
            {!!forgotPasswordApiError && (
                <InfoBanner
                    id='forgot-password-api-error'
                    bannerType='error'
                    message={forgotPasswordApiError}
                    isFocusable={true}
                />
            )}
        </form>
    )

    return (
        <div className={styles.container}>
            <Heading heading='2'>{content.title}</Heading>
            <Spacing />
            {forgotPasswordForm}
        </div>
    )
}

export default ForgotPasswordForm
