import React from 'react'

import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Card from '../../blocks/Card/Card'
import ColoredLine from '../../basics/ColoredLine/ColoredLine'
import Heading from '../../basics/Heading/Heading'
import Link from '../../basics/Link/Link'
import Spacing from '../../basics/Spacing/Spacing'
import Text from '../../basics/Text/Text'
import Upgrade from '../../blocks/Upgrade/Upgrade'
import ApiCredentialsSection from './ApiCredentials/ApiCredentials'
import AgentConnectFreeDescription from './AgentConnectSection/AgentConnectCruiseFreeDescription/AgentConnectFreeDescription'
import AgentConnectPaidProduct from './AgentConnectSection/AgentConnectCruisePaidDescription/AgentConnectPaidDescription'
import GettingStartedSection from './GettingStartedSection/GettingStartedSection'
import TryFreeAgentConnect from './AgentConnectSection/TryFreeSection/TryFreeSection'
import CruiseConnectDescription from './CruiseConnectSection/Description/CruiseConnectDescription'
import { PRODUCT_NAMES, TIER_NAMES } from '../../../utils/constants'
import { ProductTiersType } from '../../../App'

import styles from './Products.module.scss'
import allContent from '../../../content/content'

const content = allContent.homePage.products

type ProductsProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersType
    setUserCognitoData: React.Dispatch<React.SetStateAction<Record<string, any> | undefined>>
}

const Products: React.FC<ProductsProps> = ({ apiClient, setUserCognitoData, productTiers }) => {
    // const cruiseConnectProcessing = productTiers?.CRUISECONNECT === TIER_NAMES.PROCESSING
    const agentConnectCruiseProcessing = productTiers?.AGENTCONNECTCRUISE === TIER_NAMES.PROCESSING
    const cruiseConnectCruiseOnPaid = productTiers?.CRUISECONNECT === TIER_NAMES.PAID
    const agentConnectCruiseOnPaid = productTiers?.AGENTCONNECTCRUISE === TIER_NAMES.PAID

    const hasAtLeastOnePaidProduct = cruiseConnectCruiseOnPaid || agentConnectCruiseOnPaid
    const hasAtLeastOneFreeProduct = !agentConnectCruiseOnPaid || !cruiseConnectCruiseOnPaid

    const paidProducts = (
        <Card header={content.paidProductsHeading}>
            {/* AGENT CONNECT CRUISE SECTION (PAID) */}
            {agentConnectCruiseOnPaid && (
                <div className={styles.item}>
                    <AgentConnectPaidProduct />
                </div>
            )}
            {agentConnectCruiseOnPaid && cruiseConnectCruiseOnPaid && (
                <ColoredLine padding='double' />
            )}
            {/* CRUISE CONNECT API SECTION (PAID) */}
            {cruiseConnectCruiseOnPaid && (
                <div className={styles.item}>
                    <div className={styles['horizontal-boxes']}>
                        <CruiseConnectDescription />
                        <ApiCredentialsSection apiClient={apiClient} />
                    </div>
                </div>
            )}
        </Card>
    )

    const freeProducts = (
        <Card header={content.freeProductsHeading}>
            {/* AGENT CONNECT SUITE SECTION (FREE)*/}
            {!agentConnectCruiseOnPaid && (
                <div className={styles.item}>
                    <div className={styles['horizontal-boxes']}>
                        <AgentConnectFreeDescription />
                        <TryFreeAgentConnect />
                    </div>
                    <Upgrade
                        disabled={false}
                        upgradeIsProcessing={agentConnectCruiseProcessing}
                        apiClient={apiClient}
                        content={content.agentConnectCruise.upgradeSection}
                        productName={PRODUCT_NAMES.AGENT_CONNECT_CRUISE}
                    />
                </div>
            )}
            {!agentConnectCruiseOnPaid && !cruiseConnectCruiseOnPaid && (
                <ColoredLine padding='double' />
            )}
            {/* CRUISE CONNECT SECTION (FREE) */}
            {!cruiseConnectCruiseOnPaid && (
                <div className={styles.item}>
                    <div className={styles['horizontal-boxes']}>
                        <CruiseConnectDescription />
                        <ApiCredentialsSection apiClient={apiClient} />
                    </div>
                    {/*<Upgrade*/}
                    {/*    disabled={false}*/}
                    {/*    upgradeIsProcessing={cruiseConnectProcessing}*/}
                    {/*    apiClient={apiClient}*/}
                    {/*    content={content.cruiseConnect.upgradeSection}*/}
                    {/*    productName={PRODUCT_NAMES.CRUISE_CONNECT}*/}
                    {/*/>*/}
                </div>
            )}
        </Card>
    )

    const demoInfoBox = (
        <div className={styles['demo-info-box']}>
            <Heading heading='2' size='3'>
                {content.freeInfoBoxHeading}
            </Heading>
            <Spacing />
            <div className={styles['demo-info-box--list']}>
                <p>
                    <Text>{content.freeProductsSubHeading1}</Text>
                </p>
                <p>
                    <Text>{content.freeProductsSubHeading2}</Text>
                </p>
                <p>
                    <Text>{content.freeProductsSubHeading3}</Text>
                </p>
                <p>
                    <Text>{`${content.freeProductsSubHeading4a} `}</Text>
                    <Text>
                        <Link href={content.freeProductsSubHeading4bLinkUrl}>
                            {content.freeProductsSubHeading4bLinkText}
                        </Link>
                    </Text>
                </p>
            </div>
        </div>
    )

    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.title}</Heading>
            <GettingStartedSection agentConnectCruiseOnPaid={agentConnectCruiseOnPaid} />
            {hasAtLeastOnePaidProduct && paidProducts}
            {hasAtLeastOneFreeProduct && demoInfoBox}
            {hasAtLeastOneFreeProduct && freeProducts}
        </div>
    )
}

export default Products
