import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Navigation from '../../sections/NavigationPannel/NavigationPanel'

import { ProductTiersType } from '../../../App'
import styles from './ReportingLayout.module.css'
import ReportingPage from 'components/sections/Reporting/Reporting'

type ReportingProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersType
}

const Reporting = ({ productTiers }: ReportingProps): JSX.Element => {
    return (
        <div className={styles.container}>
            <Navigation productTiers={productTiers} />
            <ReportingPage />
        </div>
    )
}

export default Reporting
