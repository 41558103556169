import React, { useMemo, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Button from '../../basics/Button/Button'
import FieldError from '../../basics/FieldError/FieldError'
import Heading from '../../basics/Heading/Heading'
import LabelledInput from '../../blocks/LabelledInput/LabelledInput'
import Link from '../../basics/Link/Link'
import Select from '../../basics/INPUTS/Select/Select'
import Spacing from '../../basics/Spacing/Spacing'
import Text from '../../basics/Text/Text'
import TextInput from '../../basics/INPUTS/TextInput/TextInput'
import { ROUTES } from '../../../App'
import { countryData } from '../../../data/country-codes'
import { SIGNUP } from '../../../graphql-queries/queries'
import { COMPANY_TYPE, REGEX } from '../../../utils/constants'
import styles from './SignUpForm.module.css'
import allContent from '../../../content/content'
import { datadogLogs } from '@datadog/browser-logs'
import PhoneInput from '../../blocks/PhoneInput/PhoneInput'
const content = allContent.signUpPage.form
const phoneInputContent = allContent.signUpPage.form.phoneInput

type SignUpFormProps = {
    isAuthorised: boolean
    signUpClient: ApolloClient<NormalizedCacheObject>
}

type SignUpFormDataType = {
    firstName: string
    lastName: string
    emailAddress: string
    contactNumberCallingCode: string
    contactNumber: string
    companyName: string
    companyLocation: string
    companyType: string
    password: string
}

const SignUpForm: React.FC<SignUpFormProps> = ({ isAuthorised, signUpClient }) => {
    const [successful, setSuccessful] = useState<boolean>(false)
    const [signUpError, setSignUpError] = useState<string>('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
    } = useForm<SignUpFormDataType>({
        mode: 'onBlur',
        reValidateMode: 'onChange', // only comes into affect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            contactNumberCallingCode: '',
            contactNumber: '',
            companyName: '',
            companyType: 'Travel Agent',
            companyLocation: '',
        },
    })

    const countryOptions = useMemo(() => {
        const options = countryData.map((country) => {
            return {
                value: country.iso2Code,
                text: country.flag + ' ' + country.name,
            }
        })
        return [{ value: '', text: content.companyLocationInputPlaceHolder }, ...options]
    }, [])

    const countryCallingCodeOptions = useMemo(() => {
        const options = countryData
            .filter((country) => country.iso2Code !== 'UM') // Exclude the object with iso2Code 'UM' because the value won't be unique and is not needed here
            .map((country) => ({
                value: country.phoneCode,
                text: country.flag + ' ' + country.name + ' +' + country.phoneCode,
            }))

        return [{ value: '', text: 'Select...' }, ...options]
    }, [])

    const companyTypeOptions = useMemo(() => {
        return [
            { value: '', text: content.companyTypeInputPlaceholder },
            { value: COMPANY_TYPE.TRAVELAGENT, text: 'Travel Agent' },
            { value: COMPANY_TYPE.CONSORTIAHOMEWORKER, text: 'Consortia - Homeworker' },
            { value: COMPANY_TYPE.CONSORTIATRAVELAGENT, text: 'Consortia - Travel Agent' },
            { value: COMPANY_TYPE.SUPPLIER, text: 'Supplier' },
            { value: COMPANY_TYPE.INTEGRATOR, text: 'Integrator' },
            { value: COMPANY_TYPE.OTA, text: 'OTA' },
            { value: COMPANY_TYPE.OTHER, text: 'Other' },
        ]
    }, [])
    function handleOnChangeContactCntryCllngCd(e: React.ChangeEvent<HTMLSelectElement>): void {
        setValue('contactNumberCallingCode', e.target.value)
    }
    const signUpForm = (
        <form
            className={styles.form}
            onSubmit={handleSubmit(
                async ({
                    firstName,
                    lastName,
                    emailAddress,
                    contactNumberCallingCode,
                    contactNumber,
                    companyName,
                    companyLocation,
                    companyType,
                    password,
                }: SignUpFormDataType) => {
                    setIsSubmitting(true)
                    const signupFormData = {
                        firstName: firstName,
                        lastName: lastName,
                        emailAddress: emailAddress,
                        contactNumber: `+${contactNumberCallingCode}${contactNumber}`,
                        companyName: companyName,
                        companyLocation: companyLocation,
                        companyType: companyType,
                        password: password,
                    }
                    await signUpClient
                        .mutate({ mutation: SIGNUP, variables: signupFormData })
                        .then((response) => {
                            if (response) {
                                setSuccessful(true)
                                datadogLogs.logger.info(
                                    `Signup sucessful for: ${companyName} from ${companyLocation} with email: ${emailAddress}`
                                )
                            }
                            setIsSubmitting(false)
                            reset() // clear form data if successful.
                        })
                        .catch((error) => {
                            let errorMessage = ''
                            switch (error.message) {
                                case 'This company name is already in use. Please select another one.':
                                    errorMessage = content.errors.companyError
                                    break
                                case 'An Account with this email already exists.':
                                    errorMessage = content.errors.emailError
                                    break
                                default:
                                    errorMessage = content.errors.signupError
                            }
                            setSignUpError(errorMessage)
                            datadogLogs.logger.error(
                                `Mutate SIGNUP SignUpForm - userEmail: ${signupFormData.emailAddress}, error: ${error.message}`
                            )
                            setIsSubmitting(false)
                        })
                }
            )}
        >
            <Controller
                control={control}
                name='firstName'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.NAME,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='first-name'
                            label={content.firstNameInput}
                            isErrored={!!errors.firstName}
                            aria-describedby='first-name-error-message'
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='first-name'
                            showError={!!errors.firstName}
                            errorMessage={content.errors.firstNameInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='lastName'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.NAME,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='last-name'
                            label={content.lastNameInput}
                            aria-describedby='last-name-error-message'
                            isErrored={!!errors.lastName}
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='last-name'
                            showError={!!errors.lastName}
                            errorMessage={content.errors.lastNameInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='emailAddress'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 300,
                    pattern: REGEX.EMAIL,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='email'
                            label={content.emailInput}
                            aria-describedby='email-error-message'
                            isErrored={!!errors.emailAddress}
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='email'
                            showError={!!errors.emailAddress}
                            errorMessage={content.errors.emailInput}
                        />
                    </div>
                )}
            />
            <div className={styles['input-wrapper']}>
                <PhoneInput
                    countryCodeFieldName={'contactNumberCallingCode'}
                    inputFieldName={'contactNumber'}
                    generalError={!!errors.contactNumber || !!errors.contactNumberCallingCode}
                    disabled={isSubmitting}
                    groupLabel={phoneInputContent.groupLabel}
                    countryCodeLabel={phoneInputContent.countryCallingCode}
                    inputLabel={phoneInputContent.contactNumberInput}
                    countryCodeOptions={countryCallingCodeOptions}
                    control={control}
                    onChange={setValue}
                    onCountryCodeChange={handleOnChangeContactCntryCllngCd}
                    required={true}
                />
            </div>
            <Controller
                control={control}
                name='companyName'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.COMPANY_NAME,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='company-name'
                            label={content.companyNameInput}
                            aria-describedby='company-name-error-message'
                            isErrored={!!errors.companyName}
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='company-name'
                            showError={!!errors.companyName}
                            errorMessage={content.errors.companyNameInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='companyLocation'
                rules={{
                    required: true,
                    minLength: 2,
                    maxLength: 2,
                    pattern: /^[a-zA-Z]*$/,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            htmlFor='company-location'
                            label={content.companyLocationInput}
                            required={true}
                            aria-describedby='company-location-error-message'
                            isErrored={!!errors.companyLocation}
                        >
                            <Select
                                options={countryOptions}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        </LabelledInput>
                        <FieldError
                            inputId='company-location'
                            showError={!!errors.companyLocation}
                            errorMessage={content.errors.companyLocationInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='companyType'
                rules={{
                    required: true,
                    minLength: 1,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            htmlFor='company-type'
                            label={content.companyType}
                            required={true}
                            aria-describedby='company-type-error-message'
                            isErrored={!!errors.companyType}
                        >
                            <Select
                                options={companyTypeOptions}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        </LabelledInput>
                        <FieldError
                            inputId='company-location'
                            showError={!!errors.companyType}
                            errorMessage={content.errors.companyTypeError}
                        />
                    </div>
                )}
            />
            {process.env.REACT_APP_SITE_KEY && (
                <div className={styles['input-wrapper']}>
                    <p className='visually-hidden'>Capture PlaceHolder</p>
                </div>
            )}
            <div className={styles['submit-login-wrapper']}>
                <div className={styles['submit-login-container']}>
                    <Button
                        type='submit'
                        flavour='primary'
                        text={isSubmitting ? content.submitButtonProcessing : content.submitButton}
                        showSpinner={isSubmitting}
                    />
                    <p className={styles['login-text']}>
                        {content.loginText}
                        <Link to={ROUTES.LOGIN}>{content.loginLinkText}</Link>
                    </p>
                </div>
            </div>
            <div className={styles['signup-error-message']}>
                <FieldError errorMessage={signUpError} showError={!!signUpError} inputId='submit' />
            </div>
        </form>
    )

    return (
        <div className={styles.container}>
            {isAuthorised && <p>{content.loggedOnMessage}</p>}
            <Heading heading='2'>{content.title}</Heading>
            <Text>{content.subHeading}</Text>
            <Spacing />
            {successful && (
                <>
                    <p>{content.successMessage}</p>
                    <div className={styles['submit-login-button-wrapper']}>
                        <Link to={ROUTES.HOME}>
                            <Button type='button' text={content.goToLoginPageButton} />
                        </Link>
                    </div>
                </>
            )}
            {!successful && signUpForm}
        </div>
    )
}

export default SignUpForm
