import { datadogLogs } from '@datadog/browser-logs'

type FeatureToggleValue = 'on' | 'off'

/** This Default feature toggle object needs to be kept inline with current production toggle values */
export const DEFAULT_REACT_APP_FEATURE_TOGGLES: {
    TEST_FEATURE_TOGGLE: FeatureToggleValue
    TURN_ON_GROUP_MANAGEMENT: FeatureToggleValue
    TURN_ON_COMPANY_CONFIG: FeatureToggleValue
    TURN_ON_SALES_CHANNEL_MANAGEMENT: FeatureToggleValue
    TURN_ON_ADD_SALES_CHANNEL_BUTTON: FeatureToggleValue
    TURN_ON_SUPPLIER_CONFIG: FeatureToggleValue
    TURN_ON_BOOKING_REPORTING: FeatureToggleValue
    TURN_ON_REDIRECT: FeatureToggleValue
} = {
    TEST_FEATURE_TOGGLE: 'off',
    TURN_ON_GROUP_MANAGEMENT: 'on',
    TURN_ON_COMPANY_CONFIG: 'off',
    TURN_ON_SALES_CHANNEL_MANAGEMENT: 'on',
    TURN_ON_ADD_SALES_CHANNEL_BUTTON: 'off',
    TURN_ON_SUPPLIER_CONFIG: 'off',
    TURN_ON_BOOKING_REPORTING: 'off',
    TURN_ON_REDIRECT: 'off',
}

/** This feature toggle object has all toggles on for testing purposes, comment back in when needed for testing feature toggles */
export const testingAllOn = {
    TURN_ON_GROUP_MANAGEMENT: true,
    TURN_ON_COMPANY_CONFIG: true,
    TURN_ON_SALES_CHANNEL_MANAGEMENT: true,
    TURN_ON_ADD_SALES_CHANNEL_BUTTON: true,
    TURN_ON_SUPPLIER_CONFIG: true,
    TURN_ON_BOOKING_REPORTING: true,
    TURN_ON_REDIRECT: true,
}

type DefaultFeatureToggleKeys = keyof typeof DEFAULT_REACT_APP_FEATURE_TOGGLES

export function getReactAppToggles(
    featureTogglesProcessEnvString: string
): Record<string, boolean> {
    let newFeatureToggleObject: Record<string, FeatureToggleValue> = {}
    try {
        if (featureTogglesProcessEnvString) {
            newFeatureToggleObject = JSON.parse(featureTogglesProcessEnvString)
        } else {
            newFeatureToggleObject = { ...DEFAULT_REACT_APP_FEATURE_TOGGLES }
        }
    } catch (error) {
        datadogLogs.logger.error(`Error parsing feature toggles: ${JSON.stringify(error)}`)
        // eslint-disable-next-line no-console
        console.log('error', error)
    }
    //filter out anything that has value which isn't 'on' or 'off' and keys that are misspelled/different to defaults
    const filteredOutWrongKeysAndValues = Object.fromEntries(
        Object.entries(newFeatureToggleObject).filter(
            ([key, value]) =>
                (value === 'on' || value === 'off') &&
                DEFAULT_REACT_APP_FEATURE_TOGGLES.hasOwnProperty(key) //this checks if newFeatureToggleObject key is also a key in DEFAULT_REACT_APP_FEATURE_TOGGLES
        )
    )
    //identify missing keys by comparing the keys in default and result
    const missingKeys = Object.keys(DEFAULT_REACT_APP_FEATURE_TOGGLES).filter(
        (key) => !filteredOutWrongKeysAndValues.hasOwnProperty(key)
    )
    // Create the final object with boolean values
    return {
        ...Object.fromEntries(
            Object.entries(filteredOutWrongKeysAndValues).map(([key, value]) => [
                key,
                value === 'on', // Convert 'on' to true and 'off' to false
            ])
        ),
        ...Object.fromEntries(
            missingKeys.map((key) => [
                key,
                DEFAULT_REACT_APP_FEATURE_TOGGLES[key as DefaultFeatureToggleKeys] === 'on',
            ])
        ),
    }
}
