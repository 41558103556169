import React, { useEffect, useState } from 'react'
import {
    API_LIST_USERS,
    API_USER_CREATE,
    API_USER_RESET_PASSWORD,
} from '../../../../graphql-queries/queries'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import classnames from 'classnames'

import { CRUISE_CONNECT_API_USER_CLIENT_ERRORS } from '../../../../utils/constants'

import Button from '../../../basics/Button/Button'
import ColoredLine from '../../../basics/ColoredLine/ColoredLine'
import FieldError from '../../../basics/FieldError/FieldError'
import Heading from '../../../basics/Heading/Heading'
import Icon from '../../../basics/Icon/Icon'
import InfoBanner from '../../../blocks/InfoBanner/InfoBanner'
import InlineSpinner from '../../../basics/Spinners/InlineSpinner'
import Link from '../../../basics/Link/Link'
import Text from '../../../basics/Text/Text'

import productStyles from '../Products.module.scss'
import styles from './ApiCredentials.module.css'
import allContent from 'content/content'

const content = allContent.homePage.products.cruiseConnect.apiCredentialsSection
const navContent = allContent.navigationPanel

const ApiCredentials = ({
    apiClient,
}: {
    apiClient: ApolloClient<NormalizedCacheObject>
}): JSX.Element => {
    const [isSubmittingGenerateApiUser, setIsSubmittingGenerateApiUser] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [isCopied, setIsCopied] = useState<boolean>(false)
    const [apiUserList, setApiUserList] = useState<Record<any, any>[] | null>(null)
    const [fetchingApiUserList, setFetchingApiUserList] = useState<boolean | null>(null)

    useEffect((): void => {
        apiClient
            .query({ query: API_LIST_USERS })
            .then((response) => {
                setFetchingApiUserList(true)

                if (response?.data?.listApiUsers) {
                    setApiUserList(response.data.listApiUsers)
                }
            })
            .catch((error) => {
                setFetchingApiUserList(true)
                datadogLogs.logger.error(
                    `Query API_LIST_USERS ApiCredentials - error: ${JSON.stringify({ error })}`
                )
                if (
                    error.message === CRUISE_CONNECT_API_USER_CLIENT_ERRORS.CAN_NOT_GET_CREDENTIALS
                ) {
                    setErrorMessage(content.errors.apiCredentials)
                } else {
                    setErrorMessage(content.errors.apiGeneric)
                }
            })
    }, [apiClient])

    const generateApiUser = async (): Promise<void> => {
        setIsSubmittingGenerateApiUser(true)
        await apiClient
            .mutate({ mutation: API_USER_CREATE })
            .then((response) => {
                if (response) {
                    datadogLogs.logger.info(
                        `Mutate API_USER_CREATE Successfully created API credentials for user: ${JSON.stringify(
                            response
                        )}`
                    )
                }
                if (response.data.apiUserCreate) {
                    setApiUserList([response.data.apiUserCreate])
                }
                setIsSubmittingGenerateApiUser(false)
            })
            .catch((error) => {
                setIsSubmittingGenerateApiUser(false)
                if (error.message === CRUISE_CONNECT_API_USER_CLIENT_ERRORS.USER_ALREADY_EXISTS) {
                    setErrorMessage(content.errors.userExists)
                } else {
                    setErrorMessage(content.errors.apiGeneric)
                }
                datadogLogs.logger.error(
                    `Mutate API_USER_CREATE ApiCredentials - error: ${JSON.stringify({ error })}`
                )
            })
    }

    const regeneratePasswordHandler = async (): Promise<void> => {
        setIsCopied(false)
        setIsSubmittingGenerateApiUser(true)
        await apiClient
            .mutate({ mutation: API_USER_RESET_PASSWORD })
            .then((response) => {
                if (response) {
                    datadogLogs.logger.info(
                        `Mutate API_USER_RESET_PASSWORD Successfully reset API Password for user: ${JSON.stringify(
                            response
                        )}`
                    )
                }
                if (response.data.apiUserResetPassword) {
                    setApiUserList([response.data.apiUserResetPassword])
                }
                setIsSubmittingGenerateApiUser(false)
            })
            .catch((error) => {
                setIsSubmittingGenerateApiUser(false)
                setErrorMessage(content.errors.apiGeneric)
                datadogLogs.logger.error(
                    `Mutate API_USER_RESET_PASSWORD ApiCredentials - error: ${JSON.stringify({
                        error,
                    })}}`
                )
            })
    }

    const copyPasswordToClipboardHandler = async (): Promise<void> => {
        setIsCopied(true)
        const copyText = document.getElementById('password-field')
        if (copyText) {
            try {
                await navigator.clipboard.writeText(copyText.innerText)
            } catch (error) {
                datadogLogs.logger.error('Error copying password to clipboard', { error })
            }
        }
    }

    useEffect(() => {
        if (isCopied) {
            const timeoutId = setTimeout(() => {
                setIsCopied(false)
            }, 3000)
            return () => clearTimeout(timeoutId)
        }
    }, [isCopied])

    function getUserList(): JSX.Element {
        const users = apiUserList?.map((user): JSX.Element => {
            return (
                <div className={productStyles['api-users-container']} key={user.username}>
                    <div className={productStyles['api-users-user-pass-and-button-container']}>
                        <div className={productStyles['text-box-container']}>
                            <div className={productStyles['text-box-item']}>
                                <Text weight='bold'>{content.username}</Text>
                                <Text className={productStyles['text-box']} color='gray'>
                                    {user.username}
                                </Text>
                            </div>
                            <div className={productStyles['text-box-item']}>
                                <Text weight='bold'>{content.password}</Text>
                                <div className={productStyles['text-box-password-field']}>
                                    <Text
                                        id='password-field'
                                        className={productStyles['text-box']}
                                        color='gray'
                                    >
                                        {user.password || '******'}
                                    </Text>
                                    {user.password && !isCopied && (
                                        <Button
                                            flavour='icon'
                                            text={content.buttonCopyPassword}
                                            onClick={copyPasswordToClipboardHandler}
                                            type='button'
                                            iconName='Copy'
                                        />
                                    )}
                                    {user.password && isCopied && (
                                        <Button
                                            flavour='icon'
                                            text={content.buttonCopyPassword}
                                            onClick={copyPasswordToClipboardHandler}
                                            type='button'
                                            iconName='Check'
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <Button
                            id='regeneratePassword'
                            text={content.regenerateApiCredsButton}
                            onClick={regeneratePasswordHandler}
                            showSpinner={isSubmittingGenerateApiUser}
                            type='button'
                        />
                    </div>
                    {user.password && (
                        <InfoBanner
                            id='regenerate-password-info-banner'
                            bannerType='info'
                            message={content.errors.apiPassword}
                        />
                    )}
                </div>
            )
        })
        return <>{users}</>
    }

    const verticalBoxClasses = [productStyles['item-box'], productStyles['item-box--vertical']]
    const verticalBoxClassnames = classnames(...verticalBoxClasses)
    const boxHalfGapClassnames = classnames(
        productStyles['item-box'],
        productStyles['item-box--half-gap']
    )

    return (
        <div className={verticalBoxClassnames}>
            <div className={verticalBoxClassnames}>
                <div className={boxHalfGapClassnames}>
                    <Icon iconName='Key' />
                    <Heading heading='3'>{content.setupTitle}</Heading>
                </div>
                <p>
                    <Text>
                        {apiUserList && apiUserList?.length === 0
                            ? content.setupDescriptionNoUsers
                            : content.setupDescriptionWithUsers}
                    </Text>
                </p>
                <div className={styles['link-wrapper']}>
                    <Text>{content.setupDescription2a}</Text>{' '}
                    <Link
                        href={navContent.apiDocumentationURL}
                        target='_blank'
                        onClick={(): void =>
                            datadogLogs.logger.info('API docs link clicked (on page)')
                        }
                    >
                        <Text weight='bold' color='tertiary-blue'>
                            {navContent.apiDocumentation}
                        </Text>
                    </Link>{' '}
                    <Text>{content.setupDescription2b}</Text>
                </div>
            </div>
            <ColoredLine />
            <div>
                <div className={productStyles['item-credentials-error-container']}>
                    {!fetchingApiUserList && <InlineSpinner text={content.loading} />}
                    {apiUserList && apiUserList?.length === 0 && (
                        <Button
                            id='generateUser'
                            text={content.generateApiCredsButton}
                            onClick={generateApiUser}
                            disabled={isSubmittingGenerateApiUser || errorMessage !== null}
                            type='button'
                        />
                    )}
                    {apiUserList && apiUserList.length > 0 && getUserList()}
                    <FieldError
                        inputId='generateUser'
                        showError={errorMessage !== null}
                        errorMessage={errorMessage ?? ''}
                    />
                </div>
            </div>
        </div>
    )
}

export default ApiCredentials
