import React, { ReactNode } from 'react'

import Heading from 'components/basics/Heading/Heading'
import styles from './Card.module.scss'

type CardProps = {
    header: string | ReactNode
    children: ReactNode
}

const Card = ({ header, children }: CardProps): JSX.Element => {
    return (
        <div className={styles['card']}>
            <div className={styles['card__heading']}>
                {typeof header === 'string' ? (
                    <Heading heading='2' size='3' onDarkBackground={true}>
                        {header}
                    </Heading>
                ) : (
                    header
                )}
            </div>
            <div className={styles['card__content']}>{children}</div>
        </div>
    )
}
export default Card
