import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import Text from '../../basics/Text/Text'
import CredentialsItem, { CredentialItem } from './CredentialsItem/CredentialsItem'

import styles from './Credentials.module.scss'
import allContent from '../../../content/content'

const content = allContent.supplierConfigurationPage

export type CredentialFieldsItem = {
    /** Supplier name */
    supplierName: string
    /** Is that supplier enabled in AgentConnect */
    isEnabled: boolean
    /** Does this supplier have credentials filled in */
    hasCredentials: boolean
}

type CredentialsProps = {
    credentialItems: CredentialItem[]
    apiClient: ApolloClient<NormalizedCacheObject>
}

/** Credentials: returns a list of fields that lets user add or update credentials for a given supplier.
    It also lets user disable a supplier in Agent Connect search results.
 */

const Credentials: React.FC<CredentialsProps> = ({
    credentialItems,
    apiClient,
}: CredentialsProps) => {
    const suppliers = credentialItems.map((item) => {
        // TODO: This should be a list: <ul> <li>supplier 1</li> <li>supplier2 </li> <li>supplier 3</li> </ul>
        return (
            <CredentialsItem
                key={item.supplierCode}
                credentialItem={{
                    supplierCode: item.supplierCode,
                    available: item.available,
                    created: item.created,
                }}
                apiClient={apiClient}
            />
        )
    })
    return (
        <div className={styles['supplier-configuration__list']}>
            <div className={styles['supplier-configuration__description']}>
                <Text weight='bold'>{content.form.supplier}</Text>
                <Text weight='bold'>
                    {content.form.enable} / {content.form.disable}
                </Text>
            </div>
            {suppliers}
        </div>
    )
}

export default Credentials
