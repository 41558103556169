import React from 'react'
import Button from '../../../../basics/Button/Button'
import Heading from '../../../../basics/Heading/Heading'
import Link from '../../../../basics/Link/Link'
import Text from '../../../../basics/Text/Text'
import classnames from 'classnames'

import styles from './TryFreeSection.module.scss'

import allContent from '../../../../../content/content'
const content = allContent.homePage.products.agentConnectCruise.demoSection

const TryFreeAgentConnect = (): JSX.Element => {
    const verticalBoxClasses = [styles['item-box'], styles['item-box--vertical']]
    const verticalBoxClassnames = classnames(...verticalBoxClasses)
    const boxHalfGapClassnames = classnames(styles['item-box'], styles['item-box--half-gap'])

    return (
        <div className={verticalBoxClassnames}>
            <div className={boxHalfGapClassnames}>
                <Heading heading='3'>{content.setupTitle}</Heading>
            </div>
            <div>
                <Text weight='bold' className={styles['item-text']}>
                    {content.title}
                </Text>
                <Text>{content.setupDescription1}</Text>
                <Link
                    className={styles['item-inline-link']}
                    href={content.contactUsLink}
                    target='_blank'
                >
                    {content.contactUsLinkText}
                </Link>
                <Text>{content.setupDescription2}</Text>
            </div>
            <div>
                <Link
                    href={process.env.REACT_APP_AGENT_CONNECT_URL}
                    target='_blank'
                    noUnderLine={true}
                >
                    <Button text={content.button} type='button' />
                </Link>
            </div>
        </div>
    )
}

export default TryFreeAgentConnect
