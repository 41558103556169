import React from 'react'

import Button from 'components/basics/Button/Button'
import Text from 'components/basics/Text/Text'

import styles from './PaginationButtons.module.scss'
import allContent from 'content/content'

const content = allContent.components.paginationButtons

type PaginationButtonsProps = {
    handlePageChange: (newPage: number) => void
    page: number
    totalNumOfPages: number
}

const PaginationButtons = ({
    handlePageChange,
    page,
    totalNumOfPages,
}: PaginationButtonsProps): JSX.Element => {
    return (
        <div className={styles['button-container']}>
            <Button
                type='submit'
                flavour='icon'
                iconName='ChevronLeft'
                onClick={(): void => handlePageChange(page - 1)}
                disabled={page === 1}
                text={content.previousPageButtonText}
            />
            <Text className={styles['page-button-text']}>{`${page} of ${totalNumOfPages}`}</Text>
            <Button
                type='submit'
                flavour='icon'
                iconName='ChevronRight'
                onDarkBackground
                onClick={(): void => handlePageChange(page + 1)}
                disabled={page === totalNumOfPages}
                text={content.nextPageButtonText}
            />
        </div>
    )
}

export default PaginationButtons
