import React, { forwardRef } from 'react'
import classnames from 'classnames'

import Icon, { IconNames } from '../../Icon/Icon'
import styles from './TextInput.module.css'

export type TextInputProps = React.ComponentPropsWithRef<'input'> & {
    /** use same id for associating a label via its htmlFor prop (NOTE, this is passed automatically by our LabelledInput) */
    id?: string
    /** is a callback that is passed the event target value from the ChangeEvent */
    onChangeCallback?(value: string): void
    iconName?: IconNames
    /** toggle for error styling */
    isErrored?: boolean
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
    const {
        iconName = undefined,
        id,
        className,
        onChangeCallback,
        onChange,
        isErrored = false,
        ...rest
    } = props
    const textInputClassNames = classnames(
        `${styles['text-input']}`,
        { [styles['text-input--errored']]: isErrored },
        className
    )

    return (
        <div className={styles.container}>
            <input
                id={id}
                className={textInputClassNames}
                type='text'
                ref={ref}
                onChange={(e): void => {
                    if (onChangeCallback) onChangeCallback(e.target.value)
                    else if (onChange) onChange(e)
                }}
                {...rest}
            />
            {iconName && <Icon iconName={iconName} iconSize='S' className={styles['input-icon']} />}
        </div>
    )
})
TextInput.displayName = 'TextInput'

export default TextInput
