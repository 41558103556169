import React, { useEffect, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import UserManagement from '../../sections/UserManagement/UserManagement'
import Navigation from '../../sections/NavigationPannel/NavigationPanel'

import { ProductTiersType } from '../../../App'
import styles from './UserManagementLayout.module.css'
import { handleFetchGroupList } from '../../sections/GroupManagement/GroupManagement'
import { Group } from '../../../api-data-models/GroupsContentModel'

type UserManagementLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersType
}

const UserManagementLayout: React.FC<UserManagementLayoutProps> = ({ apiClient, productTiers }) => {
    const [groupsListData, setGroupsListData] = useState<Group[] | []>([])
    const [fetchingGroups, setFetchingGroups] = useState<boolean>(false)
    const [groupsApiErrorMessage, setGroupsApiErrorMessage] = useState<string | null>(null)

    useEffect(() => {
        handleFetchGroupList({
            apiClient,
            setGroupsListData,
            setGroupsApiErrorMessage,
            setFetchingGroups,
        })
    }, [apiClient])
    return (
        <div className={styles.container}>
            <Navigation productTiers={productTiers} />
            <UserManagement
                apiClient={apiClient}
                groupsListData={groupsListData}
                fetchingGroups={fetchingGroups}
                groupsApiErrorMessage={groupsApiErrorMessage}
            />
        </div>
    )
}

export default UserManagementLayout
