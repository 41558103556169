import React, { useEffect, useState, createContext, useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import DashBoardLayout from './components/layouts/HomeLayout/HomeLayout'
import LargeSpinner from './components/basics/Spinners/LargeSpinner'
import LoginLayout from './components/layouts/LoginLayout/LoginLayout'
import NewPasswordLayout from './components/layouts/NewPasswordLayout/NewPasswordLayout'
import SignUpLayout from './components/layouts/SignUpLayout/SignUpLayout'
import ForgotPasswordLayout from './components/layouts/ForgotPasswordLayout/ForgotPasswordLayout'
import ResetPasswordLayout from './components/layouts/ResetPasswordLayout/ResetPasswordLayout'
import SupplierConfigurationLayout from './components/layouts/SupplierConfigurationLayout/SupplierConfigurationLayout'
import UserManagementLayout from './components/layouts/UserManagementLayout/UserManagementLayout'
import SalesChannelManagementLayout from 'components/layouts/SalesChannelManagementLayout/SalesChannelManagementLayout'
import EditSalesChannelLayout from 'components/layouts/EditSalesChannelLayout/EditSalesChannelLayout'
import GenericLayout from './components/layouts/GenericLayout/GenericLayout'
import CompanyConfigurationLayout from 'components/layouts/CompanyConfigurationLayout/CompanyConfigurationLayout'
import ReportingLayout from 'components/layouts/ReportingLayout/ReportingLayout'
import TimeoutLayout from 'components/layouts/TimeoutLayout/TimeoutLayout'

import { getDataFromLocalStorage, removeDataFromLocalStorage } from './utils/use-local-storage'
import {
    APP_URLS,
    COGNITO_ACTIONS,
    ENVIRONMENT_NAMES,
    LOCAL_STORAGE_USER_DATA_KEY,
    TIER_NAMES,
} from './utils/constants'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { GET_CONNECT_USER_DATA } from './graphql-queries/queries'
import { datadogLogs } from '@datadog/browser-logs'
import content from './content/content'
import { getTenantId } from './utils/cognito-helpers/get-tenant-id'
import { getReactAppToggles } from './utils/get-feature-toggles'
import packageJson from '../package.json'
import GroupManagementLayout from './components/layouts/GroupManagementLayout/GroupManagementLayout'

type ApiProductTierInfo = {
    name: ProductNameType
    tier: TierType
}

export type ProductTiersType = Record<ProductNameType, TierType>

const initialProductTiers: ProductTiersType = {
    AGENTCONNECTCRUISE: undefined,
    CRUISECONNECT: undefined,
    AGENTCONNECTFLIGHT: undefined,
    AGENTCONNECTHOTEL: undefined,
}

export const ROUTES = {
    LOGIN: '/',
    HOME: '/homepage',
    SIGNUP: '/signup',
    NEW_PASSWORD: '/new-password',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    SUPPLIER_CONFIGURATION: '/supplier-configuration',
    COMPANY_CONFIGURATION: '/company-configuration',
    USER_MANAGEMENT: '/user-management',
    GROUP_MANAGEMENT: '/group-management',
    SALES_CHANNEL_MANAGEMENT: '/sales-channel-management',
    EDIT_SALES_CHANNEL_MANAGEMENT: '/edit-sales-channel',
    VERSION: '/version',
    MI_REPORTING: '/mi-reporting',
    TIMEOUT: '/session-timed-out',
}

type AppProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
}

const toggles = getReactAppToggles(
    process.env.REACT_APP_FEATURE_TOGGLES ? process.env.REACT_APP_FEATURE_TOGGLES : `"{}"`
)
export const FeatureToggleContext = createContext(toggles)

function App({ apiClient }: AppProps): JSX.Element {
    const featureToggles = useContext(FeatureToggleContext)

    /** APP IS NO LONGER IN USE - REDIRECT TO AGENT CONNECT WHERE ADMIN PAGES ALSO LIVE NOW */
    let agentConnectUrl: string

    switch (process.env.REACT_APP_ENVIRONMENT_NAME) {
        case ENVIRONMENT_NAMES.DEVELOPMENT:
            agentConnectUrl = APP_URLS.DEVELOPMENT
            break
        case ENVIRONMENT_NAMES.STAGE:
            agentConnectUrl = APP_URLS.STAGE
            break
        case ENVIRONMENT_NAMES.LOCAL:
            agentConnectUrl = APP_URLS.LOCAL
            break
        case ENVIRONMENT_NAMES.PRODUCTION:
        default:
            agentConnectUrl = APP_URLS.PRODUCTION
            break
    }
    // eslint-disable-next-line no-console
    if (featureToggles.TURN_ON_REDIRECT) {
        // eslint-disable-next-line no-console
        console.log('FeatureToggles:', featureToggles)
        // eslint-disable-next-line no-console
        console.log('This app is no longer in use. Redirecting to:', agentConnectUrl)
        window.location.replace(agentConnectUrl)
    } else {
        // eslint-disable-next-line no-console
        console.log('This app is no longer in use. Soon will be redirected to:', agentConnectUrl)
    }

    const navigate = useNavigate()
    const [isAuthorised, setIsAuthorised] = useState<boolean>(false)
    const [userCognitoData, setUserCognitoData] = useState<Record<string, any> | undefined>(
        undefined
    )
    const [isFetchingProductTiers, setIsFetchingProductTiers] = useState<boolean | null>(null)
    const [productTiers, setProductTiers] =
        useState<Record<ProductNameType, TierType>>(initialProductTiers)

    /** State for knowing if the session has timed out - to show banner to user why they are suddenly on login page */
    const [showExpiredBanner, setShowExpiredBanner] = useState<boolean>(false)

    /** When app first loads (say refresh window) check if userData is still present - and don't make user log in again */
    useEffect(() => {
        const localUserCognitoData = getDataFromLocalStorage({ key: LOCAL_STORAGE_USER_DATA_KEY })
        if (localUserCognitoData) {
            // Will only have happened if user presses back button from NEW_PASSWORD without setting a new Password.
            if (localUserCognitoData.challengeName === COGNITO_ACTIONS.new_password) {
                // Do not set to authorised, as they need to change their password, without their JWT token is only able to log on, no authorisation is given.
                removeDataFromLocalStorage({ key: LOCAL_STORAGE_USER_DATA_KEY })
            } else {
                // There is userData and the expiry time has not passed and we haven't updated userData state yet!
                setUserCognitoData(localUserCognitoData)
                setIsAuthorised(true)
                // TODO: At some point add a check to see if token is still valid with Cognito, not just within its 60mins
                //  (since deleting a user while logged in will still have userData in local storage) and then refresh the session if so
            }
        }
    }, [])

    const tenantId = getTenantId(userCognitoData)

    /** To set app features we need the user Tire for each product, will run this if tenantId changes value (from undefined to a value) */
    useEffect(() => {
        if (tenantId) {
            setIsFetchingProductTiers(true)
            apiClient
                .query({ query: GET_CONNECT_USER_DATA })
                .then((response) => {
                    const products: ApiProductTierInfo[] =
                        response.data?.getConnectUserData?.products

                    if (products) {
                        const productTiersTemp: ProductTiersType = initialProductTiers
                        for (const product of products) {
                            productTiersTemp[product.name] = product.tier
                        }
                        setProductTiers({ ...productTiersTemp })
                    }
                    setIsFetchingProductTiers(false)
                })
                .catch((error) => {
                    datadogLogs.logger.error(
                        `Query GET_CONNECT_USER_DATA App error: ${JSON.stringify(error)}`
                    )
                    setIsFetchingProductTiers(false)
                })
        }
    }, [apiClient, tenantId])

    const turnOnSupplierConfig =
        featureToggles.TURN_ON_SUPPLIER_CONFIG &&
        (productTiers.AGENTCONNECTCRUISE === TIER_NAMES.PAID ||
            productTiers.CRUISECONNECT === TIER_NAMES.PAID)
    const turnOnUserManagement = productTiers.AGENTCONNECTCRUISE === TIER_NAMES.PAID
    const turnOnGroupManagement = featureToggles.TURN_ON_GROUP_MANAGEMENT
    const turnOnCompanyConfiguration = featureToggles.TURN_ON_COMPANY_CONFIG
    const turnOnSalesChannelManagement = featureToggles.TURN_ON_SALES_CHANNEL_MANAGEMENT
    const turnOnBookingReporting = featureToggles.TURN_ON_BOOKING_REPORTING

    function cleanUpAppOnLogout(): void {
        removeDataFromLocalStorage({ key: LOCAL_STORAGE_USER_DATA_KEY }) // clear local storage of user cognito data
        setUserCognitoData(undefined) // set app state userCognitoData to 'un-fetched' state of undefined
        setIsAuthorised(false) // set app state isAuthorised to 'un-fetched' state of null (not false as if they were denied)
        setIsFetchingProductTiers(true) // set app state isFetchingConnectUserData to 'un-fetched' state of true
        removeDataFromLocalStorage({ key: LOCAL_STORAGE_USER_DATA_KEY }) // clear the userData - product tiers, tenantInfo etc.
    }

    function logUserOut({ to }: { to: string }): void {
        cleanUpAppOnLogout()
        navigate(to)
    }

    const AppRoutes = (
        <Routes>
            <Route
                path={ROUTES.LOGIN}
                element={
                    isAuthorised && userCognitoData ? (
                        <Navigate to={ROUTES.HOME} />
                    ) : (
                        <LoginLayout
                            showExpiredBanner={showExpiredBanner}
                            setShowExpiredBanner={setShowExpiredBanner}
                            setIsAuthorised={setIsAuthorised}
                            setUserCognitoData={setUserCognitoData}
                        />
                    )
                }
            />
            <Route path={ROUTES.SIGNUP} element={<SignUpLayout isAuthorised={isAuthorised} />} />
            <Route
                path={ROUTES.NEW_PASSWORD}
                element={<NewPasswordLayout setIsAuthorised={setIsAuthorised} />}
            />
            <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordLayout />} />
            <Route
                path={ROUTES.RESET_PASSWORD}
                element={<ResetPasswordLayout setIsAuthorised={setIsAuthorised} />}
            />
            <Route
                path={ROUTES.HOME}
                element={
                    isAuthorised ? (
                        <DashBoardLayout
                            apiClient={apiClient}
                            productTiers={productTiers}
                            setUserCognitoData={setUserCognitoData}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
            />
            <Route
                path={ROUTES.USER_MANAGEMENT}
                element={
                    isAuthorised && turnOnUserManagement ? (
                        <UserManagementLayout apiClient={apiClient} productTiers={productTiers} />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
            />
            <Route
                path={ROUTES.GROUP_MANAGEMENT}
                element={
                    isAuthorised && turnOnGroupManagement ? (
                        <GroupManagementLayout apiClient={apiClient} productTiers={productTiers} />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
            />
            <Route
                path={ROUTES.SALES_CHANNEL_MANAGEMENT}
                element={
                    isAuthorised && turnOnSalesChannelManagement ? (
                        <SalesChannelManagementLayout
                            apiClient={apiClient}
                            productTiers={productTiers}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
            />
            <Route
                path={ROUTES.EDIT_SALES_CHANNEL_MANAGEMENT} // Route without params exists because the page will handle this scenario and tell the user the issue
                element={
                    isAuthorised && turnOnSalesChannelManagement ? (
                        <EditSalesChannelLayout apiClient={apiClient} productTiers={productTiers} />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
            />
            <Route
                path={`${ROUTES.EDIT_SALES_CHANNEL_MANAGEMENT}/:salesChannelId`}
                element={
                    isAuthorised && turnOnSalesChannelManagement ? (
                        <EditSalesChannelLayout apiClient={apiClient} productTiers={productTiers} />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
            />
            <Route
                path={ROUTES.SUPPLIER_CONFIGURATION}
                element={
                    isAuthorised && turnOnSupplierConfig ? (
                        <SupplierConfigurationLayout
                            apiClient={apiClient}
                            productTiers={productTiers}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
            />
            <Route
                path={ROUTES.COMPANY_CONFIGURATION}
                element={
                    isAuthorised && turnOnCompanyConfiguration ? (
                        <CompanyConfigurationLayout
                            apiClient={apiClient}
                            productTiers={productTiers}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
            />
            <Route
                path={ROUTES.MI_REPORTING}
                element={
                    isAuthorised && turnOnBookingReporting ? (
                        <ReportingLayout apiClient={apiClient} productTiers={productTiers} />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
            />
            <Route
                path={ROUTES.TIMEOUT}
                element={<TimeoutLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.VERSION}`}
                element={
                    <div className='general-container'>
                        <p>{`version:${packageJson.version}`}</p>
                    </div>
                }
            />
            {/* catch all routes that don't exist and divert to root - rather than show 404 error */}
            <Route path='*' element={<Navigate to={'/'} replace />} />
        </Routes>
    )

    return (
        <>
            <Helmet>
                <title>{content.app.tabTitle}</title>
            </Helmet>
            <GenericLayout
                isAuthorised={isAuthorised}
                userData={userCognitoData}
                logUserOut={logUserOut}
            >
                {isAuthorised && isFetchingProductTiers ? (
                    <LargeSpinner text={content.app.configurationLoadingSpinnerText} />
                ) : (
                    AppRoutes
                )}
            </GenericLayout>
        </>
    )
}

export default App
