import { datadogLogs } from '@datadog/browser-logs'
import { Auth } from '@aws-amplify/auth'

import { ROUTES } from '../App'
import { updateCognitoAuthData, UserData } from './check-and-perform-user-session-refresh-if-needed'

export async function refreshUserSession({
    userData,
    navigate,
}: {
    navigate: (url: string) => void
    userData: UserData
}): Promise<void> {
    const userContext = datadogLogs.getGlobalContext()
    let userEmail = ''
    try {
        const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
        userEmail = currentAuthenticatedUser.attributes.email
        const currentSession = await Auth.currentSession()
        currentAuthenticatedUser.refreshSession(
            currentSession.getRefreshToken(),
            (error: Error, session: any) => {
                const { refreshToken } = session
                if (refreshToken.token) {
                    updateCognitoAuthData({ cognitoSession: session, userData })
                }
                if (error) {
                    throw error
                }
            }
        )
    } catch (error: Error | any) {
        datadogLogs.logger.error(
            `Unable to refresh cognito session, userEmail ${userEmail}`,
            { userContext },
            error
        )
        /** All steps for cleaning app state are dealt with on timeout page. */
        navigate(ROUTES.TIMEOUT)
    }
}
