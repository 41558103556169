export type SalesChannel = {
    salesChannelId: string
    salesChannelTitle: string
}

export type SalesChannelFromApi = {
    salesChannelId: string
    title: string
}

export type Group = {
    groupTitle: string
    groupId: string
    salesChannels: SalesChannel[]
}

export type GroupFromApi = {
    title: string
    userGroupId: string
    salesChannels: SalesChannelFromApi[]
}
export const GroupsContentFunction = (groupsData: GroupFromApi[]): Group[] => {
    return groupsData.map((group: GroupFromApi) => ({
        groupTitle: group.title,
        groupId: group.userGroupId,
        salesChannels: group.salesChannels.map(
            (salesChannel: SalesChannelFromApi): SalesChannel => ({
                salesChannelId: salesChannel.salesChannelId,
                salesChannelTitle: salesChannel.title,
            })
        ),
    }))
}
