import React from 'react'
import classnames from 'classnames'
import styles from './Select.module.css'

export type selectOption = {
    value: string
    text: string
    label?: string
}

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
    className?: string
    options: selectOption[]
    required?: boolean
    value?: string
    /** toggle for error styling */
    isErrored?: boolean
    /** toggle for disabled styling and function */
    disabled?: boolean
    /** to create a 'placeholder' text when not option has been chosen */
    placeholder?: Record<string, string>
}
const Select: React.FC<SelectProps> = ({
    value,
    onChange,
    options,
    className,
    isErrored = false,
    placeholder,
    disabled = false,
    ...rest
}) => {
    const classNames = classnames(className, styles.input, {
        [styles['input--errored']]: isErrored,
        [styles['input--disabled']]: disabled,
    })

    return (
        <select
            value={value}
            className={classNames}
            onChange={onChange}
            disabled={disabled}
            {...rest}
        >
            {placeholder && (
                <option value={placeholder.value} disabled>
                    {placeholder.text}
                </option>
            )}
            {options.map((option) => {
                return (
                    <option
                        key={`${option.value}-${option.text}`}
                        value={option.value}
                        label={option.label}
                    >
                        {option.text}
                    </option>
                )
            })}
        </select>
    )
}
export default Select
