import React from 'react'

import Heading from '../../../../basics/Heading/Heading'
import Text from '../../../../basics/Text/Text'

import styles from './AgentConnectFreeDescription.module.scss'
import allContent from '../../../../../content/content'

const content = allContent.homePage.products.agentConnectCruise.freeProductDescription

const AgentConnectFreeDescription = (): JSX.Element => {
    return (
        <div className={styles.container}>
            <div className={styles['heading-wrapper']}>
                <div className={styles['heading-text']}>
                    <Heading heading='3'>{content.productName}</Heading>
                    <Text weight='bold'>{content.subtitle}</Text>
                </div>
            </div>
            <div>
                <p>
                    <Text>{content.productDescription}</Text>
                </p>
                <p>
                    <Text>{content.productDescription2a} </Text>
                    <Text weight='bold'>{content.productDescription2bBold} </Text>
                    <Text>{content.productDescription2c} </Text>
                    <Text weight='bold'>{content.productDescription2dBold} </Text>
                    <Text>{content.productDescription2e}</Text>
                </p>
            </div>
        </div>
    )
}

export default AgentConnectFreeDescription
