export const getTenantId = (userCognitoData: Record<string, any> | undefined): string => {
    /** Data structure when signed in fully */
    if (userCognitoData?.signInUserSession?.idToken?.payload?.['custom:tenantid'])
        return userCognitoData.signInUserSession?.idToken?.payload?.['custom:tenantid']

    /** Data structure when signing in for the first time or on password challenge */
    if (userCognitoData?.user?.attributes?.['custom:tenantid'])
        return userCognitoData.user?.attributes?.['custom:tenantid']

    return ''
}
