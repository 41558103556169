import React, { SetStateAction, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Auth } from '@aws-amplify/auth'
import { datadogLogs } from '@datadog/browser-logs'

import Button from '../../basics/Button/Button'
import FieldError from '../../basics/FieldError/FieldError'
import Heading from '../../basics/Heading/Heading'
import LabelledInput from '../../blocks/LabelledInput/LabelledInput'
import PasswordInput from '../../basics/INPUTS/PasswordInput/PasswordInput'
import Spacing from '../../basics/Spacing/Spacing'
import TextInput from '../../basics/INPUTS/TextInput/TextInput'
import {
    LOCAL_STORAGE_USER_DATA_KEY,
    ACCESS_TOKEN_EXPIRY_MINS,
    REGEX,
} from '../../../utils/constants'
import { setDataToLocalStorage } from '../../../utils/use-local-storage'
import { getCognitoErrorMessage } from '../../../utils/get-cognito-error-message'

import styles from './ResetPasswordForm.module.css'
import allContent from '../../../content/content'
import { ROUTES } from '../../../App'
const content = allContent.resetPasswordPage.form

type ResetPasswordProps = {
    setIsAuthorised: React.Dispatch<SetStateAction<boolean>>
    email?: string
}

type ResetPasswordDataType = {
    email: string
    code: string
    newPassword: string
}

const ResetPasswordForm: React.FC<ResetPasswordProps> = ({ setIsAuthorised, email }) => {
    const [successful, setSuccessful] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [resetPasswordError, setResetPasswordError] = useState<string>('')
    const navigate = useNavigate()

    useEffect(() => {
        if (successful) {
            navigate(ROUTES.HOME) // pass the email to reset password form, set it as default and disable input
        }
    }, [successful, navigate])

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ResetPasswordDataType>({
        mode: 'onBlur',
        reValidateMode: 'onChange', // only comes into effect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            email: email || '',
            code: '',
            newPassword: '',
        },
    })

    const resetPasswordForm = (
        <form
            className={styles.form}
            onSubmit={handleSubmit(async (formFields: ResetPasswordDataType) => {
                setSubmitting(true)
                await Auth.forgotPasswordSubmit(
                    formFields.email,
                    formFields.code,
                    formFields.newPassword
                )
                    .then((response) => {
                        if (response) {
                            Auth.currentAuthenticatedUser()
                                .then((user) => {
                                    datadogLogs.logger.info(
                                        `Auth.forgotPasswordSubmit ResetPasswordForm Successfully get user info after resetting the password: ${user.username}`
                                    )
                                    setDataToLocalStorage({
                                        data: { user: user },
                                        key: LOCAL_STORAGE_USER_DATA_KEY,
                                        expiryMins: ACCESS_TOKEN_EXPIRY_MINS,
                                    })
                                })
                                .catch((error) => {
                                    // eslint-disable-next-line no-console
                                    console.log('ERROR with currentAuthenticatedUser:', error) // remove once troubleshooting period is done
                                    datadogLogs.logger.error(
                                        `Auth.currentAuthenticatedUser ResetPasswordForm - email: ${email} , error: ${error.message}`
                                    )
                                })
                            // eslint-disable-next-line no-console
                            console.log('Reset Password response:', response)
                            setIsAuthorised(true)
                            setSuccessful(true)
                            reset()
                        }
                    })
                    .catch((error) => {
                        // eslint-disable-next-line no-console
                        console.log('ERROR with Reset Password:', error) // remove once troubleshooting period is done
                        setResetPasswordError(getCognitoErrorMessage(error.name || 'unknown'))
                        datadogLogs.logger.error(
                            `Error thrown in Auth.forgotPasswordSubmit ResetPasswordForm - error: ${error.message}`
                        )
                        setSubmitting(false)
                    })
                setSubmitting(false)
            })}
        >
            <Controller
                control={control}
                name='email'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 300,
                    pattern: REGEX.EMAIL,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='email'
                            label={content.emailInput}
                            aria-describedby='email-error-message'
                        >
                            <TextInput
                                value={email}
                                onChange={onChange}
                                onBlur={onBlur}
                                disabled={!!email}
                            />
                        </LabelledInput>
                        <FieldError
                            inputId='email'
                            showError={!!errors.email}
                            errorMessage={content.errors.emailInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='code'
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='code'
                            label={content.codeInput}
                            isErrored={!!errors.code}
                            aria-describedby='confirmation-code-error-message'
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='code'
                            showError={!!errors.code}
                            errorMessage={content.errors.codeInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='newPassword'
                rules={{
                    required: true,
                    minLength: 6,
                    maxLength: 30,
                    pattern:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[\^$*.[\]{}()?"!@#%&/,><':;|_~`=+-]).{6,30}$/,
                    // cognito password policy: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='new-password'
                            label={content.passInput}
                            aria-describedby='password-error-message'
                        >
                            <PasswordInput
                                autoComplete='off'
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        </LabelledInput>
                        <FieldError
                            inputId='new-password'
                            showError={!!errors.newPassword}
                            errorMessage={content.errors.passInput}
                        />
                        <Spacing />
                    </div>
                )}
            />
            <div className={styles['submit-button-wrapper']}>
                <Button
                    type='submit'
                    flavour='primary'
                    text={content.submitButton}
                    disabled={submitting}
                />
            </div>
            <FieldError
                errorMessage={resetPasswordError}
                showError={!!resetPasswordError}
                inputId='submit'
            />
        </form>
    )

    return (
        <div className={styles.container}>
            <Heading heading='2'>{content.title}</Heading>
            <Spacing />
            {resetPasswordForm}
        </div>
    )
}

export default ResetPasswordForm
