import React, { useEffect, useState } from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import Header from 'components/blocks/Header/Header'
import Link from 'components/basics/Link/Link'
import Menu from 'components/blocks/Menu/Menu'
import Text from 'components/basics/Text/Text'
import { ROUTES } from 'App'

import allContent from 'content/content'
import styles from './HeaderContent.module.css'
import Image from '../../basics/Image/Image'

const headerContent = allContent.header
const userMenuContent = allContent.header.userMenu

type HeaderContentProps = {
    isAuthorised: null | boolean
    logUserOut({ to }: { to: string }): void
    userData?: Record<string, any>
}
const HeaderSection: React.FC<HeaderContentProps> = ({ isAuthorised, logUserOut, userData }) => {
    const [emailState, setEmailState] = useState<string>('')
    let email = emailState

    useEffect(() => {
        // Check email in both challengeParam and userAttributes as the userAttributes, for NEW_PASSWORD flow vs rest of time logged on
        email =
            userData?.user?.attributes?.email || // eslint-disable-line react-hooks/exhaustive-deps
            userData?.challengeParam?.userAttributes?.email || // eslint-disable-line react-hooks/exhaustive-deps
            userData?.signInUserSession?.idToken?.payload?.email // eslint-disable-line react-hooks/exhaustive-deps
        setEmailState(email)
    }, [userData])

    function onClickLogOut(): void {
        datadogLogs.logger.info(`Logging out user: ${email}`)
        logUserOut({ to: ROUTES.LOGIN })
    }

    const userMenuOptions = [
        {
            jsxElement: <Text color='white'>{email}</Text>,
            label: 'user-email',
        },
        {
            jsxElement: (
                <Button
                    flavour='secondary'
                    text={userMenuContent.logoutButton}
                    type='button'
                    onClick={onClickLogOut}
                />
            ),
            label: userMenuContent.logoutButton,
        },
    ]

    return (
        <Header>
            <Link to={ROUTES.HOME}>
                <Image className={styles.logo} src={headerContent.logo} alt='TravelTek logo' />
            </Link>
            <div className={styles['nav-container']}>
                {isAuthorised && (
                    <Menu
                        id={userMenuContent.label}
                        buttonText={userMenuContent.label}
                        onDarkBackground={true}
                        doubleTopMargin={true}
                        iconName='UserCircle'
                        options={userMenuOptions}
                    />
                )}
            </div>
        </Header>
    )
}

export default HeaderSection
