import React, { SetStateAction, useEffect, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'

import Button from '../../../basics/Button/Button'
import Icon, { IconNames } from '../../../basics/Icon/Icon'
import InfoBanner from '../../../blocks/InfoBanner/InfoBanner'
import Modal from '../../../blocks/Modal/Modal'
import Text from '../../../basics/Text/Text'
import { User } from '../../../../api-data-models/UsersContentModel'
import {
    AGENT_CONNECT_USER_DISABLE,
    AGENT_CONNECT_USER_ENABLE,
    AGENT_CONNECT_USER_RESET_PASSWORD,
    AGENT_CONNECT_USER_UPDATE,
    ASSIGN_USER_TO_A_GROUP,
} from '../../../../graphql-queries/queries'

import Menu from '../../../blocks/Menu/Menu'
import UpdateUserModal, {
    UpdatedUserDetailsData,
    UpdatedUserGroupData,
} from '../UpdateUserModal/UpdateUserModal'
import InlineSpinner from '../../../basics/Spinners/InlineSpinner'
import { Group } from '../../../../api-data-models/GroupsContentModel'

import styles from './UsersList.module.scss'
import allContent from '../../../../content/content'

const content = allContent.userManagementPage.list

const USER_STATUS_ICONS = {
    FORCE_CHANGE_PASSWORD: { icon: 'Clock' },
    CONFIRMED: { icon: 'Check' },
    DISABLED: { icon: 'Cross' },
}

export type HandleUpdateUserFunction = (
    userDetailData: UpdatedUserDetailsData | null,
    userGroupData: UpdatedUserGroupData | null
) => void

type UsersListProps = {
    usersData: User[]
    apiClient: ApolloClient<NormalizedCacheObject>
    fetchingGroups: boolean
    groupsListData: Group[]
    groupsApiErrorMessage: string | null
    setShouldRefreshUsersData: React.Dispatch<SetStateAction<boolean>>
    setUpdateUserDetailsSuccessMessage: React.Dispatch<SetStateAction<string | null>>
    setUpdateUserGroupSuccessMessage: React.Dispatch<SetStateAction<string | null>>
    setUpdateUserGroupError: React.Dispatch<SetStateAction<string | null>>
    setUpdateUserDetailsError: React.Dispatch<SetStateAction<string | null>>
}

const UsersList: React.FC<UsersListProps> = ({
    usersData,
    apiClient,
    fetchingGroups,
    groupsListData,
    groupsApiErrorMessage,
    setShouldRefreshUsersData,
    setUpdateUserDetailsSuccessMessage,
    setUpdateUserGroupSuccessMessage,
    setUpdateUserGroupError,
    setUpdateUserDetailsError,
}) => {
    const [renderUserList, setRenderUserList] = useState<User[]>(usersData)
    const [isLoading, setIsLoading] = useState(false)
    const [loadingEmail, setLoadingEmail] = useState<string | null>(null)
    const [isPasswordConfirmModalOpen, setIsPasswordConfirmModalOpen] = useState<boolean>(false)
    const [isUserUpdateModalOpen, setIsUserUpdateModalOpen] = useState<boolean>(false)
    const [userDataForUpdate, setUserDataForUpdate] = useState<User | null>(null)
    const [clickedResetButtonId, setClickedResetButtonId] = useState<string>('') // set ID of button that opens modal so focus can be returned on close
    const [confirmedResetPassword, setConfirmedResetPassword] = useState<boolean>(false)
    const [resetPasswordEmail, setResetPasswordEmail] = useState<string | null>(null)
    const [disableUserError, setDisableUserError] = useState<string | null>(null)
    const [enableUserError, setEnableUserError] = useState<string | null>(null)
    const [resetPasswordError, setResetPasswordError] = useState<string | null>(null)
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState<string | null>(null)
    const userContext = datadogLogs.getGlobalContext()

    useEffect(() => {
        setRenderUserList(usersData)
    }, [usersData])

    useEffect(() => {
        if (resetPasswordEmail && confirmedResetPassword) {
            setResetPasswordError(null)
            setResetPasswordSuccess(null)
            setIsLoading(true)
            setLoadingEmail(resetPasswordEmail)
            apiClient
                .mutate({
                    mutation: AGENT_CONNECT_USER_RESET_PASSWORD,
                    variables: { emailAddress: resetPasswordEmail },
                })
                .then((response) => {
                    setResetPasswordEmail(null)
                    setConfirmedResetPassword(false)
                    setIsLoading(false)
                    setLoadingEmail(null)
                    if (response?.data?.agentConnectUserResetPassword) {
                        setResetPasswordSuccess(
                            `${resetPasswordEmail} ${content.resetPasswordSuccess} ${response.data.agentConnectUserResetPassword.password}`
                        )
                        datadogLogs.logger.info(
                            `Mutate AGENT_CONNECT_USER_RESET_PASSWORD Successfully reset user password: ${resetPasswordEmail}`
                        )
                    }
                })
                .catch((error) => {
                    setResetPasswordEmail(null)
                    setConfirmedResetPassword(false)
                    setIsLoading(false)
                    setLoadingEmail(null)
                    datadogLogs.logger.error(
                        `Mutate AGENT_CONNECT_USER_RESET_PASSWORD UserList - email: ${resetPasswordEmail}, error: ${JSON.stringify(
                            {
                                error,
                            }
                        )}`,
                        { userContext },
                        error
                    )
                    setResetPasswordError(`${content.errors.resetPassword} ${resetPasswordEmail}`)
                })
        }
    }, [apiClient, confirmedResetPassword, resetPasswordEmail, userContext])

    const updateUserList = (prevUserList: User[], userDataForUpdate: any): User[] => {
        return prevUserList.map((user: User) => {
            if (user.emailAddress === userDataForUpdate.emailAddress) {
                user.userGroups = [userDataForUpdate.userGroup]
                return { ...user, ...userDataForUpdate }
            }
            return user
        })
    }

    const updateUserDetails = (data: UpdatedUserDetailsData): void => {
        const { userId, emailAddress, firstName, lastName } = data
        if (emailAddress && userId) {
            setIsLoading(true)
            setLoadingEmail(data.emailAddress)
            apiClient
                .mutate({
                    mutation: AGENT_CONNECT_USER_UPDATE,
                    variables: {
                        userId,
                        emailAddress,
                        firstName,
                        lastName,
                    },
                })
                .then((response) => {
                    setIsLoading(false)
                    setLoadingEmail(null)
                    setUpdateUserDetailsSuccessMessage(content.updateUserDetailsSuccessMessage)
                    datadogLogs.logger.info(
                        `Mutate AC_UPDATE_USER_AND_ASSIGN_GROUP Successfully update user: ${data.emailAddress}`
                    )
                })
                .catch((error) => {
                    setIsLoading(false)
                    setLoadingEmail(null)
                    setUpdateUserDetailsError(`${content.errors.updateUser} ${data.emailAddress}`)
                    datadogLogs.logger.error(
                        'Mutate AC_UPDATE_USER_AND_ASSIGN_GROUP App error:',
                        {},
                        error
                    )
                })
                .finally(() => {
                    const returnFocusTo = document.getElementById(clickedResetButtonId)
                    setShouldRefreshUsersData(true)
                    setIsUserUpdateModalOpen(false)
                    setUserDataForUpdate(null)
                    returnFocusTo?.focus()
                })
        }
    }

    const updateUserGroup = (data: UpdatedUserGroupData): void => {
        const { userId, groupId } = data
        if (userId) {
            setIsLoading(true)
            apiClient
                .mutate({
                    mutation: ASSIGN_USER_TO_A_GROUP,
                    variables: { userGroupId: groupId, userId: userId },
                })
                .then(() => {
                    setIsLoading(false)
                    setLoadingEmail(null)
                    setRenderUserList((prevUserList) =>
                        updateUserList(prevUserList, { ...data, userGroups: [groupId] })
                    )
                    setUpdateUserGroupSuccessMessage(content.updateUserGroupSuccessMessage)
                    datadogLogs.logger.info(
                        `Mutate AC_UPDATE_USER_AND_ASSIGN_GROUP Successfully update user: ${data.userId}`
                    )
                })
                .catch((error) => {
                    setIsLoading(false)
                    setLoadingEmail(null)
                    setUpdateUserGroupError(`${content.errors.updateUser} ${data.userId}`)
                    datadogLogs.logger.error(
                        'Mutate AC_UPDATE_USER_AND_ASSIGN_GROUP App error:',
                        {},
                        error
                    )
                })
                .finally(() => {
                    const returnFocusTo = document.getElementById(clickedResetButtonId)
                    setShouldRefreshUsersData(true)
                    setIsUserUpdateModalOpen(false)
                    setUserDataForUpdate(null)
                    returnFocusTo?.focus()
                })
        }
    }

    const handleUpdateUser: HandleUpdateUserFunction = (
        userDetailsData: UpdatedUserDetailsData | null,
        userGroupData: UpdatedUserGroupData | null
    ): void => {
        if (userDetailsData) {
            updateUserDetails(userDetailsData)
        }
        if (userGroupData) {
            updateUserGroup(userGroupData)
        }
    }

    const handleDisableUser = (email: string): void => {
        setIsLoading(true)
        setLoadingEmail(email)
        apiClient
            .mutate({
                mutation: AGENT_CONNECT_USER_DISABLE,
                variables: { emailAddress: email },
            })
            .then(() => {
                setIsLoading(false)
                setLoadingEmail(null)
                setRenderUserList((prev) =>
                    prev.map((user) =>
                        user.emailAddress === email ? { ...user, enabled: false } : user
                    )
                )
                datadogLogs.logger.info(
                    `Mutate AGENT_CONNECT_USER_DISABLE Successfully disable user: ${email}`
                )
            })
            .catch((error) => {
                setIsLoading(false)
                setLoadingEmail(null)
                setDisableUserError(`${content.errors.disableUser} ${email}`)
                datadogLogs.logger.error(
                    `Mutate AGENT_CONNECT_USER_DISABLE UserList - email: ${resetPasswordEmail}, error: ${JSON.stringify(
                        {
                            error,
                        }
                    )}`
                )
            })
    }

    const handleEnableUser = (email: string): void => {
        setIsLoading(true)
        setLoadingEmail(email)
        apiClient
            .mutate({
                mutation: AGENT_CONNECT_USER_ENABLE,
                variables: { emailAddress: email },
            })
            .then(() => {
                setIsLoading(false)
                setLoadingEmail(null)
                setRenderUserList((prev) =>
                    prev.map((user) =>
                        user.emailAddress === email ? { ...user, enabled: true } : user
                    )
                )
                datadogLogs.logger.info(
                    `Mutate AGENT_CONNECT_USER_ENABLE Successfully enable user: ${email}`
                )
            })
            .catch((error) => {
                setIsLoading(false)
                setLoadingEmail(null)
                setEnableUserError(`${content.errors.enableUser} ${email}`)
                datadogLogs.logger.error(
                    `Mutate AGENT_CONNECT_USER_ENABLE UserList - email: ${resetPasswordEmail}, error: ${JSON.stringify(
                        {
                            error,
                        }
                    )}`
                )
            })
    }
    return (
        <>
            {disableUserError && (
                <InfoBanner
                    id='disable-user-error-banner'
                    bannerType='error'
                    message={disableUserError}
                />
            )}
            {enableUserError && (
                <InfoBanner
                    id='enable-user-error-banner'
                    bannerType='error'
                    message={enableUserError}
                />
            )}
            {resetPasswordError && (
                <InfoBanner
                    id='password-reset-error-banner'
                    bannerType='error'
                    message={resetPasswordError}
                />
            )}
            {resetPasswordSuccess && (
                <InfoBanner
                    id='password-reset-success-banner'
                    bannerType='success'
                    message={resetPasswordSuccess}
                />
            )}
            {groupsApiErrorMessage && (
                <InfoBanner
                    id='fetch-groups-data-error-banner'
                    bannerType='error'
                    message={groupsApiErrorMessage}
                />
            )}
            <div className={styles.list}>
                <div className={styles['list-header']}>
                    <div>
                        <Text weight='bold' size='L'>
                            {content.userColumn}
                        </Text>
                    </div>
                    <div>
                        <Text weight='bold' size='L'>
                            {content.confirmationStatusColumn}
                        </Text>
                    </div>
                    <div>
                        <Text weight='bold' size='L'>
                            {content.linkedGroupsColumn}
                        </Text>
                        <div />
                    </div>
                </div>
                {renderUserList.map((user: User) => {
                    const options = [
                        {
                            label: content.resetPassword,
                            onClick: (): void => {
                                setResetPasswordEmail(user.emailAddress)
                                setIsPasswordConfirmModalOpen(true)
                                setClickedResetButtonId(user.emailAddress + '-action-menu-button')
                            },
                            disabled: !!resetPasswordEmail,
                        },
                        {
                            label: content.edit,
                            onClick: (): void => {
                                setUserDataForUpdate(user)
                                setIsUserUpdateModalOpen(true)
                                setClickedResetButtonId(user.emailAddress + '-action-menu-button')
                            },
                        },
                        {
                            label: user.enabled ? content.disable : content.enable,
                            onClick: (): void => {
                                if (user.enabled) {
                                    handleDisableUser(user.emailAddress)
                                } else {
                                    handleEnableUser(user.emailAddress)
                                }
                                setClickedResetButtonId(user.emailAddress + '-action-menu-button')
                            },
                        },
                    ]

                    return (
                        <div className={styles['list-item']} key={user.emailAddress}>
                            <div className={styles['list-item--left']}>
                                <Text weight='bold'>
                                    {user.firstName} {user.lastName}
                                </Text>
                                <Text>{user.emailAddress}</Text>
                            </div>
                            <div className={styles['list-item--middle']}>
                                <div className={styles['status-wrapper']}>
                                    {user.enabled ? (
                                        <>
                                            <Icon
                                                iconName={
                                                    USER_STATUS_ICONS[user.userStatus]
                                                        .icon as IconNames
                                                }
                                                iconColor='tertiary-blue'
                                            />
                                            <Text>{content.userStatus[user.userStatus]}</Text>
                                        </>
                                    ) : (
                                        <Text>{content.userStatus.DISABLED}</Text>
                                    )}
                                </div>
                            </div>
                            <div className={styles['list-item--right']}>
                                {user.userGroups.map((group) => {
                                    return <div key={group.groupId}>{group.groupTitle}</div>
                                })}
                                <div>
                                    {isLoading && loadingEmail === user.emailAddress ? (
                                        <div className={styles['inline-spinner']}>
                                            <InlineSpinner text={content.actionMenuProcessing} />
                                        </div>
                                    ) : (
                                        <Menu
                                            id={user.userId}
                                            options={options}
                                            buttonText={content.actionMenu}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <UpdateUserModal
                fetchingGroups={fetchingGroups}
                groupsListData={groupsListData}
                groupsApiErrorMessage={groupsApiErrorMessage}
                isSubmitting={isLoading}
                onClose={(): void => {
                    const returnFocusTo = document.getElementById(clickedResetButtonId)
                    setIsUserUpdateModalOpen(false)
                    setUserDataForUpdate(null)
                    returnFocusTo?.focus()
                }}
                userDataForUpdate={userDataForUpdate}
                handleUpdateUser={handleUpdateUser}
                isOpen={isUserUpdateModalOpen}
            />
            <Modal
                isOpen={isPasswordConfirmModalOpen}
                returnFocusId={clickedResetButtonId}
                setClosed={(): void => {
                    const returnFocusTo = document.getElementById(clickedResetButtonId)
                    setResetPasswordEmail(null)
                    setIsPasswordConfirmModalOpen(false)
                    returnFocusTo?.focus() // Set focus back to element (button) that lost focus to modal
                }}
                headerText={content.resetPasswordModal.title}
            >
                <div className={styles['reset-password-modal-content']}>
                    <p>
                        <Text>{content.resetPasswordModal.text1}</Text>{' '}
                        <Text weight='bold'>{resetPasswordEmail}</Text>
                    </p>
                    <p>
                        <Text>{content.resetPasswordModal.text2}</Text>
                    </p>
                    <div className={styles['button-group']}>
                        <Button
                            type='button'
                            flavour='secondary'
                            text={content.resetPasswordModal.close}
                            onClick={(): void => {
                                const returnFocusTo = document.getElementById(clickedResetButtonId)
                                setResetPasswordEmail(null)
                                setIsPasswordConfirmModalOpen(false)
                                returnFocusTo?.focus() // Set focus back to element (button) that lost focus to modal
                            }}
                        />
                        <Button
                            type='button'
                            flavour='primary'
                            text={content.resetPasswordModal.confirm}
                            onClick={(): void => {
                                const returnFocusTo = document.getElementById(clickedResetButtonId)
                                setIsPasswordConfirmModalOpen(false)
                                setConfirmedResetPassword(true)
                                returnFocusTo?.focus() // Set focus back to element (button) that lost focus to modal
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default UsersList
