export const DATE_FORMAT_D_M_Y_SLASH = 'dd/MM/yyyy'
export const DATE_FORMAT_Y_M_D_HYPHEN = 'yyyy-MM-dd' // 2024-01-07
export const DATE_FORMAT_USA_SHORT = 'MM/dd/yyyy' // 01/07/2024
export const DATE_FORMAT_USA_LONG = 'MMM d, yyyy' // 'Jan 27, 2024'

export const COMPANY_TYPE = {
    CONSORTIATRAVELAGENT: 'CONSORTIATRAVELAGENT',
    CONSORTIAHOMEWORKER: 'CONSORTIAHOMEWORKER',
    OTA: 'OTA',
    TRAVELAGENT: 'TRAVELAGENT',
    SUPPLIER: 'SUPPLIER',
    INTEGRATOR: 'INTEGRATOR',
    OTHER: 'OTHER',
}

export const COGNITO_ACTIONS = { new_password: 'NEW_PASSWORD_REQUIRED' }

/** REGEX for input (each key press) and submit (final value) validation */
export const REGEX = {
    NAME: /^[a-zA-Z]([',.\- a-zA-Z])*[a-zA-Z]$/, // Allows lower upper case letters, along with characters [',.\- ] except at start and end.
    COMPANY_NAME: /^[a-zA-Z\d][\w.!#£$&@%'*+=?^`, -]*[a-zA-Z\d]$/,
    DIGITS_ONLY: /^[0-9]*$/, // checks for any number of 0-9's. Don't use \d as this allows other ASCII characters that are numbers but not ones our backend would recognise!
    DIGITS_0_to_2: /^[0-9]{0,2}$/, // checks for any number of 0-9's. Don't use \d as this allows other ASCII characters that are numbers but not ones our backend would recognise!
    DIGITS_0_to_3: /^[0-9]{0,3}$/, // checks for any number of 0-9's. Don't use \d as this allows other ASCII characters that are numbers but not ones our backend would recognise!
    DIGITS_0_to_4: /^[0-9]{0,4}$/, // checks for any number of 0-9's. Don't use \d as this allows other ASCII characters that are numbers but not ones our backend would recognise!
    DAY: /^(([1-9]?|0[1-9])|(1[0-9])|(2[0-9])|(3[01]))$/, // 01 or 1 (02 or 2) up to 31
    MONTH: /^(([1-9]|0[1-9])|(1[0-2]))$/, // 01 or 1 (02 or 2) to 12, not 13 and above
    YEAR_1900_to_2099: /^(19|20)\d\d$/,
    EMAIL: /^[\w.!#$%'*+/=?^`{|}~-]+@[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*$/,
    // Valid characters in email: https://ladedu.com/valid-characters-for-email-addresses-the-complete-list/
    INT_PHONE: /^\+[1-9]\d+$/,
}
export const LOCAL_STORAGE_USER_DATA_KEY = 'userData'

export const ACCESS_TOKEN_EXPIRY_MINS = 60 // time in minutes for token to expire

export const CRUISE_CONNECT_API_USER_CLIENT_ERRORS = {
    CAN_NOT_GET_CREDENTIALS: 'Cannot get API credentials',
    USER_ALREADY_EXISTS: 'API User already exists',
}

export type SupplierCode =
    | 'CCL'
    | 'CEL'
    | 'CUN'
    | 'HAL'
    | 'MSC'
    | 'NCL'
    | 'PCL'
    | 'RCL'
    | 'SBN'
    | 'VVC'
    | 'VKG'
    | 'DCL'
    | 'AZA'
    | 'EXP'

export const PRODUCT_NAMES: Record<string, ProductNameType> = {
    AGENT_CONNECT_CRUISE: 'AGENTCONNECTCRUISE',
    AGENT_CONNECT_FLIGHT: 'AGENTCONNECTFLIGHT',
    AGENT_CONNECT_HOTEL: 'AGENTCONNECTHOTEL',
    CRUISE_CONNECT: 'CRUISECONNECT',
}

export const TIER_NAMES: Record<string, TierType> = {
    PAID: 'PAID',
    PROCESSING: 'PROCESSING',
    FREE: 'FREE',
}

export const ENVIRONMENT_NAMES = {
    DEVELOPMENT: 'development',
    STAGE: 'stage',
    PRODUCTION: 'production',
    LOCAL: 'local',
}

export const APP_URLS = {
    DEVELOPMENT: 'https://enya.traveltekdev.com',
    STAGE: 'https://enya.traveltekstage.com',
    PRODUCTION: 'https://agentconnect.traveltek.net',
    LOCAL: 'https://media1.tenor.com/m/muj4RR_sPl0AAAAC/homer-simpson-les-simpson.gif',
}
