import React, { useEffect } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Navigation from '../../sections/NavigationPannel/NavigationPanel'
import Products from '../../sections/Products/Products'
import { ProductTiersType } from '../../../App'
import { getDataFromLocalStorage, setDataToLocalStorage } from '../../../utils/use-local-storage'
import { ACCESS_TOKEN_EXPIRY_MINS, LOCAL_STORAGE_USER_DATA_KEY } from '../../../utils/constants'
import { Auth } from '@aws-amplify/auth'
import { datadogLogs } from '@datadog/browser-logs'

import styles from './HomeLayout.module.css'

type HomeLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersType
    setUserCognitoData: React.Dispatch<React.SetStateAction<Record<string, any> | undefined>>
}

const HomeLayout = ({
    apiClient,
    productTiers,
    setUserCognitoData,
}: HomeLayoutProps): JSX.Element => {
    // eslint-disable-next-line no-console
    console.log('HomeLayout productTiers', productTiers)
    /** When landing on HomePage for first after choosing password, the userData is not yet fully populated and needs to be fetched */
    useEffect(() => {
        const userData = getDataFromLocalStorage({ key: LOCAL_STORAGE_USER_DATA_KEY }) // TODO: should just have app pass this in from state.
        const userContext = datadogLogs.getGlobalContext()
        if (!userData?.user?.signInUserSession?.accessToken?.jwtToken) {
            // if (userCognitoData && !userCognitoData.signInUserSession?.accessToken?.jwtToken) { // not sure
            // eslint-disable-next-line no-console
            console.log(
                '(HomeLayout) App useEffect did not find data in user.signInUserSession.accessToken, so re-fetched currentAuthenticatedUser and set to state and local storage.' +
                    '\n current state of useData:',
                userData
            )
            Auth.currentAuthenticatedUser()
                .then((updatedUserData) => {
                    datadogLogs.logger.info(
                        `Auth.currentAuthenticatedUser HomeLayout - Successfully fetched full userData on first password set from signup: ${updatedUserData.username}`,
                        { userContext }
                    )
                    setDataToLocalStorage({
                        data: { user: updatedUserData },
                        key: LOCAL_STORAGE_USER_DATA_KEY,
                        expiryMins: ACCESS_TOKEN_EXPIRY_MINS,
                    })
                    setUserCognitoData(updatedUserData)
                })
                .catch((error) => {
                    datadogLogs.logger.error(
                        `Auth.currentAuthenticatedUser HomeLayout - error: ${JSON.stringify(error)}`,
                        { userContext },
                        error
                    )
                })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    // CAN NOT HAVE USER DATA IN THIS ARRAY AS IT CAUSES INFINITE RE-RENDERS

    return (
        <div className={styles.container}>
            <Navigation productTiers={productTiers} />
            <Products
                productTiers={productTiers}
                apiClient={apiClient}
                setUserCognitoData={setUserCognitoData}
            />
        </div>
    )
}

export default HomeLayout
