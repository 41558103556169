import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import SupplierConfiguration from '../../sections/SupplierConfiguration/SupplierConfiguration'
import Navigation from '../../sections/NavigationPannel/NavigationPanel'

import { ProductTiersType } from '../../../App'
import styles from './SupplierConfigurationLayout.module.css'

type SupplierConfigLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersType
}

const SupplierConfigLayout = ({
    apiClient,
    productTiers,
}: SupplierConfigLayoutProps): JSX.Element => {
    return (
        <div className={styles.container}>
            <Navigation productTiers={productTiers} />
            <SupplierConfiguration apiClient={apiClient} />
        </div>
    )
}

export default SupplierConfigLayout
