import React from 'react'
import Text from '../Text/Text'
import ListItem from '../List/ListItem'
import List from '../List/List'
import styles from './Breadcrumb.module.css'

type Crumb = {
    url?: string
    text: string
}

type BreadCrumbProps = {
    urlList: Crumb[]
}

const Breadcrumb: React.FC<BreadCrumbProps> = ({ urlList }) => {
    const navTrail = urlList.map((crumb: Crumb, i) => {
        const location = crumb.url ? (
            <a className={styles.link} href={crumb.url}>
                <Text color='primary-blue-40'>{crumb.text}</Text>
            </a>
        ) : (
            <Text weight='bold'>{crumb.text}</Text>
        )

        return (
            <ListItem key={crumb.text} listKey={crumb.text}>
                {i > 0 && (
                    <Text
                        weight='bold'
                        color='primary-blue-40'
                        className={styles.divider}
                    >{`>`}</Text>
                )}
                {location}
            </ListItem>
        )
    })
    return <List className={styles.list}>{navTrail}</List>
}

export default Breadcrumb
