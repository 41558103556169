import React, { SetStateAction, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import { datadogLogs } from '@datadog/browser-logs'

import Button from '../../basics/Button/Button'
import FieldError from '../../basics/FieldError/FieldError'
import Heading from '../../basics/Heading/Heading'
import InfoBanner from '../../blocks/InfoBanner/InfoBanner'
import LabelledInput from '../../blocks/LabelledInput/LabelledInput'
import PasswordInput from '../../basics/INPUTS/PasswordInput/PasswordInput'
import Spacing from '../../basics/Spacing/Spacing'
import { ROUTES } from '../../../App'
import { setDataToLocalStorage } from '../../../utils/use-local-storage'
import { LOCAL_STORAGE_USER_DATA_KEY, ACCESS_TOKEN_EXPIRY_MINS } from '../../../utils/constants'

import styles from './NewPasswordForm.module.css'
import allContent from '../../../content/content'
const content = allContent.newPasswordPage.form

type NewPasswordFormProps = {
    setIsAuthorised: React.Dispatch<SetStateAction<boolean>>
    setUserData: React.Dispatch<SetStateAction<Record<string, any> | undefined>>
    userData: Record<string, any> | undefined
}

type NewPasswordFormDataType = {
    newPassword: string
}

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
    setIsAuthorised,
    setUserData,
    userData,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isPasswordApiError, setIsPasswordApiError] = useState<boolean>(false)
    const [isCognitoUser, setIsCognitoUser] = useState<boolean>(false)
    const [cognitoUser, setCognitoUser] = useState<Record<string, any> | undefined>()
    const navigate = useNavigate()

    useEffect(() => {
        if (userData && !isCognitoUser) {
            setCognitoUser(assignCognitoUser(userData))
        }
    }, [userData, isCognitoUser])

    function goToHomePage(): void {
        navigate(ROUTES.HOME)
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<NewPasswordFormDataType>({
        mode: 'onBlur',
        reValidateMode: 'onChange', // only comes into effect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            newPassword: '',
        },
    })

    function assignCognitoUser(userData: Record<string, any>): any {
        let cognitoUserObject: any = userData
        // Make sure that userObject is an instance of CognitoUser
        if (!(cognitoUserObject instanceof CognitoUser) && !!userData.pool) {
            const pool = new CognitoUserPool({
                UserPoolId: userData?.pool.userPoolId,
                ClientId: userData?.pool.clientId,
                endpoint: userData?.client.endpoint,
                Storage: userData?.storage,
                AdvancedSecurityDataCollectionFlag:
                    userData?.pool?.advancedSecurityDataCollectionFlag,
            })

            cognitoUserObject = new CognitoUser({
                Username: userData?.username,
                Pool: pool,
                Storage: window?.localStorage,
            })
            cognitoUserObject.Session = userData?.Session
        }
        setIsCognitoUser(true)
        return cognitoUserObject
    }

    const newPasswordForm = (
        <form
            className={styles.form}
            onSubmit={handleSubmit(async (formFields: NewPasswordFormDataType) => {
                setIsSubmitting(true)
                await Auth.completeNewPassword(
                    cognitoUser, // the Cognito User Object
                    formFields.newPassword // the new password
                )
                    .then((user) => {
                        setIsSubmitting(false)
                        /** At this time the user is logged in (when MFA is not set required) */
                        // eslint-disable-next-line no-console
                        console.log('Cognito auth response (set new password):', user)
                        if (user) {
                            Auth.currentAuthenticatedUser()
                                .then((user) => {
                                    setDataToLocalStorage({
                                        data: user,
                                        key: LOCAL_STORAGE_USER_DATA_KEY,
                                        expiryMins: ACCESS_TOKEN_EXPIRY_MINS,
                                    })
                                    setIsAuthorised(true)
                                    setUserData(user)
                                    goToHomePage()
                                })
                                .catch((error) => {
                                    setIsPasswordApiError(true)
                                    datadogLogs.logger.error(
                                        `Auth.currentAuthenticatedUser NewPasswordFrom - user: ${user}, error - ${JSON.stringify(
                                            error
                                        )}`
                                    )
                                })
                        }
                    })
                    .catch((error) => {
                        setIsSubmitting(false)
                        setIsPasswordApiError(true)
                        datadogLogs.logger.error(
                            `Auth.completeNewPassword NewPasswordForm - error: ${JSON.stringify({
                                error,
                            })}`
                        )
                    })
            })}
        >
            <Controller
                control={control}
                name='newPassword'
                rules={{
                    required: true,
                    minLength: 6,
                    maxLength: 30,
                    pattern:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[\^$*.[\]{}()?"!@#%&/,><':;|_~`=+-]).{6,30}$/,
                    // cognito password policy: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='new-password'
                            label={content.passInput}
                            aria-describedby='new-password-error-message'
                        >
                            <PasswordInput
                                autoComplete='off'
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        </LabelledInput>
                        <FieldError
                            inputId='new-password'
                            showError={!!errors.newPassword}
                            errorMessage={content.errors.passInput}
                        />
                        <Spacing />
                    </div>
                )}
            />
            {isPasswordApiError && (
                <InfoBanner
                    id='set-new-password-api-error'
                    bannerType='error'
                    message={content.errors.apiError}
                />
            )}
            <div className={styles['submit-login-wrapper']}>
                <Button
                    type='submit'
                    flavour='primary'
                    showSpinner={isSubmitting}
                    text={isSubmitting ? content.submitting : content.submitButton}
                />
            </div>
        </form>
    )

    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.title}</Heading>
            <Spacing />
            {newPasswordForm}
        </div>
    )
}

export default NewPasswordForm
