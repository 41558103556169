import React, { useContext } from 'react'
import classnames from 'classnames'

import ErrorBoundary from 'components/blocks/ErrorBoundary/ErrorBoundary'
import Footer from 'components/blocks/Footer/Footer'
import HeaderContent from 'components/sections/HeaderContent/HeaderContent'
import Link from 'components/basics/Link/Link'
import Text from 'components/basics/Text/Text'
import { FeatureToggleContext } from 'App'

import styles from './GenericLayout.module.css'
import allContent from 'content/content'

const footerContent = allContent.footer

interface GenericLayoutProps {
    children: React.ReactNode
    className?: string
    isAuthorised: boolean
    userData?: Record<string, any>
    logUserOut({ to }: { to: string }): void
}

const GenericLayout: React.FC<GenericLayoutProps> = ({
    children,
    className,
    isAuthorised,
    userData,
    logUserOut,
}: GenericLayoutProps): JSX.Element => {
    const featureToggles = useContext(FeatureToggleContext)
    if (featureToggles.TEST_FEATURE_TOGGLE) {
        // eslint-disable-next-line no-console
        console.log('TEST: Feature toggle is on')
    }
    const fullBleedContainer = classnames(styles['full-bleed-wrapper'], className)

    return (
        <div className={styles.page}>
            <ErrorBoundary>
                <HeaderContent
                    isAuthorised={isAuthorised}
                    logUserOut={logUserOut}
                    userData={userData}
                />
                <div className={fullBleedContainer}>
                    <main className={styles.content}>{children}</main>
                </div>
                <Footer>
                    <div className={styles['link-container']}>
                        <div className={styles['link-container--column']}>
                            <Text color='white' weight='bold'>
                                {footerContent.support}
                            </Text>
                            {/* Links going to a traveltek url only need rel='noopener', all other external links will need rel='noopener 'noreferrer' */}
                            <Link
                                href={footerContent.serviceDeskUrl}
                                rel='noopener'
                                onDarkBackground={true}
                                target='_blank'
                            >
                                {footerContent.serviceDeskText}
                            </Link>
                            <Link
                                href={footerContent.privacyPolicyUrl}
                                rel='noopener'
                                onDarkBackground={true}
                                target='_blank'
                            >
                                {footerContent.privacyPolicyText}
                            </Link>
                            <Link
                                href={footerContent.termsOfUseUrl}
                                rel='noopener'
                                onDarkBackground={true}
                                target='_blank'
                            >
                                {footerContent.termsOfUseText}
                            </Link>
                        </div>
                    </div>
                </Footer>
            </ErrorBoundary>
        </div>
    )
}

export default GenericLayout
