import { ROUTES } from '../App'
import { getDataFromLocalStorage, setDataToLocalStorage } from './use-local-storage'
import { ACCESS_TOKEN_EXPIRY_MINS, LOCAL_STORAGE_USER_DATA_KEY } from './constants'
import { CognitoUserSession } from 'amazon-cognito-identity-js'

import { refreshUserSession } from './refresh-user-session'

const HALF_SESSION_EXPIRY_MS = (ACCESS_TOKEN_EXPIRY_MINS / 2) * 60 * 1000

export type UserData = {
    user: {
        signInUserSession: {
            refreshToken: {
                token: string
            }
        }
    }
    expiry: number
}

export function updateCognitoAuthData({
    userData,
    cognitoSession,
}: {
    userData: UserData
    cognitoSession: CognitoUserSession
}): void {
    const user = userData.user
    const updatedUserData = { ...user, signInUserSession: cognitoSession }
    setDataToLocalStorage({
        data: { user: updatedUserData },
        key: LOCAL_STORAGE_USER_DATA_KEY,
        expiryMins: ACCESS_TOKEN_EXPIRY_MINS,
    })
}

export const checkAndPerformUserSessionRefreshIfNeeded = ({
    timeNow,
    navigate,
}: {
    timeNow: number
    navigate: (url: string) => void
}): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const userData: UserData | undefined = getDataFromLocalStorage({
        key: LOCAL_STORAGE_USER_DATA_KEY,
        withExpiry: true,
    })
    /** If session data is missing, then it has expired - send user to Timeout Page */
    if (!userData) {
        navigate(ROUTES.TIMEOUT)
    } else {
        const timeTillExpiryMs = userData.expiry - timeNow

        /** Don't bother to refresh if expiry is longer than half the expiry - reduce api calls */
        if (timeTillExpiryMs > HALF_SESSION_EXPIRY_MS) return

        /** check if userData contains signInUserSession - assume this not a demo user */
        const userCanBeRefreshed = Boolean(userData?.user?.signInUserSession)
        if (!userCanBeRefreshed) return

        /** Try to refresh session */
        refreshUserSession({ navigate, userData }).then()
    }
}
