import React from 'react'

import ForgotPasswordForm from '../../sections/ForgotPasswordForm/ForgotPasswordForm'
import Breadcrumb from '../../basics/Breadcrumb/Breadcrumb'

const ForgotPasswordLayout: React.FC = () => (
    <>
        <Breadcrumb urlList={[{ url: '/', text: 'Login' }, { text: 'Forgot Password' }]} />
        <div className='general-container'>
            <ForgotPasswordForm />
        </div>
    </>
)

export default ForgotPasswordLayout
