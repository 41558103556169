import { format } from 'date-fns'
import {
    DATE_FORMAT_D_M_Y_SLASH,
    DATE_FORMAT_Y_M_D_HYPHEN,
    DATE_FORMAT_USA_LONG,
    DATE_FORMAT_USA_SHORT,
} from './constants'

type DateFormatVariant = 'D_M_Y_SLASH' | 'Y_M_D_HYPHEN' | 'USA_LONG' | 'USA_SHORT'

export const getFormattedDate = (inputDateString: string, variant: DateFormatVariant): string => {
    const newDate = new Date(inputDateString)
    const isValid = newDate instanceof Date && !isNaN(Number(newDate))
    if (!isValid) return 'Invalid date'

    switch (variant) {
        case 'D_M_Y_SLASH':
            return format(newDate, DATE_FORMAT_D_M_Y_SLASH)
        case 'Y_M_D_HYPHEN':
            return format(newDate, DATE_FORMAT_Y_M_D_HYPHEN)
        case 'USA_SHORT':
            return format(newDate, DATE_FORMAT_USA_SHORT)
        case 'USA_LONG':
            return format(newDate, DATE_FORMAT_USA_LONG)
        default:
            return format(newDate, DATE_FORMAT_USA_LONG)
    }
}
