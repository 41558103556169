import React from 'react'
import { Controller, useForm } from 'react-hook-form'

import Button from '../../../basics/Button/Button'
import FieldError from '../../../basics/FieldError/FieldError'
import LabelledInput from '../../../blocks/LabelledInput/LabelledInput'
import Modal from '../../../blocks/Modal/Modal'
import Select from '../../../basics/INPUTS/Select/Select'
import Text from '../../../basics/Text/Text'
import TextInput from '../../../basics/INPUTS/TextInput/TextInput'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import { HandleUpdateGroup } from '../GroupsList/GroupsList'
import { Group, SalesChannel } from '../../../../api-data-models/GroupsContentModel'
import { REGEX } from '../../../../utils/constants'

import allContent from '../../../../content/content'
import styles from './UpdateGroupModal.module.css'

const content = allContent.homePage.products.agentConnectCruise.updateGroupModal

export type FormData = {
    groupTitle?: string
    salesChannelId?: string
}

type FormProps = {
    groupDataForUpdate: Group
    salesChannels: SalesChannel[]
    handleUpdateGroup({ userGroupId, salesChannel }: HandleUpdateGroup): void
    isSubmitting: boolean
    onClose: () => void
}

const Form: React.FC<FormProps> = ({
    groupDataForUpdate,
    salesChannels,
    handleUpdateGroup,
    onClose,
    isSubmitting,
}): React.ReactElement => {
    /** Make Select Options for sales channel using array of all sales channels*/
    const salesChannelOptions: { value: string; text: string }[] = salesChannels
        .map((salesChannel: SalesChannel) => {
            return {
                text: salesChannel.salesChannelTitle,
                value: salesChannel.salesChannelId,
            }
        })
        .sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()))

    const currentSalesChannelId = groupDataForUpdate.salesChannels.length
        ? groupDataForUpdate.salesChannels[0].salesChannelId
        : '' // placeholder value on select has value ''

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            groupTitle: groupDataForUpdate.groupTitle,
            salesChannelId: currentSalesChannelId,
        },
    })
    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit((formData: FormData): void => {
                /** Not updating the Group Title at moment, but will be used soon. */
                // const groupTitle = formData.groupTitle

                /** Need more than the id for the sales channel - need full object - so fetch from salesChannels
                 * IGNORE TYPE as the find will always find a matching sales channel - because the salesChannels data provided the input value! */

                const selectedSalesChannelId = formData.salesChannelId
                if (selectedSalesChannelId) {
                    const selectedSalesChannel = salesChannels.find(
                        (salesChannel) => salesChannel.salesChannelId === selectedSalesChannelId
                    )
                    if (selectedSalesChannel) {
                        handleUpdateGroup({
                            userGroupId: groupDataForUpdate.groupId,
                            salesChannel: {
                                salesChannelId: selectedSalesChannel.salesChannelId,
                                salesChannelTitle: selectedSalesChannel.salesChannelTitle,
                            },
                        })
                    }
                }
            })}
        >
            <p>
                <Text>{content.text1}</Text>
            </p>
            <Controller
                control={control}
                name='groupTitle'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.COMPANY_NAME,
                }}
                render={({ field: { onBlur, onChange, value } }): React.ReactElement => (
                    <div>
                        <LabelledInput
                            required={true}
                            htmlFor='group-title'
                            label={content.groupTitleInput}
                            isErrored={!!errors.groupTitle}
                            disabled={true} // when this field is editable, update to isSubmitting
                        >
                            <TextInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                required={true}
                            />
                        </LabelledInput>
                        <FieldError
                            inputId='group-title'
                            showError={!!errors.groupTitle}
                            errorMessage={content.errors.groupTitle}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='salesChannelId'
                rules={{ required: true }}
                render={({ field: { onBlur, onChange, value } }): React.ReactElement => {
                    return (
                        <div>
                            <LabelledInput
                                required={true}
                                htmlFor='sales-channel-id'
                                label={content.salesChannelInput}
                                disabled={isSubmitting}
                                isErrored={!!errors.salesChannelId}
                            >
                                <Select
                                    placeholder={{
                                        text: content.salesChannelPlaceholderText,
                                        value: '',
                                    }}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    required={true}
                                    options={salesChannelOptions}
                                    disabled={isSubmitting}
                                />
                            </LabelledInput>
                            <FieldError
                                inputId='sales-channel-id'
                                showError={!!errors.salesChannelId}
                                errorMessage={content.errors.salesChannelId}
                            />
                        </div>
                    )
                }}
            />
            <div className={styles['button-wrapper']}>
                <Button
                    type='button'
                    flavour='tertiary'
                    text={content.cancelButton}
                    onClick={onClose}
                    disabled={isSubmitting}
                />
                <Button
                    text={isSubmitting ? content.submittingButton : content.confirmButton}
                    type='submit'
                    showSpinner={isSubmitting}
                />
            </div>
        </form>
    )
}

type UpdateGroupModalProps = {
    isOpen: boolean
    isSubmitting: boolean
    groupDataForUpdate: Group | null
    handleUpdateGroup({ userGroupId, salesChannel }: HandleUpdateGroup): void
    onClose: () => void
    salesChannels: SalesChannel[]
    isErrored: boolean
}

const UpdateGroupModal: React.FC<UpdateGroupModalProps> = ({
    isOpen,
    isSubmitting,
    handleUpdateGroup,
    onClose,
    groupDataForUpdate,
    salesChannels,
    isErrored = false,
}): React.ReactElement => {
    return (
        <Modal
            headerText={content.title}
            isOpen={isOpen}
            returnFocusId=''
            setClosed={onClose}
            className={styles.wrapper}
        >
            {isOpen && groupDataForUpdate !== null && (
                <div className={styles.container}>
                    <Form
                        groupDataForUpdate={groupDataForUpdate}
                        salesChannels={salesChannels}
                        handleUpdateGroup={handleUpdateGroup}
                        isSubmitting={isSubmitting}
                        onClose={onClose}
                    />
                    {isErrored && (
                        <InfoBanner
                            bannerType='error'
                            message={content.errors.apiError}
                            id='group-add-modal-error-banner'
                        />
                    )}
                </div>
            )}
        </Modal>
    )
}
export default UpdateGroupModal
